import React from "react";
import styled from "styled-components";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <Overlay onMouseDown={onClose}>
      <Container>
        <ScrollBox>
          <Block onMouseDown={(e) => e.stopPropagation()}>
            <BlockContent>
              {children}
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container>
    </Overlay>
  );
};

export default Modal;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(216, 216, 216, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;

  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // min-height: 350px;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 400;
  justify-content: flex-start;
`;