import React, { useRef, useEffect } from "react";

const AutoResizingTextArea = ({ value, onChange, useMinimumHeight, style, ...props }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset the height so it can shrink when needed
      textarea.style.height = useMinimumHeight ? 0 : "auto";
      // Set the height to the scrollHeight to adjust to content
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value]);

  // When the textarea is focused, move the caret to the end
  const handleFocus = () => {
    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        const length = textarea.value.length;
        textarea.setSelectionRange(length, length);
      }
    }, 0);
  };

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onFocus={handleFocus}
      style={{
        fontFamily: "Source Sans Pro, Helvetica, Arial, sans-serif",
        fontSize: "15px",
        resize: "none",
        overflow: "hidden",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        ...style,
      }}
      {...props}
    />
  );
}

export default AutoResizingTextArea;
