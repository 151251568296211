// dependencies
import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const Select = ({ value = 0, onChange, options = [], ...rest }) => (
  <div className={`${style.select} ${value === null ? style.none : ""}`}>
    <select
      onChange={onChange}
      value={value}
      {...rest}
    >
      {options}
    </select>
  </div>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default Select;