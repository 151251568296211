import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../../NotFound";
import DetailsHeader from "../../../components/DetailsHeader";
import SubNavigation from "../../../components/SubNavigation";
import WarningsList from "./warningsList";

class WarningsSummaryDetails extends Component {

  render() {
    const { isLoading } = this.props;

    if (isEmpty(this.props.companyId)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/support/overview/${this.props.companyId}/alarms`, label: `Alarms` });
    // subNavigationLinks.push({ pathname: `/support/overview/${this.props.companyId}/graph`, label: `Graph` });
    
    const companies = this.props.auth?.user?.companies ?? [];
    const selectedCompany = companies.find(c => c._id === this.props.companyId);
    let title = selectedCompany?.name;

    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={isLoading}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/support/overview/${this.props.companyId}`} to={{ pathname: `/support/overview/${this.props.companyId}/alarms` }} />
          <Route path="/support/overview/:companyId/alarms/:warningId?" children={(props) => <WarningsList {...props} companyId={this.props.companyId} />} />
          <Route path="/support/overview/:companyId/graph" children={(props) => <div {...props} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps, null)(WarningsSummaryDetails);