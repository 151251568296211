import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../NotFound";
import SubNavigation from "../../../components/SubNavigation";
import LargeHeader from "../../../components/LargeHeader";
import CompaniesContainer from "./container";
import style from "./style.module.scss";

class Companies extends Component {
  
  render() {
    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/support/settings`, label: `List` });
    // subNavigationLinks.push({ pathname: `/support/history`, label: `History` });

    return (
      <div className={style.page}>
        <LargeHeader title="Companies" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Route path="/support/settings/:companyId?" children={(props) => <CompaniesContainer {...props} companyId={props.match.params.companyId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default Companies;