import React from "react";
import isEmpty from "lodash/isEmpty";
import Info from "../Info";
import { Button } from "ui";
import { DARKGREEN, DARKRED } from "../Icon";
import IconButton, { IconButtonType } from "../IconButton";
import style from  "./style.module.scss";

const TextBox = ({ placeholder, label, name, input, onChange = () => {}, value, info, help, disabled = false, valid = true, required = false, showCopy = false, hasDarkBackground = false, size = "large", isTopRow = false, textAreaStyle, onDownload = null, onDownloadBinary = null, downloadText = "download" }) => (
  <div className={ isTopRow ? style.topTextbox : style.textbox }>
    <label className={style.label}>{label}</label>
    { help && <Info text={help} />}
    <textarea
      className={style.textfield}
      style={{ ...getInputStyles(value, size, disabled, valid, required, hasDarkBackground), ...textAreaStyle }}
      placeholder={placeholder}
      name={name}
      {...input}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
    </textarea>
    <div className={style.buttons}>  
      {
        // This requires window.Clipboard :/
        showCopy && (
          <IconButton type={IconButtonType.COPY} onClick={() => window.Clipboard.copy(value)} />
        )
      }
      {
        onDownload && (
          <IconButton type={IconButtonType.DOWNLOAD} onClick={() => onDownload(value)} />
        )
      }
      {
        onDownloadBinary && (
          <IconButton type={IconButtonType.DOWNLOAD_BINARY} onClick={() => onDownloadBinary(value)} />
        )
      }
    </div>

    {info && <div className={style.info}>{info}</div>}
  </div>
);
 
const getInputStyles = (value, size, disabled, valid, required, hasDarkBackground) => {
  let style = { 
    height: size?.includes("px") ? size : size === "large" ? "250px" : size === "medium" ? "100px" : "54px",
    cursor: "auto"
  };
  
  if (!isEmpty(value)) {
    if (disabled) {
      style.color = "#666";
    }
    else if (required) {
      if (valid) {
        style.borderColor = DARKGREEN;
        style.borderWidth = "2px";
      }
      else {
        style.borderColor = DARKRED;
        style.borderWidth = "2px";
      }
    }
  }

  if (hasDarkBackground) {
    style.backgroundColor = "#fff";

    if (disabled) {
      style.color = "#666";
    }
  }
  else {
    if (disabled) {
      style.backgroundColor = "#eee";
    }
    else {
      style.backgroundColor = "#fff";
    }
  }

  return style;
}

export default TextBox;