import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { get, isEmpty, isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import Table from "../../components/Table";
import SearchBox from "../../components/SearchBox";
import SmallButton from "../../components/SmallButton";
import OptionFooter from "../../components/OptionFooter";
import TopRowOptions from "../../components/TopRowOptions";
import SelectionFooterCell from "../../components/SelectionFooterCell";
import ServiceAccountDetails from "./serviceAccountDetails";
import * as accountActions from "../../actions/account";
import * as selectedActions from "../../actions/selected";
import { Row, Col } from ".";
import styled from "styled-components";

const tabBarLinks = [
  { label: `Details` },
  { label: `Locations` }
];

class ServiceAccounts extends Component {

  constructor(props) {
    // console.log("ServiceAccounts.constructor");
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "desc",
      offset: 0,
      limit: 20
    };

    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);

    this.onNewClient = this.onNewClient.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);

    this.onAddLocations = this.onAddLocations.bind(this);
    this.onRemoveLocations = this.onRemoveLocations.bind(this);

    if (isEmpty(this.props.serviceAccountId)) {
      this.props.clearServiceAccount();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getServiceAccounts(queryParams);
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onNewClient() {
    this.props.clearServiceAccount();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/account/service-accounts/new`);
  }

  onCancelClick() {
    this.props.clearServiceAccountDraft();
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined && column.name !== "isSelected") {
          // console.log("rowInfo", rowInfo);
          const row = rowInfo.original;
          let link = `/companies/${this.props.match.params.companyId}/account/service-accounts/${rowInfo.original.id}`;
  
          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.props.serviceAccountId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  onSave() {
    const { push } = this.props.history;

    // New service account
    if (isEmpty(this.props.serviceAccount)) {
      if (this.props.serviceAccountDraft &&
        this.props.serviceAccountDraft.name &&
        this.props.serviceAccountDraft.name.length > 0) {

          let body = {
            name: this.props.serviceAccountDraft.name,
            description: this.props.serviceAccountDraft.description,
            certificate: this.props.serviceAccountDraft.certificate
          };

          if (this.props.serviceAccountDraft.locations) {
            body.locationIds = this.props.serviceAccountDraft.locations.map(location => location.id);
          }

          this.props.createServiceAccount(body, push);
      }
    }
    else {
      let body = {
        name: this.props.serviceAccountDraft.name,
        description: this.props.serviceAccountDraft.description,
        certificate: this.props.serviceAccountDraft.certificate
      };

      if (this.props.serviceAccountDraft.locations) {
        body.locationIds = this.props.serviceAccountDraft.locations.map(location => location.id);
      }

      this.props.updateServiceAccount(this.props.serviceAccount.id, body, push);
    }
  }

  serviceAccountsTable() {
    return (
      <Table
        data={this.props.serviceAccounts.results}
        noDataText={this.props.isLoading ? "" : "No outbound data APIs"}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.serviceAccounts.count}
        onSortedChange={this.onSortedChange}
        columns={[
          {
            header: "Name",
            accessorKey: "name"
          },
          {
            header: "Client ID",
            accessorKey: "id"
          },
          // {
          //   header: "Locations",
          //   accessorKey: "locations",
          //   cell: ({ row }) => (<span>{row.locations.length}</span>)
          // },
          {
            header: "Metric",
            accessorKey: "metric"
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "id",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  onDeselectLocation(id) {
    this.props.deselectLocation(id);
  }

  optionFooter() {
    if (this.props.serviceAccountId === null) {
      return;
    }
    else if (!isEmpty(this.props.selectedLocations)) {
      let options = [];
      const selectedLocationIds = Object.keys(this.props.selectedLocations);

      let addedLocations = get(this.props.serviceAccountDraft, "locations", get(this.props.serviceAccount, "locations", []));

      if (!isEmpty(addedLocations)) {
        if (selectedLocationIds.every(id => addedLocations.find(location => location.id === id))) {
          options.push(
            { 
              label: "Remove",
              callback: this.onRemoveLocations,
              disabled: this.props.isLoading,
              destructive: true
            }
          );
        }
        else {
          options.push(
            { 
              label: "Add",
              callback: this.onAddLocations,
              disabled: this.props.isLoading
            }
          );
        }
      }
      else {
        options.push(
          { 
            label: "Add",
            callback: this.onAddLocations,
            disabled: this.props.isLoading
          }
        );
      }

      const selectedLocations = selectedLocationIds.map(key => this.props.selectedLocations[key]);
      const selectedLocationElements = selectedLocations.map(location => (
        <SelectionFooterCell id={location._id || location.id} key={location._id || location.id} text={location.name} onClick={() => this.onDeselectLocation(location._id || location.id)} />
      ));

      return (
        <OptionFooter
          inline={true}
          cancel={this.onCancelClick}
          options={[
            ...options,
          ]}
        >
          { selectedLocationElements }
        </OptionFooter>
      );
    }
    else if (this.props.serviceAccountId === "new") {
      return (
        <OptionFooter
          inline={true}
          cancel={this.onCancelClick}
          options={[
            { 
              label: "Save",
              callback: this.onSave,
              disabled: isEmpty(this.props.serviceAccountDraft.name)
            }
          ]}
        />
      );
    }
    else if (!isEmpty(this.props.serviceAccountDraft)) {

      let hasChanges = false;
      let nameIsInvalid = false;
      if (this.props.serviceAccountDraft.name && this.props.serviceAccountDraft.name !== this.props.serviceAccount.name) {
        hasChanges = true;
        nameIsInvalid = this.props.serviceAccountDraft.name.length === 0;
      }

      if (this.props.serviceAccountDraft.description && this.props.serviceAccountDraft.description !== this.props.serviceAccount.description) {
        hasChanges = true;
      }

      if (this.props.serviceAccountDraft.certificate && this.props.serviceAccountDraft.certificate !== this.props.serviceAccount.certificate) {
        hasChanges = true;
      }

      if (this.props.serviceAccountDraft.locations && !isEqual(this.props.serviceAccountDraft.locations, this.props.serviceAccount.locations)) {
        hasChanges = true;
      }

      if (hasChanges) {
        return (
          <OptionFooter
            inline={true}
            cancel={this.onCancelClick}
            options={[
              { 
                label: "Save",
                callback: this.onSave,
                disabled: nameIsInvalid
              }
            ]}
          />
        );
      }
    }
  }

  onAddLocations() {
    const selectedLocationIds = Object.keys(this.props.selectedLocations);
    const selectedLocations = selectedLocationIds.map(id => ({ id, name: this.props.selectedLocations[id].name }));

    if (this.props.serviceAccountId === "new") {
      let locations = get(this.props.serviceAccountDraft, "locations", []);
      selectedLocations.forEach(selectedLocation => {
        if (!locations.find(location => location.id === selectedLocation.id)) {
          locations.push(selectedLocation);
        }
      });

      this.props.updateServiceAccountDraft({ locations });
    }
    else {
      let locationIds = get(this.props.serviceAccountDraft, "locations", get(this.props.serviceAccount, "locations", [])).map(location => location.id);
      const body = {
        locationIds: [...new Set([...locationIds, ...selectedLocationIds])] 
      };

      this.props.updateServiceAccount(this.props.serviceAccountId, body, this.props.history.push);
    }
  }

  onRemoveLocations() {
    const selectedLocationIds = Object.keys(this.props.selectedLocations);

    if (this.props.serviceAccountId === "new") {
      this.props.updateServiceAccountDraft({ locations: this.props.serviceAccountDraft.locations.filter(location => !selectedLocationIds.includes(location.id)) });
    }
    else {
      let locationIds = get(this.props.serviceAccountDraft, "locations", get(this.props.serviceAccount, "locations", [])).map(location => location.id);
      const body = {
        locationIds: locationIds.filter(locationId => !selectedLocationIds.includes(locationId))
      };

      this.props.updateServiceAccount(this.props.serviceAccountId, body, this.props.history.push);
    }
  }
  
  render() {

    return (
      <>
        <Hidden xs sm md>
          <SplitView>
            <LeftContainer>
              <ListContainer>
                <TopRowOptions
                  searchbox={(
                    <SearchBox
                      value={this.state.searchText}
                      onSearchChanged={(value) => this.setState({ searchText: value })}
                      onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
                      onClear={() => this.setState({ searchText: "",  offset: 0 }, this.fetchData)}
                      inListView
                    />
                  )}
                  buttons={[
                    <SmallButton key="b1" text="New client" onClick={this.onNewClient} singleLine noMargin />
                  ]}
                />
                <Row $paddingTop="20px">
                  <Col>
                    { this.serviceAccountsTable() }
                  </Col>
                </Row>
              </ListContainer>
            </LeftContainer>
            <RightContainer>
              <ListContainer>
                { !isEmpty(this.props.serviceAccountId) && <ServiceAccountDetails {...this.props} />}
              </ListContainer>
            </RightContainer>
          </SplitView>
        </Hidden>
        <Visible xs sm md>
          <SingleView style={{ backgroundColor: !isEmpty(this.props.serviceAccountId) && "#F6F6F6" }}>
            { !isEmpty(this.props.serviceAccountId) ? (
              <SlimListContainer>
                <ServiceAccountDetails {...this.props} />
              </SlimListContainer>
            ) : (
              <SlimListContainer>
                <TopRowOptions
                  searchbox={(
                    <SearchBox
                      value={this.state.searchText}
                      onSearchChanged={(value) => this.setState({ searchText: value })}
                      onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
                      onClear={() => this.setState({ searchText: "", offset: 0 }, this.fetchData)}
                      inListView
                    />
                  )}
                  buttons={[
                    <SmallButton key="b1" text="New client" onClick={this.onNewClient} singleLine noMargin />
                  ]}
                />
                <Row $paddingTop="20px">
                  <Col>
                    { this.serviceAccountsTable() }
                  </Col>
                </Row>
              </SlimListContainer>
            )
            }
          </SingleView>
        </Visible>

        { this.optionFooter() }

        {/* {
          showOptionFooter && (
            <OptionFooter 
              cancel={this.onCancel} 
              options={[
                { 
                  label: "Save", 
                  callback: this.onSave, 
                  disabled: this.props.isLoading ||
                    isEmpty(this.props.serviceAccountDraft)
                }
              ]} 
            />
          )
        } */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isLoading: state.loading.serviceAccounts,
    isLoadingLocations: state.loading.locations,
    serviceAccount: state.serviceAccount.data,
    serviceAccountDraft: state.serviceAccount.draft,
    serviceAccounts: state.serviceAccounts.data,
    selectedLocations: state.selected.locations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getServiceAccounts: accountActions.getServiceAccounts,
    createServiceAccount: accountActions.createServiceAccount,
    updateServiceAccount: accountActions.updateServiceAccount,
    deleteServiceAccount: accountActions.deleteServiceAccount,
    updateServiceAccountDraft: accountActions.updateServiceAccountDraft,
    clearServiceAccount: accountActions.clearServiceAccount,
    clearServiceAccountDraft: accountActions.clearServiceAccountDraft,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceAccounts));

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 188px);
  overflow: auto;
`;

const SingleView = styled.div`
  display: block;
  height: calc(100vh - 188px);
  overflow: auto;
`;

const LeftContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 60%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
`;

const ListContainer = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px;
`;

const SlimListContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const RightContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 40%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
    margin-bottom: 0;
  }
`;