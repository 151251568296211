import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, map } from "lodash";
import Mapbox from "!mapbox-gl";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import RotateMode from "mapbox-gl-draw-rotate-mode";
import SmallButton from "../../../components/SmallButton";
import TextBox from "../../../components/TextBox";
import { ControlledDropdownSelection } from "../../../components/DropdownSelection";
import * as featureActions from "../../../actions/features";
import * as selectedActions from "../../../actions/selected";
import { getMapBounds, MapColors } from "../helpers";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-regular-svg-icons";
import { faDrawSquare, faTrash } from "@fortawesome/pro-solid-svg-icons";

let mapbox;
let mapboxDraw;
let shouldUpdateMap;
let combinedFeatures;
let savedFeatureMapping;
let mapHasLoaded;
let usesMazemap = false;

class DrawMap extends Component {
  
  constructor(props) {
    // console.log("DrawMap.constructor", props);
    super(props);
    shouldUpdateMap = false;
    mapHasLoaded = false;
    combinedFeatures = {};
    savedFeatureMapping = {};

    this.state = {
      drawMode: "simple_select",
      showMap: false,
      selectedFeatures: []
    };
    
    this.changeDrawMode = this.changeDrawMode.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onMapRender = this.onMapRender.bind(this);
    this.onFeaturesSelected = this.onFeaturesSelected.bind(this);
    this.onRotateEnd = this.onRotateEnd.bind(this);
    this.addLocationGeoJson = this.addLocationGeoJson.bind(this);
    this.loadConfig = this.loadConfig.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.resetChanges = this.resetChanges.bind(this);
    this.overridenStyleChanged = this.overridenStyleChanged.bind(this);
    this.onClearSelection = this.onClearSelection.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  loadConfig() {
    // console.log("DrawMap.loadConfig", this.props);

    // Build config
    const config = {
      container: "mazemap-container",
      autoSetRTLTextPlugin: false
    };

    if (get(this.props.location, "geoJsonFeature.geometry", null)) {
      // Center for mapped location
      const llb = getMapBounds([this.props.location.geoJsonFeature]);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50 };
    }
    else if (get(this.props.map, "features[0].geometry", null)) {
      // Center for unmapped location
      const llb = getMapBounds(this.props.map.features);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50 };
    }
    else {
      // Skandinavia
      config.center = { lng: 17.7099499, lat: 64.55875 };
      config.zoom = 3.5;
    }

    if (this.props.location.floorLevel !== "" && !isEmpty(this.props.company.mazemapCampusTag)) {
      usesMazemap = true;
      config.campuses = this.props.company.mazemapCampusTag;
      config.zLevel = this.props.location.floorLevel;
      config.zLevelControl = false;
      
      let key;
      if (!isEmpty(this.props.company.mazemapApiKey)) {
        if (this.props.company.mazemapApiKey.length === 32) {
          key = `Bearer ${this.props.company.mazemapApiKey}`;
        }
        else {
          key = this.props.company.mazemapApiKey;
        }

        Mazemap.Config.setMazemapAuthToken(key); // eslint-disable-line no-undef
     }

      mapbox = new Mazemap.Map(config); // eslint-disable-line no-undef
    }
    else {
      usesMazemap = false;

      Mapbox.accessToken = "pk.eyJ1IjoidG5zbWFydHgiLCJhIjoiY2s4aXJ6MTYxMDQycjNsbjYxZTA2ZmY5diJ9.6cYHg6iYWpNrtSuAjf-Eyg";
      config.style = "mapbox://styles/mapbox/streets-v11";
      config.attributionControl = false;

      mapbox = new Mapbox.Map(config).addControl(new Mapbox.AttributionControl({
        compact: true
      }));
    }

    // mapbox.addControl(new Mazemap.mapboxgl.NavigationControl());  // eslint-disable-line no-undef

    const mazeMapOptions = {
      displayControlsDefault: false,
      // defaultMode: MapboxDraw.modes.SIMPLE_SELECT,
      modes: {...MapboxDraw.modes, RotateMode }, //, draw_assisted_rectangle: DrawRectangle // ,draw_rectangle: MapboxDrawRectangle
      // controls: {
      //   polygon: true
      // },
      userProperties: true
    };

    mapboxDraw = new MapboxDraw(mazeMapOptions); 
    mapbox.addControl(mapboxDraw, "top-left");
    
    mapbox.on("draw.create", event => {
      const newFeatures = event.features.map(feature => {
        feature.changeType = "new";
        return feature;
      });

      this.props.storeEditedFeatures(newFeatures);
    });

    mapbox.on("draw.update", event => {
      const newFeatures = event.features.map(feature => {
        feature.changeType = "edited";
        return feature;
      });

      this.props.storeEditedFeatures(newFeatures);
    });

    mapbox.on("draw.combine", event => {
      console.log("draw.combine", event);
      combinedFeatures[event.createdFeatures[0].id] = event.deletedFeatures;
    });

    mapbox.on("draw.uncombine", event => {
      console.log("draw.uncombine", event);
      console.log("combinedFeatures", combinedFeatures);
      event.createdFeatures.forEach((createdFeature,index) => {
        const savedFeatures = combinedFeatures[event.deletedFeatures[0].id];
        savedFeatureMapping[createdFeature.id] = savedFeatures[index];
      });

      console.log("draw.uncombine.done => featureCollection", mapboxDraw.getAll());
      console.log("savedFeatureMapping", savedFeatureMapping);
      this.remapFeatures(savedFeatureMapping, mapboxDraw.getAll());
    });

    mapbox.on("load", this.onMapLoad);

    mapbox.on("draw.render", this.onMapRender);

    mapbox.on("draw.selectionchange", event => {
      const mode = mapboxDraw.getMode();
      this.onFeaturesSelected(event.features, mode);
    });

    RotateMode.rotatestart = function(selectedFeature,originalCenter) {
      console.log('ROTATESTART');
      console.log('feature: ',selectedFeature);
      console.log('center: ',originalCenter);
   }
   
   RotateMode.rotating = function(selectedFeature,originalCenter,lastMouseDown) {
      // console.log('ROTATING');
      // console.log('feature: ',selectedFeature);
      // console.log('center: ',originalCenter);
      // console.log('lastMouseDown: ',lastMouseDown);
   }
   
   RotateMode.rotateend = this.onRotateEnd;
  }

  componentDidMount() {
    if (this.props.map !== undefined) {
      shouldUpdateMap = false;
      this.loadConfig();
    }
  }

  componentWillUnmount() {
    mapbox.off("load", this.onMapLoad);
    mapbox.off("draw.render", this.onMapRender);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.location.floorMap === undefined && nextProps.location.floorMap !== undefined) {
      shouldUpdateMap = true;
    }
  }
  
  componentDidUpdate() {
    if (this.props.map !== undefined && shouldUpdateMap) {
      shouldUpdateMap = false;
      this.loadConfig();
    }
  }

  onMapLoad() {
    const mode = mapboxDraw.getMode();
    mapHasLoaded = true;

    // Add geometry
    if (get(this.props.map, "features[0]", false)) {
      this.addLocationGeoJson();
    }
  }

  onMapRender() {
    // console.log("draw.render", event);
    if (mapHasLoaded && !this.state.showMap) {
      this.setState({ showMap: true });
    }
  }

  onFeaturesSelected(features, mode) {
    this.setState({ selectedFeatures: features, drawMode: mode });
  }

  onClearSelection() {
    this.setState({ selectedFeatures: [], drawMode: "simple_select" });
  }

  onRotateEnd(selectedFeature) {
    const featureCollection = mapboxDraw.getAll();
    this.props.storeEditedFeatures(featureCollection.features);
  }

  changeDrawMode(newMode) {
    mapboxDraw.changeMode(newMode);
    this.setState({ drawMode: newMode });
  }

  addLocationGeoJson() {

    // Add features
    this.props.map.features.forEach(feature => {
      if (feature.id) {
        // Check if we have an updated feature
        const editedFeature = this.props.editedFeatures.find(editedFeature => editedFeature.id === feature.id);
        if (editedFeature) {
          if (editedFeature.changeType !== "deleted") {
            mapboxDraw.add(editedFeature);
          }
        }
        else {
          mapboxDraw.add(feature);
        }
      }
    });

    // Add created features
    this.props.editedFeatures.forEach(feature => {
      if (feature.changeType === "new") {
        mapboxDraw.add(feature);
      }
    });

    if (mapbox.getLayer("mm-campus-label") !== undefined) {
      mapbox.setLayoutProperty("mm-campus-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-building-label") !== undefined) {
      mapbox.setLayoutProperty("mm-building-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-poi-label") !== undefined) {
      mapbox.setLayoutProperty("mm-poi-label", "visibility", "none");
    }
  }

  remapFeatures(featureMap, featureCollection) {

    if (mapHasLoaded && this.state.showMap) {

      /* eslint-disable no-param-reassign */
      featureCollection.features.forEach(feature => {
        if (featureMap[feature.id]) {
          feature.properties = featureMap[feature.id].properties;
          feature.id = featureMap[feature.id].id;
        }
      });
      /* eslint-enable no-param-reassign */
  
      // Redraw
      mapboxDraw.deleteAll();
      featureCollection.features.forEach(feature => {
        if (feature.id && !feature.properties.companyMap) {
          mapboxDraw.add(feature);
        }
      });

      this.props.storeEditedFeatures(featureCollection.features);
    }
  }

  overridenStyleChanged(event) {
    
    const featureId = this.state.selectedFeatures[0].id;
    const foundFeature = this.props.map.features.find(feature => feature.id === featureId);
    const foundEditedFeature = this.props.editedFeatures.find(feature => feature.id === featureId);

    if (foundEditedFeature) {
      const featureCopy = JSON.parse(JSON.stringify(foundEditedFeature));
      featureCopy.properties = { ...featureCopy.properties };

      if (event.target.value === "zone") {
        featureCopy.properties.style = { fillColor: MapColors.zone };
      }
      else {
        featureCopy.properties.style = null;
      }

      // Check if new feature is the same as the original - don't store if so
      if (foundFeature) {

        // Check if style is equal 
        const bothEmpty = isEmpty(foundFeature.properties.style) && isEmpty(featureCopy.properties.style);
        const bothEqual = JSON.stringify(foundFeature.properties.style) === JSON.stringify(featureCopy.properties.style);

        // Check if coordinates are equal
        const bothCoordinatesEqual = JSON.stringify(foundFeature.geometry.coordinates) === JSON.stringify(featureCopy.geometry.coordinates);

        if ((bothEmpty || bothEqual) && bothCoordinatesEqual) {
          this.props.removeEditedFeatures([featureCopy]);
          return;
        }
      }
      
      this.props.storeEditedFeatures([featureCopy]);
    }
    else if (foundFeature) {
      const featureCopy = JSON.parse(JSON.stringify(foundFeature));
      featureCopy.properties = { ...featureCopy.properties };
      featureCopy.changeType = "edited";

      if (event.target.value === "zone") {
        featureCopy.properties.style = { fillColor: MapColors.zone };
      }
      else {
        featureCopy.properties.style = null;
      }
      
      this.props.storeEditedFeatures([featureCopy]);
    }
  }

  deleteFeature(featureId) {
    mapboxDraw.delete(featureId);

    // If new feature - remove from edited
    // If edited feature - set changeType to deleted
    const foundFeature = this.props.map.features.find(feature => feature.id === featureId);
    const foundEditedFeature = this.props.editedFeatures.find(feature => feature.id === featureId);
    
    if (foundEditedFeature) {
      if (foundEditedFeature.changeType === "new") {
        this.props.removeEditedFeatures([foundEditedFeature]);
      }
      else {
        foundEditedFeature.changeType = "deleted";
        this.props.storeEditedFeatures([foundEditedFeature]);
      }
    }
    else if (foundFeature) {
      const featureCopy = JSON.parse(JSON.stringify(foundFeature));
      featureCopy.changeType = "deleted";
      this.props.storeEditedFeatures([featureCopy]);
    }

    this.setState({ selectedFeatures: [] });
  }

  saveChanges() {
    // Remove locationId from properties since it is not allowed
    const editedFeatures = this.props.editedFeatures.map(feature => {
      const featureCopy = JSON.parse(JSON.stringify(feature));

      // If new feature - remove id
      if (featureCopy.changeType === "new") {
        delete featureCopy.id;
      }
      else {
        featureCopy.properties = { style: featureCopy.properties.style ?? null };
      }

      return featureCopy;
    });

    this.props.saveMapChanges(this.props.location.id, editedFeatures, this.onClearSelection);
  }

  resetChanges() {
    mapboxDraw.deleteAll();
    this.props.clearEditedFeatures();
    this.props.map.features.forEach(feature => {
      if (feature.id) {
        mapboxDraw.add(feature);
      }
    });
    this.setState({ selectedFeatures: [] });
  }

  render() {
    // console.log("DrawMap.render", this.props, this.state);

    const selectedFeature = get(this.state.selectedFeatures, "[0]", null);
    const editedFeature = this.props.editedFeatures.find(feature => feature.id === selectedFeature?.id);
    const feature = editedFeature ?? selectedFeature;
    let featureDetails;
    if (feature) {
      featureDetails = (
        <InspectionBox>
          <Header>
            <Title>{feature.changeType === "new" ? "New feature" : feature.id}</Title>
            <InspectionIconButton
                color={"white"}
                hoverColor="#eee"
                onClick={() => this.deleteFeature(feature.id)}>
              <FontAwesomeIcon icon={faTrash} color="#333" />
            </InspectionIconButton>
          </Header>
          <TextBox 
            value={JSON.stringify(feature.properties, null, 2)}
            size="180px"
            isTopRow
            disabled
          />
          <ControlledDropdownSelection
            label="Overriden style"
            value={feature.properties?.style?.fillColor === MapColors.zone ? "zone" : "default"}
            options={[{ id: "default", name: "Default" }, { id: "zone", name: "Render as zone" }]}
            onChange={this.overridenStyleChanged}
          />
        </InspectionBox>
      )
    }

    let saveBox = null
    const changeCount = get(this.props, "editedFeatures.length", 0);
    const hasChange = changeCount > 0;
    if (hasChange) {
      saveBox = (
        <Footer>
          <FooterBox>
            <FooterTitle>{changeCount} updated { changeCount > 1 ? "features" : "feature"}</FooterTitle>
            <ButtonRow>
              <SmallButton
                text="Discard"
                onClick={this.resetChanges}
                color="clear-gray"
                style={{ margin: 0 }}
              />
              <SmallButton
                text="Save"
                onClick={this.saveChanges}
                color="blue"
                style={{ margin: 0 }}
              />
            </ButtonRow>
          </FooterBox>
        </Footer>
      )
    }

    let drawModeButton = null;
    if (this.state.drawMode === "simple_select") {
      drawModeButton = (
        <IconButton
            color={"white"}
            hoverColor="#eee"
            onClick={() => this.changeDrawMode("draw_polygon")}>
          <FontAwesomeIcon icon={faDrawSquare} color="#333" />
        </IconButton>
      );
    }
    else {
      drawModeButton = (
        <IconButton
            color={"white"}
            hoverColor="#eee"
            onClick={() => this.changeDrawMode("simple_select")}>
          <FontAwesomeIcon icon={faHandPointer} color="#333" />
        </IconButton>
      );
    }

    return (
      <MapContainer>
        <div id="mazemap-container" style={{ height: "100%", width: "100%"}} />
        { featureDetails }
        { saveBox }
        <ButtonsContainer>
          
          <IconButton
              color={this.state.drawMode === "simple_select" ? "#f6f6f6" : "white"}
              hoverColor="#eee"
              onClick={() => this.changeDrawMode("simple_select")}>
            <FontAwesomeIcon
              icon={faHandPointer}
              color={this.state.drawMode === "simple_select" ? "#333" : "#333"}
            />
          </IconButton>

          <IconButton
              color={this.state.drawMode === "draw_polygon" ? "#f6f6f6" : "white"}
              hoverColor="#eee"
              onClick={() => this.changeDrawMode("draw_polygon")}>
            <FontAwesomeIcon
              icon={faDrawSquare}
              color={this.state.drawMode === "draw_polygon" ? "#333" : "#333"}
            />
          </IconButton>
          
        </ButtonsContainer>
      </MapContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapFeature: state.selected.mapFeature,
    createdFeatures: state.selected.createdFeatures,
    editedFeatures: state.selected.editedFeatures
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveMapChanges: featureActions.saveMapChanges,
    storeEditedFeatures: selectedActions.storeEditedFeatures,
    removeEditedFeatures: selectedActions.removeEditedFeatures,
    clearEditedFeatures: selectedActions.clearEditedFeatures,
    addCreatedFeature: selectedActions.addCreatedFeature
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawMap);

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F6F6F4;
  position: relative;
`;

const InspectionBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 360px;
  max-height: 600px;
  
  background-color: white;
  z-index: 100;
  border-radius: 5px;
  box-shadow: -1px 0 5px 0 rgba(0,0,0,0.2);
  
  padding: 15px 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 100;

  // Click through
  pointer-events: none;
  user-select: none;
`;

const FooterTitle = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
  align-self: center;
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: -1px 0 5px 0 rgba(0,0,0,0.2);
  padding: 15px 20px;

  // Catch mouse events
  pointer-events: auto;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 99;

  // Click through
  pointer-events: none;
  user-select: none;
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  color: white;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  box-shadow: -1px 0 5px 0 rgba(0,0,0,0.2);
  // transition: 0.2s;

  &:hover {
    background-color: ${props => props.hoverColor};
  }

  // Catch mouse events
  pointer-events: auto;
  user-select: none;
`;

const InspectionIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  color: white;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;

  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;
