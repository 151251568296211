import axios from "axios";
import Cookies from "universal-cookie";
import isEmpty from "lodash/isEmpty";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import * as locationActions from "./locations";
import * as authActions from "./auth";
import { API_URL, BASE_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const registerFeature = (feature, parentIndex, floorLocationId, viewedLocationId) => async (dispatch) => {
  dispatch(actions.requestData(types.EDIT_LOCATION));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const features = [feature];

  // Strip properties and id
  const createdFeatures = features.map(aFeature => ({
    ...aFeature,
    id: undefined,
    properties: undefined
  }));

  // const updatedFeatures = features.map(aFeature => ({
  //   ...aFeature,
  //   properties: {
  //     index: parentIndex + 1
  //   }
  // }));

  actions.createFeatures(floorLocationId, createdFeatures, apiUrl)
    // .then(() => actions.updateFeatures(floorLocationId, updatedFeatures, apiUrl))
    // .then(() => dispatch(locationActions.mapFeatureToLocation(feature.id, owningLocationId, viewedLocationId)))
    .then(() => {
      dispatch(actions.receiveData(null, types.EDIT_LOCATION));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.EDIT_LOCATION));
      }
    });
};

export const saveMapChanges = (floorLocationId, features, clearSelection) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_FEATURES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let newOrEditedFeatures = [];
  let deletedFeatures = [];
  features.forEach(feature => {

    const featureCopy = JSON.parse(JSON.stringify(feature));
    if (featureCopy.changeType === "new" || featureCopy.changeType === "edited") {
      delete featureCopy.changeType;
      newOrEditedFeatures.push(featureCopy);
    }
    else if (featureCopy.changeType === "deleted") {
      delete featureCopy.changeType;
      deletedFeatures.push(featureCopy);
    }
  });

  // If no changes, return
  if (isEmpty(newOrEditedFeatures) && isEmpty(deletedFeatures)) {
    console.log("No changes to save");
    return;
  }

  if (floorLocationId === "root") {
    try {
      !isEmpty(newOrEditedFeatures) && await actions.updateCompanyFeatures(newOrEditedFeatures, apiUrl);
      // If this succeeds, only then proceed with the delete
      !isEmpty(deletedFeatures) && await actions.deleteCompanyFeatures(deletedFeatures, apiUrl);
    
      // If both succeeded:
      dispatch(actions.success(types.UPDATE_FEATURES));
      dispatch(authActions.getCompanyMap());

      // Use callback to clear selection
      if (clearSelection) {
        clearSelection();
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    }
  }
  else {
    try {
      !isEmpty(newOrEditedFeatures) && await actions.updateFeatures(floorLocationId, newOrEditedFeatures, apiUrl);
      // If this succeeds, only then proceed with the delete
      !isEmpty(deletedFeatures) && await actions.deleteFeatures(floorLocationId, deletedFeatures, apiUrl);
    
      // If both succeeded:
      dispatch(actions.success(types.UPDATE_FEATURES));
      dispatch(locationActions.getFloorMap(floorLocationId));

      // Use callback to clear selection
      if (clearSelection) {
        clearSelection();
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    }
  }
};

export const changeFeatures = (locationFeatures, sensorFeatures, gatewayFeatures, floorLocationId, viewedLocationId) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_FEATURES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Strip properties
  const updatedFeatures = locationFeatures.map(feature => ({
    ...feature,
    properties: undefined
  }));

  const updatedSensors = sensorFeatures.map(sensorFeature => ({
    id: sensorFeature.properties.sensor._id,
    body: {
      geoJsonFeature: sensorFeature
    }
  }));

  const updatedGateways = gatewayFeatures.map(gatewayFeature => ({
    id: gatewayFeature.properties.gateway._id,
    body: {
      geoJsonFeature: gatewayFeature
    }
  }));

  actions.updateFeatures(floorLocationId, updatedFeatures, apiUrl)
    .then(() => actions.updateSensors(updatedSensors, apiUrl))
    .then(() => actions.updateGateways(updatedGateways, apiUrl))
    .then(() => {
      dispatch(actions.receiveData(null, types.UPDATE_FEATURES));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    });
};

export const deleteFeatures = (features, floorLocationId, viewedLocationId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_FEATURES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const geoJsonFeatureIds = features.map(feature => feature.id);

  axios.post(`${apiUrl}companies/${companyId}/locations/${floorLocationId}/map/delete-features`, {
      geoJsonFeatureIds
    }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_FEATURES));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());

      if (redirect) {
        redirect(`/companies/${companyId}/locations/${viewedLocationId}/locations`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_FEATURES));
      }
    });
};

export const registerCompanyFeature = (feature, companyId) => async (dispatch) => {
  dispatch(actions.requestData(types.REGISTER_COMPANY_FEATURE));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const features = [feature];

  // Strip properties and id
  const createdFeatures = features.map(aFeature => ({
    ...aFeature,
    id: undefined,
    properties: undefined
  }));

  actions.createCompanyFeatures(createdFeatures, companyId, apiUrl)
    .then(() => {
      dispatch(actions.success(types.REGISTER_COMPANY_FEATURE));
      dispatch(authActions.getCompanyMap());
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.REGISTER_COMPANY_FEATURE));
      }
    });
};

export const changeCompanyFeatures = (locationFeatures) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_FEATURES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Strip properties
  const updatedFeatures = locationFeatures.map(feature => ({
    ...feature,
    properties: undefined
  }));

  actions.updateCompanyFeatures(updatedFeatures, apiUrl)
    .then(() => {
      dispatch(actions.success(types.UPDATE_FEATURES));
      dispatch(selectionActions.clearSelection());
      dispatch(authActions.getCompanyMap());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    });
};

export const deleteCompanyFeatures = (features) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_FEATURES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const geoJsonFeatureIds = features.map(feature => feature.id);

  axios.post(`${apiUrl}companies/${companyId}/map/delete-features`, {
      geoJsonFeatureIds
    }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_FEATURES));
      dispatch(authActions.getCompanyMap());
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_FEATURES));
      }
    });
};
