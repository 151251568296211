import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  companies: {
    results: []
  },
  warning: null,
  warnings: {
    results: []
  },
  logs: {
    results: [],
    offset: 0,
  },
  companiesCorrelationId: null,
  warningCorrelationId: null,
  warningsCorrelationId: null,
  logsCorrelationId: null,
};

export default function alarmsReducer(state = initialState, action) {
  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_WARNINGS_SUMMARY) {
        return {
          ...state,
          companies: initialState.companies,
          companiesCorrelationId: action.metadata.correlationId
        }
      }

      if (action.fetchType === types.GET_WARNINGS) {
        const newState = { 
          ...state, 
          warningsCorrelationId: action.metadata.correlationId
        };

        // Clear the warnings if the offset is the same or the state is different (e.g. loading new alarms)
        if (action.metadata.offset === state.warnings.offset || action.metadata.state !== state.warnings.state) {
          newState.warnings = initialState.warnings;
        }
        
        return newState;
      }

      if (action.fetchType === types.GET_WARNING) {
        return {
          ...state,
          warning: null,
          warningCorrelationId: action.metadata.correlationId
        }
      }
      
      if (action.fetchType === types.GET_WARNING_LOGS) {
        return {
          ...state,
          logsCorrelationId: action.metadata.correlationId
        }
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_WARNINGS_SUMMARY_PART) {

        // Check if the data is old
        if (action.metadata.correlationId !== state.companiesCorrelationId) {
          return state;
        }

        // Append the companies to the existing companies
        const companies = state.companies.results.concat(action.payload.results);

        return {
          ...state,
          companies: {
            ...action.payload,
            results: companies
          }
        }
      }

      if (action.fetchType === types.GET_WARNINGS) {

        // Check if the data is old
        if (action.metadata.correlationId !== state.warningsCorrelationId) {
          return state;
        }

        // Add metadata.state to the results
        action.payload.results.map(warning => {
          warning.state = action.metadata.state;
          return warning;
        });

        // Combine the results with the existing results (if any)
        const results = state.warnings.results.concat(action.payload.results);
        return {
          ...state,
          warnings: {
            ...action.payload,
            state: action.metadata.state,
            results
          }
        }
      }

      if (action.fetchType === types.GET_WARNING) {

        // Check if the data is old
        if (action.metadata.correlationId !== state.warningCorrelationId) {
          return state;
        }

        return {
          ...state,
          warning: action.payload
        }
      }

      if (action.fetchType === types.GET_WARNING_LOGS) {

        // Check if the data is old
        if (action.metadata.correlationId !== state.logsCorrelationId) {
          return state;
        }

        return {
          ...state,
          logs: action.payload
        }
      }

      if (action.fetchType === types.CREATE_COMMENT) {
        toast.success("Comment created");
        return state;
      }

      if (action.fetchType === types.UPDATE_COMMENT) {
        toast.success("Comment updated");
        return state;
      }

      if (action.fetchType === types.DELETE_COMMENT) {
        toast.success("Comment deleted");
        return state;
      }

      if (action.fetchType === types.MUTE_WARNING) {
        toast.success("Alarm silenced");
        return state;
      }

      if (action.fetchType === types.UNMUTE_WARNING) {
        toast.success("Alarm silenced removed");
        return state;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = action?.payload?.response?.status ?? "Error";

      if (action.fetchType === types.GET_WARNINGS_SUMMARY) {
        toast.error(`${statusCode}: Could not download alarm overview`);
        return state;
      }

      if (action.fetchType === types.GET_WARNINGS) {
        toast.error(`${statusCode}: Could not download alarms`);
        return state;
      }

      if (action.fetchType === types.GET_WARNING) {
        toast.error(`${statusCode}: Could not download alarm`);
        return state;
      }

      if (action.fetchType === types.GET_WARNING_LOGS) {
        toast.error(`${statusCode}: Could not download alarm logs`);
      }

      if (action.fetchType === types.CREATE_COMMENT) {
        toast.error(`${statusCode}: Could not create comment`);
      }

      if (action.fetchType === types.UPDATE_COMMENT) {
        toast.error(`${statusCode}: Could not update comment`);
      }

      if (action.fetchType === types.DELETE_COMMENT) {
        toast.error(`${statusCode}: Could not delete comment`);
      }

      if (action.fetchType === types.MUTE_WARNING) {
        toast.error(`${statusCode}: Could not mute alarm`);
      }

      if (action.fetchType === types.UNMUTE_WARNING) {
        toast.error(`${statusCode}: Could not unmute alarm`);
      }

      return state;
    }

    default:
      return state;
  }
}