import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, API_URL_DASHBOARD, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";
import { LOCATION_DATA_ERROR } from "../dashboardHelpers";

const cookies = new Cookies();

export const getDaySamples = (inLocation, locationIds, sensorType, aggregation, date, showWholeDay, coreTime, hashKey, capacity) => async (dispatch) => {
  // console.log("getDaySamples", locationIds, coreTime);

  const body = {
    "includeHolidays": true,
    "includeWeekends": true,
    "locations": locationIds,
    "aggregation": aggregation,
  };

  if (showWholeDay) {
    const start = date.clone();
    body.startDate = start.format("YYYY-MM-DD");
    body.endDate = start.format("YYYY-MM-DD");
  }
  else {
    const start = date.clone().set("hours", coreTime.start.hours).set("minutes", coreTime.start.minutes);
    body.startDate = start.format("YYYY-MM-DD");
    body.startTime = start.format("HH:mm:00");

    const end = date.clone().set("hours", coreTime.end.hours).set("minutes", coreTime.end.minutes);
    body.endDate = end.format("YYYY-MM-DD");
    body.endTime = end.format("HH:mm:00");
  }
  
  if (inLocation) {
    dispatch(getSampleHistory(locationIds[0], body, sensorType, hashKey, capacity));
  }
  else {
    // console.log("getSampleHistory location count", body.locations.length);

    // Do not load data if the query is too big
    if (sensorType === "occupiedMinutes" && body.locations.length > 400) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 400, count: body.locations.length } });
    }
    else if (sensorType === "occupiedMinutes" && date.diff(moment().startOf("week").subtract(6, "weeks")) < 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_OLD }});
    }
    else if (locationIds.length === 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.NO_LOCATIONS }});
    }
    else {
      dispatch(getSampleHistory("all", body, sensorType, hashKey, capacity));
    }
  }
};

export const getWeekSamples = (inLocation, locationIds, sensorType, aggregation, startDate, endDate, showWeekends, showHolidays, showWholeDay, coreTime, hashKey, capacity) => async (dispatch) => {
  // console.log("getWeekSamples", locationIds);

  const body = {
    "includeHolidays": showHolidays,
    "includeWeekends": showWeekends,
    "locations": locationIds,
    "aggregation": aggregation,
    "startDate": startDate.format("YYYY-MM-DD")
  };

  if (showWholeDay) {
    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = endDate.format("YYYY-MM-DD");
    }
  }
  else {
    const start = startDate.clone().set("hours", coreTime.start.hours).set("minutes", coreTime.start.minutes);
    const end = endDate.clone().set("hours", coreTime.end.hours).set("minutes", coreTime.end.minutes);
    body.startTime = start.format("HH:mm:00");
    body.endTime = end.format("HH:mm:00");

    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = end.format("YYYY-MM-DD");
    }
  }

  if (inLocation) {
    dispatch(getSampleHistory(locationIds[0], body, sensorType, hashKey, capacity));
  }
  else {
    // Do not load data if the query is too big
    if (sensorType === "occupiedMinutes" && body.locations.length > 200) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 200, count: body.locations.length } });
    }
    else if (sensorType === "occupiedMinutes" && startDate.diff(moment().startOf("week").subtract(6, "weeks")) < 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_OLD }});
    }
    else if (locationIds.length === 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.NO_LOCATIONS }});
    }
    else {
      dispatch(getSampleHistory("all", body, sensorType, hashKey, capacity));
    }
  }
};

export const getMonthSamples = (inLocation, locationIds, sensorType, aggregation, startDate, endDate, showWeekends, showHolidays, showWholeDay, coreTime, hashKey, capacity) => async (dispatch) => {

  console.log("getMonthSamples location count", locationIds.length);

  const body = {
    "includeHolidays": showHolidays,
    "includeWeekends": showWeekends,
    "locations": locationIds,
    "aggregation": aggregation,
    "startDate": startDate.format("YYYY-MM-DD")
  };

  if (showWholeDay) {
    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = endDate.format("YYYY-MM-DD");
    }
  }
  else {
    const start = startDate.clone().set("hours", coreTime.start.hours).set("minutes", coreTime.start.minutes);
    const end = endDate.clone().set("hours", coreTime.end.hours).set("minutes", coreTime.end.minutes);
    body.startTime = start.format("HH:mm:00");
    body.endTime = end.format("HH:mm:00");

    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = end.format("YYYY-MM-DD");
    }
  }

  if (inLocation) {
    console.log("getSampleHistory location count", sensorType,  body.locations.length);
    dispatch(getSampleHistory(locationIds[0], body, sensorType, hashKey, capacity));
  }
  else {
    console.log("getSampleHistory location count", sensorType,  body.locations.length);
    console.log("sensorType", sensorType);
    console.log("hashKey", hashKey);
    const queryType = hashKey.split("-")[0];
    // console.log("queryType", queryType);

    // Do not load data if the query is too big
    if (["occupiedMinutes"].includes(sensorType) && body.locations.length > 100) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 100, count: body.locations.length }});
    }
    else if (["peopleCompareDayOfWeek"].includes(queryType) && body.locations.length > 800) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 800, count: body.locations.length }});
    }
    else if (["occupiedMinutes"].includes(sensorType) && startDate.diff(moment().startOf("week").subtract(6, "weeks")) < 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.TOO_OLD }});
    }
    else if (locationIds.length === 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.NO_LOCATIONS }});
    }
    else {
      dispatch(getSampleHistory("all", body, sensorType, hashKey, capacity));
    }
  }
};

export const getAnySamples = (inLocation, locationIds, sensorType, aggregation, startDate, endDate, showWeekends, showHolidays, showWholeDay, coreTime, hashKey, capacity) => async (dispatch) => {

  console.log("getAnySamples location count", locationIds.length);

  const body = {
    "includeHolidays": showHolidays,
    "includeWeekends": showWeekends,
    "locations": locationIds,
    "aggregation": aggregation,
    "startDate": startDate.format("YYYY-MM-DD")
  };

  if (showWholeDay) {
    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = endDate.format("YYYY-MM-DD");
    }
  }
  else {
    const start = startDate.clone().set("hours", coreTime.start.hours).set("minutes", coreTime.start.minutes);
    const end = endDate.clone().set("hours", coreTime.end.hours).set("minutes", coreTime.end.minutes);
    body.startTime = start.format("HH:mm:00");
    body.endTime = end.format("HH:mm:00");

    if (endDate && moment().diff(endDate) > 0) {
      body.endDate = end.format("YYYY-MM-DD");
    }
  }

  if (inLocation) {
    console.log("getSampleHistory location count", sensorType,  body.locations.length);
    dispatch(getSampleHistory(locationIds[0], body, sensorType, hashKey, capacity));
  }
  else {
    console.log("getSampleHistory location count", sensorType,  body.locations.length);
    console.log("sensorType", sensorType);
    console.log("hashKey", hashKey);
    const queryType = hashKey.split("-")[0];
    // console.log("queryType", queryType);

    // Do not load data if the query is too big
    if (["occupiedMinutes"].includes(sensorType) && body.locations.length > 100) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 100, count: body.locations.length }});
    }
    else if (["peopleCompareDayOfWeek"].includes(queryType) && body.locations.length > 800) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: { body, hashKey, error: LOCATION_DATA_ERROR.TOO_MANY_LOCATIONS, max: 800, count: body.locations.length }});
    }
    else if (["occupiedMinutes"].includes(sensorType) && startDate.diff(moment().startOf("week").subtract(8, "weeks")) < 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.TOO_OLD }});
    }
    else if (locationIds.length === 0) {
      dispatch({ type: types.STOP_GET_SENSOR_HISTORY, payload: {body, hashKey, error: LOCATION_DATA_ERROR.NO_LOCATIONS }});
    }
    else {
      dispatch(getSampleHistory("all", body, sensorType, hashKey, capacity));
    }
  }
};

export const getSampleHistory = (locationId, body, sensorType, hashKey, capacity) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SENSOR_HISTORY, { locationId, body, hashKey }));

  const apiUrl = await API_URL_DASHBOARD();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const newBody = { ...body, timezone: "CET" }; 

  axios.post(`${apiUrl}companies/${companyId}/data/default/${sensorType}`, newBody, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR_HISTORY, { locationId, body, hashKey, capacity }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR_HISTORY, { locationId, body, hashKey }));
      }
    });
};

export const storeDashboardQueryHash = (locationId, hashKeys) => async (dispatch) => {
  // dispatch({ type: types.STORE_DASHBOARD_QUERY_HASH, payload: queryHash });
  // dispatch(actions.requestData(types.INITIATE_SENSOR_HISTORY, { locationId, hashKeys }));
};

export const getOfflineGateways = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_OFFLINE_GATEWAYS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/dashboard/offline-gateways`, await actions.getAxiosConfig({ ...queryParams, limit: 100 }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_OFFLINE_GATEWAYS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_OFFLINE_GATEWAYS));
      }
    });
}

export const getOfflineSensors = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_OFFLINE_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/dashboard/offline-sensors`, await actions.getAxiosConfig({ ...queryParams, limit: 100 }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_OFFLINE_SENSORS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_OFFLINE_SENSORS));
      }
    });
}

export const getSystemUptime = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SYSTEM_UPTIME));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/dashboard/uptime-metrics`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SYSTEM_UPTIME));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SYSTEM_UPTIME));
      }
    });
}

export const getUniqueUserLogins = () => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UNIQUE_USER_LOGINS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    month: moment().utc().startOf("month").subtract(1, "month").toISOString(),
  }

  axios.get(`${apiUrl}companies/${companyId}/dashboard/unique-user-logins`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UNIQUE_USER_LOGINS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UNIQUE_USER_LOGINS));
      }
    });
}