import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { faPlug, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { get, isEmpty } from "lodash";
import { RowIcon, BLUE, GREEN, GREY, RED } from "../../components/RowIcon";
import Table from "../../components/Table";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import OptionFooter from "../../components/OptionFooter";
import NoAccess from "../NoAccess";
import * as gatewayActions from "../../actions/gateways";
import * as selectedActions from "../../actions/selected";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class Sensors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      searchText: "",
      sortBy: null,
      sortOrder: "asc",
      offset: 0,
      limit: 100,
    };
    
    this.onRowClick = this.onRowClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);

    // Reset highlighted feature
    this.props.setHighlightedFeature(null);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/sensors/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        // Clear any pending leave timeout
        if (this.leaveTimeout) {
          clearTimeout(this.leaveTimeout);
          this.leaveTimeout = null;
        }
        if (rowInfo?.original?.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => {
        // Delay the clearing of hover state
        this.leaveTimeout = setTimeout(() => {
          this.onHover(null);
        }, 100);
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onHover(id) {
    this.props.setHighlightedFeature(id);
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };
    
    if (queryParams.sortBy === "properties.metadata.connectionState") {
      queryParams.sortBy = "status";
    }

    this.props.getGatewaySensors(this.state.id, queryParams);
  }

  render() {
    const { isLoading } = this.props;

    const canViewGateway = this.props.auth.hasAdminRole;
    if (!canViewGateway) {
      return <NoAccess />;
    }
    
    if (this.props.gateway.properties === null) {
      return null;
    }

    const tableElement = (
      <Table
        data={this.props.gatewaySensors.sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.gatewaySensors.count}
        noDataText={isLoading ? "" : "No sensors found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "ID",
            accessorKey: "properties.metadata.vendorInfo.sensorId",
            sortable: false,
          },
          {
            id: "connectionState",
            header: "",
            accessorKey: "properties.metadata.connectionState",
            width: 40,
            cell: ({ row }) => {
              const { connectionState } = row.original.properties.metadata;
              if (connectionState === "connected") {
                return <RowIcon tooltip="connected" bgColor={GREEN} icon={faPlug} />
              }

              if (connectionState === "disconnected") {
                return <RowIcon tooltip="disconnected" bgColor={RED} icon={faPlug} />
              }

              return <RowIcon tooltip="unknown" bgColor={GREY} icon={faPlug} />         
            }
          },
          {
            id: "inMap",
            header: "",
            accessorKey: "properties.metadata.geoJsonFeature",
            width: 40,
            cell: ({ row }) => {
              const { geoJsonFeature } = row.original.properties.metadata;
              if (!isEmpty(geoJsonFeature)) {
                return <RowIcon tooltip="in map" bgColor={BLUE} icon={faMapMarkerAlt} />;
              }
              
              return <RowIcon tooltip="not in map" bgColor={GREY} icon={faMapMarkerAlt} />;
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => {
              const sensors = Object.keys(row.original.properties).filter((key) => (key !== "metadata"));
              return (<div>{sensors.length > 0 ? (<div className="arrow" />) : null}</div>)
            }
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.gatewaySensorsIsLoading}
      />
    );

    const content = (
      <>
        <TopRowOptions
          searchbox={(
            <SearchBox 
              value={this.state.searchText}
              onSearchChanged={this.onSearchChange}
              onSearchClick={this.onSearchClick}
              onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
              inListView
            />
          )}
        />
        { tableElement }
        <div style={{ paddingTop: "40px" }} />
      </>
    );

    return (
      <div className={style.listContainer}>
        <Hidden xs sm md>
          <div className={style.scroll}>
            { content }
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll}>
            { content }
          </div>
        </Visible>
        <OptionFooter 
          cancel={null} 
          options={[]} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gateway: state.gateway,
    currentLocation: state.location,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    isLoading: state.loading.gateways,
    isLoadingLocation: state.loading[API.GET_LOCATION],
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    createdFeature: state.selected.createdFeature,
    companyMap: state.auth.map,
    gatewaySensors: state.sensors.gatewaySensors,
    gatewaySensorsIsLoading: state.sensors.gatewaySensorsIsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectGateway: selectedActions.selectGateway,
    updateGateway: gatewayActions.updateGateway,
    getGatewaySensors: gatewayActions.getGatewaySensors,
    clearSelection: selectedActions.clearSelection,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);
