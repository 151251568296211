import { get, has } from "lodash";
import { toast } from "react-toastify";
import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, FETCH_MESSAGE, REQ_DATA, RECV_DATA, LOGGING_IN, GET_COMPANY, RECV_ERROR, GET_COMPANY_MAP, GET_PROFILE, SYNC_COMPANY_MAP, GET_ROOM_MAP_LINK, CLEAR_ROOM_MAP_LINK, RESTART_YANZI_WORKER, GET_VORTO_IDS, GET_WEB_APP_URL, SEND_CONSENT_EMAIL } from "../ApiTypes";
import { Role } from "../helpers";

const initialState = {
  hasCheckedCookie: false,
  authenticated: true,
  user: null,
  role: 0,

  hasSudoRole: false,
  hasITSupportRole: false,
  hasITAdminRole: false,
  hasSupportRole: false,
  hasAdminRole: false,
  hasUserRole: false,
  isUserRole: false,

  hasInternalRole: false,
  hasInternalITRole: false,
  hasAnyInternalRole: false,
  hasAnyInternalITRole: false,

  // companies: {},
  selectedCompany: {}, 
  nextCompanyId: null,
  vortoIds: [],
  vendors: {},
  map: null,
  roomMapURL: null,
  webAppUrl: null,

  enableWPAReportAccess: false,
  enableEnergyReportAccess: false,
  enableNoShowReportAccess: false,
  enableAdminInvites: true,
};

const getRole = (user, companyId) => {

  // For testing
  // return Role.ITAdmin;
  // return Role.Support;
  // return Role.Admin;
  // return Role.User;

  if (!user) {
    return Role.User;
  }
  
  const company = user.companies.find(company => (company._id === companyId));
  if (!company) {
    return Role.User;
  }

  // Get highest role
  let highestRole = Role.User;
  company.roles.forEach((role) => {
    if (role.name === "sudo") {
      highestRole = Role.Sudo;
    }
    else if (role.name === "support" && highestRole < Role.ITSupport) {
      highestRole = Role.ITSupport;
    }
    else if (role.name === "itAdmin" && highestRole < Role.ITAdmin) {
      highestRole = Role.ITAdmin;
    }
    else if (role.name === "installer" && highestRole < Role.Support) {
      highestRole = Role.Support;
    }
    else if (role.name === "admin" && highestRole < Role.Admin) {
      highestRole = Role.Admin;
    }
    else if (role.name === "user" && highestRole < Role.User) {
      highestRole = Role.User;
    }
  });

  return highestRole;
};

const getInternalRoles = (user, companyId) => {

  // Check if the user has internal role in current company or any company
  let hasInternalRole = false;
  let hasInternalITRole = false;
  let hasAnyInternalRole = false;
  let hasAnyInternalITRole = false;

  let highestRole = Role.User;
  user.companies.forEach((company) => {
    company.roles.forEach((role) => {
      if (role.name === "sudo") {
        highestRole = Role.Sudo;
      }
      else if (role.name === "support" && highestRole < Role.ITSupport) {
        highestRole = Role.ITSupport;
      }
      else if (role.name === "itAdmin" && highestRole < Role.ITAdmin) {
        highestRole = Role.ITAdmin;
      }
      else if (role.name === "installer" && highestRole < Role.Support) {
        highestRole = Role.Support;
      }
      else if (role.name === "admin" && highestRole < Role.Admin) {
        highestRole = Role.Admin;
      }
      else if (role.name === "user" && highestRole < Role.User) {
        highestRole = Role.User;
      }
    });

    // Check if the user has internal role in current company or any company
    const internalRole = company.roles.some(role => (role.name === "sudo" || role.name === "support" || role.name === "installer"));
    const internalITRole = company.roles.some(role => (role.name === "sudo" || role.name === "support"));

    // Current company
    if (company._id === companyId) {
      hasInternalRole = internalRole;
      hasInternalITRole = internalITRole;
    }

    // Any company
    hasAnyInternalRole = hasAnyInternalRole || internalRole;
    hasAnyInternalITRole = hasAnyInternalITRole || internalITRole;

  });

  return {
    hasAnyInternalRole,
    hasAnyInternalITRole,
    hasInternalRole,
    hasInternalITRole,
  };
};

export default function authReducer(state = initialState, action) {
  
  switch (action.type) {

    case AUTH_USER:
      return {...state, hasCheckedCookie: true, authenticated: true }

    case UNAUTH_USER:
      localStorage.removeItem("company_id");
      return {...initialState, hasCheckedCookie: true, authenticated: false };

    case AUTH_ERROR:
      return {...state, hasCheckedCookie: true, error: action.payload };

    case FETCH_MESSAGE:
      return {...state, message: action.payload };

    case REQ_DATA: {

      if (action.fetchType === GET_PROFILE) {
        return {...state };
      }

      if (action.fetchType === GET_COMPANY) {
        return {...state, selectedCompany: {}, nextCompanyId: action.metadata.nextCompanyId };
      }

      if (action.fetchType === GET_COMPANY_MAP) {
        return {...state, map: null };
      }

      if (action.fetchType === SYNC_COMPANY_MAP) {
        return state;
      }

      if (action.fetchType === RESTART_YANZI_WORKER) {
        return state;
      }

      if (action.fetchType === GET_VORTO_IDS) {
        return {...state, vortoIds: [], vendors: {} };
      }

      return state;
    }

    case RECV_DATA: {

      if (action.fetchType === GET_PROFILE) {
        const user = action.payload;
        const newState = { ...state, user };

        // Sort companies alfabetically
        user.companies = user.companies.sort((a,b) => a.name > b.name ? 1 : -1);

        // If user has internal role in any company
        const internalRoles = getInternalRoles(user);
        newState.hasInternalRole = internalRoles.hasInternalRole;
        newState.hasInternalITRole = internalRoles.hasInternalITRole;
        newState.hasAnyInternalRole = internalRoles.hasAnyInternalRole;
        newState.hasAnyInternalITRole = internalRoles.hasAnyInternalITRole;

        // If already gotten selectedCompany - get the role for that company
        if (state.selectedCompany._id) {
          const userCompany = user.companies.find(company => (company._id === state.selectedCompany._id));
          if (userCompany) {
            const role = getRole(user, state.selectedCompany._id);
            newState.role = role;
            newState.hasSudoRole = role === Role.Sudo;
            newState.hasITSupportRole = role >= Role.ITSupport; // IT Support
            newState.hasITAdminRole = role >= Role.ITAdmin; // Account owner
            newState.hasSupportRole = role >= Role.Support; // Support
            newState.hasAdminRole = role >= Role.Admin;
            newState.hasUserRole = role >= Role.User;
            newState.isUserRole = role === Role.User;
            newState.enableWPAReportAccess = get(userCompany, "serviceFeatures.enableWPAReportAccess", false);
            newState.enableEnergyReportAccess = get(userCompany, "serviceFeatures.enableEnergyReportAccess", false);
            newState.enableNoShowReportAccess = get(userCompany, "serviceFeatures.enableNoShowReportAccess", false);
            newState.enableAdminInvites = get(state.selectedCompany, "serviceFeatures.enableAdminInvites", true);
          }
        }

        return newState;
      }

      if (action.fetchType === LOGGING_IN) {
        return {...state, error: '', authenticated: true};
      }

      if (action.fetchType === GET_COMPANY) {

        const selectedCompany = action.payload;

        // Convert mazemap values to let the UI differentiate between the initial value and the downloaded value
        // mazemapCampusTag: null -> ""
        // mazemapApiKey: null -> ""
        if (selectedCompany.mazemapCampusTag === null) {
          selectedCompany.mazemapCampusTag = "";
        }

        if (selectedCompany.mazemapApiKey === null) {
          selectedCompany.mazemapApiKey = "";
        }

        if (selectedCompany.mazemapCampusTag === "foo") {
          selectedCompany.mazemapCampusTag = "";
        }

        if (selectedCompany.mazemapApiKey === "foo") {
          selectedCompany.mazemapApiKey = "";
        }

        if (state.user && state.user.companies) {
          const userCompany = state.user.companies.find(company => (company._id === action.payload._id));
          if (userCompany) {
            const role = getRole(state.user, action.payload._id);

            return {
              ...state,
              selectedCompany: action.payload,
              role,
              hasSudoRole: role === Role.Sudo,
              hasITSupportRole: role >= Role.ITSupport,
              hasITAdminRole: role >= Role.ITAdmin,
              hasSupportRole: role >= Role.Support,
              hasAdminRole: role >= Role.Admin,
              hasUserRole: role >= Role.User,
              isUserRole: role === Role.User,
              enableWPAReportAccess: get(userCompany, "serviceFeatures.enableWPAReportAccess", false),
              enableEnergyReportAccess: get(userCompany, "serviceFeatures.enableEnergyReportAccess", false),
              enableNoShowReportAccess: get(userCompany, "serviceFeatures.enableNoShowReportAccess", false),
              enableAdminInvites: get(selectedCompany, "serviceFeatures.enableAdminInvites", true),
            };
          }
        }
        
        return {...state, selectedCompany: action.payload };
      }

      if (action.fetchType === GET_COMPANY_MAP) {
        return {...state, map: action.payload };
      }

      if (action.fetchType === GET_WEB_APP_URL) {
        return {...state, webAppUrl: action.payload };
      }

      if (action.fetchType === SYNC_COMPANY_MAP) {
        return state;
      }

      if (action.fetchType === RESTART_YANZI_WORKER) {
        return state;
      }

      if (action.fetchType === GET_ROOM_MAP_LINK) {
        return {...state, roomMapURL: action.payload }; 
      }

      if (action.fetchType === GET_VORTO_IDS) {
        const vortoIds = action.payload;
        
        // Put the vortoIds in Vendor groups with name and version groups
        const vendors = {};
        vortoIds.forEach((vorto) => {
          const vendor = vorto.vendor;
          const model = vorto.model;
          const version = vorto.version;

          if (!vendors[vendor]) {
            vendors[vendor] = {};
          }

          if (!vendors[vendor][model]) {
            vendors[vendor][model] = {};
          }

          vendors[vendor][model][version] = vorto.id;
        });

        return {...state, vortoIds, vendors };
      }

      if (action.fetchType === SEND_CONSENT_EMAIL) {
        toast.success("Consent email sent");
        return state;
      }
      
      return state;
    }

    case RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === GET_PROFILE) {
        toast.error(`${statusCode}: Could not get profile`);
        return {...state, user: null };
      }

      if (action.fetchType === GET_COMPANY) {
        toast.error(`${statusCode}: Could not get company`);
        return {
          ...state,
          selectedCompany: {},
          role: 0,
          hasSudoRole: false,
          hasITSupportRole: false,
          hasITAdminRole: false,
          hasSupportRole: false,
          hasAdminRole: false, 
          hasUserRole: false,
          isUserRole: false,
          enableWPAReportAccess: false,
          enableEnergyReportAccess: false,
          enableNoShowReportAccess: false,
        };
      }

      if (action.fetchType === GET_COMPANY_MAP) {
        toast.error(`${statusCode}: Could not get map`);
        return {...state, map: null };
      }

      if (action.fetchType === SYNC_COMPANY_MAP) {
        toast.error(`${statusCode}: Could not sync map`);
        return state;
      }

      if (action.fetchType === RESTART_YANZI_WORKER) {
        toast.error(`${statusCode}: Could not restart yanzi worker`);
        return state;
      }

      if (action.fetchType === GET_VORTO_IDS) {
        toast.error(`${statusCode}: Could not get Vorto IDs`);
        return {...state, vortoIds: [], vendors: {} };
      }

      if (action.fetchType === SEND_CONSENT_EMAIL) {
        toast.error(`${statusCode}: Could not send consent email`);
        return state;
      }
      
      return state;
    }

    case CLEAR_ROOM_MAP_LINK: {
      return {...state, roomMapURL: null }; 
    }

    default:
      return state;
  }
}
