import React from "react";
import styled from "styled-components";

interface CheckboxBlock {
  label: string;
  isChecked: boolean;
  onClick: () => {};
  disabled: boolean;
  style?: any;
}

const CheckboxBlock: React.FC<CheckboxBlock> = ({ label, isChecked, onClick, disabled, style }) => (
  <Container style={style}>
    <Label>
      <Checkbox type="checkbox" onChange={onClick} checked={isChecked} disabled={disabled} />
      <Checkmark />
      {label}
    </Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
  height: 33px;
`;

const Checkmark = styled.span`
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-color: #cacaca;
  border-radius: 3px;

  &::after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 11px;
    border: solid #444;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkbox = styled.input`
  display: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  left: -9999px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Source Sans Pro;
  font-size: 15px;
  color: #444;

  &:hover input ~ ${Checkmark} {
    background-color: #F1F2F3;
  }

  input:checked ~ ${Checkmark}:after {
    display: block;
  }

  ${Checkmark}:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export default CheckboxBlock;