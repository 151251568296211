import * as types from "../ApiTypes";

const initialState = {};

export default function loadingReducer(state = initialState, action) {
  const newState = { ...state };

  if (action.fetchType === null) {
    return newState;
  }
  
  if (action.type === types.REQ_DATA || action.type === types.POST_DATA) {
    newState[action.fetchType] = true;
  }
  else {
    delete newState[action.fetchType];
  }

  // Update loading groups
  const appUsageLoadingTypes = [types.GET_UNIQUE_USER_LOGINS];
  newState.appUsage = appUsageLoadingTypes.some(type => newState[type]);

  const authLoadingTypes = [types.GET_COMPANY];
  newState.auth = authLoadingTypes.some(type => newState[type]);

  const unregisteredSensorsLoadingTypes = [types.GET_UNREGISTERED_SENSORS, types.GET_UNREGISTERED_SENSOR, types.UPDATE_UNREGISTERED_SENSOR, types.UPDATE_UNREGISTERED_SENSORS, types.CREATE_UNREGISTERED_SENSORS, types.GET_UNREGISTERED_SENSOR_SAMPLES];
  newState.unregisteredSensors = unregisteredSensorsLoadingTypes.some(type => newState[type]);

  const contentLoadingTypes = [types.GET_CONTENT, types.CREATE_CONTENT, types.UPDATE_CONTENT, types.DELETE_CONTENT, types.SEND_NOTIFICATION];
  newState.content = contentLoadingTypes.some(type => newState[type]);

  const contentsLoadingTypes = [types.GET_ALL_CONTENT];
  newState.allContent = contentsLoadingTypes.some(type => newState[type]);

  const customTagLoadingTypes = [types.GET_CUSTOM_TAG, types.CREATE_CUSTOM_TAG, types.UPDATE_CUSTOM_TAG, types.DELETE_CUSTOM_TAG];
  newState.customTag = customTagLoadingTypes.some(type => newState[type]);

  const customTagsLoadingTypes = [types.GET_CUSTOM_TAGS];
  newState.customTags = customTagsLoadingTypes.some(type => newState[type]);

  const displaysLoadingTypes = [types.GET_DISPLAYS, types.GET_DISPLAY, types.CREATE_DISPLAY, types.UPDATE_DISPLAY, types.DELETE_DISPLAY];
  newState.displays = displaysLoadingTypes.some(type => newState[type]);

  const filterLoadingTypes = [types.QUERY_LOCATIONS, types.GET_SENSOR_TYPES, types.GET_LOCATION_HIERARCHY, types.LOAD_FILTER];
  newState.filter = filterLoadingTypes.some(type => newState[type]);

  const filtersLoadingTypes = [types.GET_FILTERS, types.GET_FILTER, types.CREATE_FILTER, types.UPDATE_FILTER, types.DELETE_FILTERS];
  newState.filters = filtersLoadingTypes.some(type => newState[type]);

  const gatewayLoadingTypes = [types.GET_GATEWAY];
  newState.gateway = gatewayLoadingTypes.some(type => newState[type]);

  const gatewaysLoadingTypes = [types.GET_GATEWAYS, types.GET_ALL_GATEWAYS, types.CREATE_GATEWAY];
  newState.gateways = gatewaysLoadingTypes.some(type => newState[type]);

  const insightsLoadingTypes = [types.GET_INSIGHT_DASHBOARDS];
  newState.insights = insightsLoadingTypes.some(type => newState[type]);

  const jobLoadingTypes = [types.GET_JOB, types.DELETE_JOB];
  newState.job = jobLoadingTypes.some(type => newState[type]);

  const jobGroupLoadingTypes = [types.GET_JOB_GROUP];
  newState.jobGroup = jobGroupLoadingTypes.some(type => newState[type]);

  const jobGroupsLoadingTypes = [types.GET_JOB_GROUPS];
  newState.jobGroups = jobGroupsLoadingTypes.some(type => newState[type]);

  const locationLoadingTypes = [types.GET_LOCATION, types.GET_FLOOR_MAP, types.SYNC_FLOOR_MAP, types.EDIT_LOCATION];
  newState.location = locationLoadingTypes.some(type => newState[type]);

  const locationsLoadingTypes = [types.GET_LOCATIONS, types.GET_LOCATION, types.GET_ROOT_LOCATION, types.GET_LOCATION_HIERARCHY, types.QUERY_LOCATIONS, types.ADD_LOCATION, types.MOVE_LOCATIONS, types.DELETE_LOCATIONS, types.MAP_FEATURE_TO_LOCATION, types.REMOVE_FEATURES_FROM_LOCATIONS, types.UPLOAD_LOCATION_CSV];
  newState.locations = locationsLoadingTypes.some(type => newState[type]);

  const locationSearcgLoadingTypes = [types.SEARCH_LOCATIONS];
  newState.locationSearch = locationSearcgLoadingTypes.some(type => newState[type]);

  const locationTagsLoadingTypes = [types.GET_LOCATION_TAGS, types.GET_LOCATION_TAG, types.CREATE_LOCATION_TAG, types.UPDATE_LOCATION_TAG, types.DELETE_LOCATION_TAG];
  newState.locationTags = locationTagsLoadingTypes.some(type => newState[type]);

  const occupancyLoadingTypes = [types.GET_OCCUPANCY_METRICS];
  newState.occupancy = occupancyLoadingTypes.some(type => newState[type]);

  const screenLoadingTypes = [types.GET_SCREEN_GROUP, types.UPDATE_SCREEN_GROUP, types.DELETE_SCREEN_GROUP];
  newState.screen = screenLoadingTypes.some(type => newState[type]);

  const screensLoadingTypes = [types.GET_SCREEN_GROUPS, types.CREATE_SCREEN_GROUP, types.DELETE_SCREEN_GROUP];
  newState.screens = screensLoadingTypes.some(type => newState[type]);

  const sensorLoadingTypes = [types.GET_SENSOR, types.UPDATE_SENSOR, types.DELETE_SENSOR, types.UPDATE_ATOMIC_SENSOR];
  newState.sensor = sensorLoadingTypes.some(type => newState[type]);

  const sensorsLoadingTypes = [types.GET_SENSORS, types.GET_ALL_SENSORS, types.GET_DISCOVERED_SENSORS, types.GET_GATEWAY_SENSORS, types.CREATE_SENSOR, types.MOVE_SENSORS, types.MOVE_SENSORS_TO_GATEWAY, types.GET_WEBEX_DEVICES, types.GET_WEBEX_MACRO_STATUS];
  newState.sensors = sensorsLoadingTypes.some(type => newState[type]);

  const sensorTagsLoadingTypes = [types.GET_SENSOR_TAGS, types.GET_SENSOR_TAG, types.CREATE_SENSOR_TAG, types.UPDATE_SENSOR_TAG, types.DELETE_SENSOR_TAG];
  newState.sensorTags = sensorTagsLoadingTypes.some(type => newState[type]);

  const sudoLoadingTypes = [types.GET_ADMIN_COMPANIES, types.GET_SUDO_COMPANIES, types.GET_SUDO_COMPANY, types.UPDATE_COMPANY, types.CREATE_COMPANY, types.DELETE_COMPANY, types.SYNC_YANZI];
  newState.sudo = sudoLoadingTypes.some(type => newState[type]);

  const companyLoadingTypes = [types.GET_COMPANY_DETAILS, types.UPDATE_COMPANY_DETAILS];
  newState.company = companyLoadingTypes.some(type => newState[type]);

  const uptimeLoadingTypes = [types.GET_SYSTEM_UPTIME];
  newState.uptime = uptimeLoadingTypes.some(type => newState[type]);

  const usersLoadingTypes = [types.GET_USERS, types.DELETE_USER];
  newState.users = usersLoadingTypes.some(type => newState[type]);

  const userLoadingTypes = [types.GET_USER, types.DELETE_USER, types.GRANT_ROLE];
  newState.user = userLoadingTypes.some(type => newState[type]);

  const userGroupLoadingTypes = [types.GET_USER_GROUP, types.UPDATE_USER_GROUP, types.DELETE_USER_GROUP, types.GET_USER_GROUP_USERS, types.ADD_USER_GROUP_USERS, types.DELETE_USER_GROUP_USERS];
  newState.userGroup = userGroupLoadingTypes.some(type => newState[type]);

  const userGroupsLoadingTypes = [types.GET_USER_GROUPS, types.CREATE_USER_GROUP, types.DELETE_USER_GROUP];
  newState.userGroups = userGroupsLoadingTypes.some(type => newState[type]);

  const graphResourcesLoadingTypes = [types.GET_GRAPH_RESOURCES, types.GET_GRAPH_RESOURCE, types.CREATE_GRAPH_RESOURCE, types.UPDATE_GRAPH_RESOURCE, types.DELETE_GRAPH_RESOURCE];
  newState.graphResources = graphResourcesLoadingTypes.some(type => newState[type]);

  const serviceAccountsLoadingTypes = [types.GET_SERVICE_ACCOUNTS, types.GET_SERVICE_ACCOUNT, types.CREATE_SERVICE_ACCOUNT, types.UPDATE_SERVICE_ACCOUNT, types.DELETE_SERVICE_ACCOUNT];
  newState.serviceAccounts = serviceAccountsLoadingTypes.some(type => newState[type]);

  const adminsLoadingTypes = [types.GET_ADMINS];
  newState.admins = adminsLoadingTypes.some(type => newState[type]);

  const supportUsersLoadingTypes = [types.GET_SUPPORT_USERS];
  newState.supportUsers = supportUsersLoadingTypes.some(type => newState[type]);

  const inviteLoadingTypes = [types.GET_INVITE, types.DELETE_INVITE];
  newState.invite = inviteLoadingTypes.some(type => newState[type]);

  const invitesLoadingTypes = [types.GET_INVITES, types.INVITE_USERS, types.DELETE_INVITE];
  newState.invites = invitesLoadingTypes.some(type => newState[type]);

  const reportsLoadingTypes = [types.GET_LOCATION_HIERARCHY, types.GET_WORKPLACE_CATEGORIES];
  newState.reports = reportsLoadingTypes.some(type => newState[type]);

  const widgetsLoadingTypes = [types.GET_WIDGET_PROFILE, types.GET_LOCATION_HIERARCHY, types.GET_WORKPLACE_CATEGORIES, types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS, types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS];
  newState.widgets = widgetsLoadingTypes.some(type => newState[type]);

  const alarmsLoadingTypes = [types.GET_WARNINGS_SUMMARY, types.GET_WARNINGS_SUMMARY_PART, types.GET_WARNINGS];
  newState.alarms = alarmsLoadingTypes.some(type => newState[type]);

  return newState;
}