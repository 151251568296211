import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import InputModal from "../../components/InputModal";
import TopRowOptions from "../../components/TopRowOptions";
import * as userGroupActions from "../../actions/userGroups";
import * as selectedActions from "../../actions/selected";

class GroupList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      newGroupName: "",
      showNewGroupModal: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onNewGroupNameChanged = this.onNewGroupNameChanged.bind(this);
    this.onCreateGroup = this.onCreateGroup.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/org/groups/${rowInfo.original._id}/users`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original._id === this.props.groupId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedUserGroups[row.original._id] !== undefined && this.props.selectedUserGroups[row.original._id]) {
      this.props.deselectUserGroup(row.original._id);
    }
    else {
      this.props.selectUserGroup(row.original);
    }
  }

  onNewGroupNameChanged(event) {
    const newGroupName = event.target.value;
    this.setState({ newGroupName });
  }

  onCreateGroup() {
    const name = this.state.newGroupName.trim();

    this.props.createUserGroup({ name });
    this.setState({ showNewGroupModal: false });
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getUserGroups(queryParams);
  }

  render() {
    const { isLoading } = this.props;

    const canCreateUserGroup = this.props.auth.hasAdminRole;
    const hasSelection = !isEmpty(this.props.selectedUserGroups);

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("userGroup");

    const tableElement = (
      <Table
        data={this.props.data.usergroups}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc", offset: 0 }, this.fetchData);
        }}
        onOffsetChanged={(offset) => { this.setState({ offset }, this.fetchData) }}
        noDataText={isLoading ? "" : "No groups found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedUserGroups[row.original._id] !== undefined && this.props.selectedUserGroups[row.original._id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name"
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <div>
        <TopRowOptions
          searchbox={(
            <SearchBox
              value={this.state.searchText}
              onSearchChanged={(value) => { this.setState({ searchText: value }) }}
              onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
              onSearchClick={this.onSearchClick}
              inListView
            />
          )}
          buttons={[
            <SmallButton key="b1" text="New group" onClick={() => this.setState({ showNewGroupModal: true })} disabled={!canCreateUserGroup} singleLine noMargin />
          ]}
        />
        
        { tableElement }
        <div style={{ paddingTop: "40px" }} />

        <InputModal
          show={this.state.showNewGroupModal}
          onHide={() => this.setState({ showNewGroupModal: false })}
          title="New group"
          // text=""
          inputLabel="Name"
          inputValid={this.state.newGroupName}
          inputValue={this.state.newGroupName}
          onInputChange={this.onNewGroupNameChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.onCreateGroup
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNewGroupModal: false })
          }}
        />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.userGroups.data,
    isLoading: state.loading.userGroups,
    selectedUserGroups: state.selected.userGroups,
    selectedCompany: state.auth.selectedCompany,
    selectedTypes: state.selected.allTypes,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getUserGroups: userGroupActions.getUserGroups,
    createUserGroup: userGroupActions.createUserGroup,
    selectUserGroup: selectedActions.selectUserGroup,
    deselectUserGroup: selectedActions.deselectUserGroup,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
