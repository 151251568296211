import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AutoResizingTextArea from "../../../components/AutoResizingTextArea";
import styled from "styled-components";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

const LogRow = ({
  id,
  text,
  user,
  createdAt,
  saveDisabled,
  isEditing,
  canEdit,
  onEdit,
  onSave,
  canDelete,
  onDelete,
  onCancel,
  onChange,
  onUserClick
}) => {

  const logDate = createdAt ? moment(createdAt).format("DD/MM/YY - HH:mm") : "";

  if (!isEditing) {
    const textElement = text && (<Text>{text}</Text>);
    const userElement = user && (<UserLink $hasOnClick={!!onUserClick} onClick={onUserClick}>– {user}</UserLink>);
    return (
      <LogContainer>
        <LogRowContainer>
          {createdAt && <LogDate>{logDate}</LogDate>}
          <div>{textElement}{userElement}</div>
          { canEdit && (
            <IconButtonContainer>
              { canEdit && <EditButton icon={faPencilAlt} onClick={() => onEdit(id, text)} /> }
              { canDelete && <EditButton icon={faTrashAlt} onClick={() => onDelete(id)} /> }
            </IconButtonContainer>
          )}
        </LogRowContainer>
      </LogContainer>
    );
  }

  return (
    <LogContainer>
      <LogRowContainer>
        <LogDate>{logDate}</LogDate>
        <AutoResizingTextArea
          autoFocus
          value={text}
          onChange={onChange}
          placeholder="Write your comment here..."
          style={{
            flex: 1,
            backgroundColor: "rgb(250, 250, 250)",
            borderRadius: "5px",
            border: "solid 1px #D8D8D8",
            padding: "12px",
            color: "#0c0f26",
            boxSizing: "border-box",
          }}
        />
        <ButtonContainer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button $disabled={saveDisabled} onClick={onSave}>Save</Button>
        </ButtonContainer>
      </LogRowContainer>
    </LogContainer>
  );
};

export default LogRow;

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding: 12px 12px;

  &:last-child {
    border-bottom: none;
  }
`;

const LogRowContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 10px;
`;

const LogDate = styled.div`
  min-width: 110px;
  max-width: 110px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
`;

const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-left: auto;
`;

const Button = styled.div`
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 8px 12px;
  color: #333;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ $disabled }) => $disabled && `
    color: #aaa;
    background-color: #f9f9f9;
    cursor: not-allowed;
  `}
`;

const EditButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #aaa;
  transition: color 0.2s;

  &:hover {
    color: #555
  }
`;

const Text = styled.span`
  margin-right: 5px;
`;

const UserLink = styled.span`
  color: #777777;
  font-size: 13px;
  align-self: center;

  ${({ $hasOnClick }) => $hasOnClick && `
    cursor: pointer;
    color: #1c4d82;
  `}
`;