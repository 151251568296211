import React from "react";
import moment from "moment";
import get from "lodash/get";
import { RotatingLines } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faClock, faCircleCheck, faEllipsis, faGear, faList, faMapPin, faQuestionCircle, faThumbTack, faThumbTackSlash } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Popup, PopupAnchor } from "ui";
import ListCard from "../../../components/ListCard";
import { WidgetGraphType } from "./types";
import ValueListGraph from "./valueListGraph";
import HorizontalBarGraph from "./horizontalBarGraph";
import VerticalBarGraph from "./verticalBarGraph";
import KPIBox from "./kpiBox";
import TextValue from "./textValue";
import Sparkline from "./sparklineGraph";

interface WidgetProps {
  widget: any;
  sourceData: any;
  loading: any;
  building: any;
  page: any;
  onSingleItemClick: (event: any, item: any) => void;
  showLocations?: (widget: any) => void;
  showDataQuality?: (widget: any) => void;
  addWidgetToOverview?: (widget: any) => void;
  removeWidgetFromOverview?: (widget: any) => void;
}

interface WidgetState {
  isMenuVisible: boolean;
}

export class Widget extends React.Component<WidgetProps, WidgetState> {

  private optionButtonRef: React.RefObject<HTMLDivElement>;
  private menuRef: React.RefObject<HTMLDivElement>;

  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      isMenuVisible: false
    };
    this.optionButtonRef = React.createRef();
    this.menuRef = React.createRef();
    this.hideMenu = this.hideMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.handleScrollInMenu = this.handleScrollInMenu.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener("mousedown", this.hideMenu);
    window.addEventListener("scroll", this.handleScrollInMenu, true); // Use capture mode
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.hideMenu);
    window.removeEventListener("scroll", this.handleScrollInMenu, true);
  }

  componentDidUpdate(prevProps: WidgetProps, prevState: WidgetState): void {
    if (this.state.isMenuVisible !== prevState.isMenuVisible) {
      if (this.state.isMenuVisible) {
        // Add event listeners
        document.addEventListener("mousedown", this.hideMenu);
        window.addEventListener("scroll", this.handleScrollInMenu, true);
      } else {
        // Remove event listeners
        document.removeEventListener("mousedown", this.hideMenu);
        window.removeEventListener("scroll", this.handleScrollInMenu, true);
      }
    }
  }

  showMenu() {
    this.setState((prevState) => ({ isMenuVisible: !prevState.isMenuVisible }));
  }

  hideMenu(e: MouseEvent) {
    if (this.menuRef.current && !this.menuRef.current.contains(e.target as Node)) {
      this.setState({ isMenuVisible: false });
    }
  }

  handleScrollInMenu() {
    this.setState({ isMenuVisible: false }); // Hide the popup on scroll
  }

  render() {
    const { widget, sourceData, loading, building, page, onSingleItemClick } = this.props;

    let footerDescription = building?.name;
    if (page.id === "overview") {
      footerDescription = `${widget.wpaCategoryName} in ${building?.name}`;
    }
    else if (page) {
      footerDescription = `${page.name} in ${building?.name}`;
    }

    let widgetContent = null;
    let widgetLocationCount = -1;
    let dataPointCapacity;
    const widgetData: any[] = [];

    let chartDescription = "";
    const lastMonth = moment.utc().subtract(1, "month").startOf("month");
    const lastLastMonth = moment.utc().subtract(2, "month").startOf("month");

    switch (widget.type) {
      case WidgetGraphType.ValueList:

        widget.dataLayout.forEach((dataPointLayout: any) => {
          const dataPoint = get(sourceData, 0, {});
          widgetData.push({
            name: get(dataPoint, dataPointLayout.nameProperty, dataPointLayout.name),
            value: get(dataPoint, dataPointLayout.property, dataPointLayout.value),
            description: dataPointLayout.description,
            color: dataPointLayout.color,
            // locationId: data.locationId,
          });
        });

        // Get the number of locations
        widgetLocationCount = widgetData.length;

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <ValueListGraph
            data={widgetData}
            valueType={widget.valueType}
            max={widget.max}
            showMaxLine={widget.showMaxLine}
            onClick={onSingleItemClick}
            hoverText="Go to location"
          />
        );

        break;
      case WidgetGraphType.DynamicValueList:

        const layout = widget.dataLayout;
        const dataList = sourceData?.results?.filter((data: any) => data[widget.dateKey] === layout.date) ?? [];
        dataList.forEach((data: any) => {
          widgetData.push({
            name: get(data, layout.nameProperty, layout.name),
            value: get(data, layout.property, layout.value),
            description: layout.description,
            color: layout.color,
            locationId: data.locationId,
          });
        });

        // Get the number of locations
        widgetLocationCount = sourceData.count;

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <ValueListGraph
            data={widgetData}
            valueType={widget.valueType}
            max={widget.max}
            showMaxLine={widget.showMaxLine}
            onClick={onSingleItemClick}
            hoverText="Go to location"
          />
        );

        break;
      case WidgetGraphType.HorizontalBar:

        let hasDescription = false;
        widget.dataLayout.forEach((dataPointLayout: any) => {
          const dataPoint = get(sourceData, 0, {});
          widgetData.push({
            value: get(dataPoint, dataPointLayout.property, 0),
            description: dataPointLayout.description,
            color: dataPointLayout.color
          });

          if (dataPointLayout.description) {
            hasDescription = true;
          }

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }

          if (dataPoint.capacity !== undefined) {
            dataPointCapacity = dataPoint.capacity;
          }
        });

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <HorizontalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={dataPointCapacity ?? widget.max}
            showMaxLine={widget.showMaxLine}
            showDescription={hasDescription}
          />
        );

        break;
      case WidgetGraphType.VerticalBar:

        widget.dataLayout.map((dataPointLayout: any) => {
          // Get the data point with the same date/weekday/hour etc. as the layout
          const dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          // console.log("dataPoint", dataPoint);
          // console.log("dataPointLayout", dataPointLayout);
          widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }

          if (dataPoint.capacity !== undefined) {
            dataPointCapacity = dataPoint.capacity;
          }

        });

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <VerticalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={dataPointCapacity ?? widget.max}
            description={widget.dataDescription}
            showMaxLine={widget.showMaxLine}
          />
        );

        break;
      case WidgetGraphType.KPIBox:

        widget.dataLayout.map((dataPointLayout: any) => {
          // Get the data point with the same date/weekday/hour etc. as the layout
          const dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          widgetData.push({
            value: get(dataPoint, dataPointLayout.property, 0),
            change: get(dataPoint, dataPointLayout.change, undefined),
            percentageChange: get(dataPoint, dataPointLayout.percentageChange, undefined),
            total: get(dataPoint, dataPointLayout.total, undefined),
            description: dataPointLayout.description,
            color: dataPointLayout.color
          });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        chartDescription = widget.description;

        // widgetContent = (
        //   <KPIBox
        //     data={widgetData}
        //     valueType={widget.valueType}
        //     max={widget.max}
        //     description={widget.dataDescription}
        //   />
        // );

        break;
      case WidgetGraphType.ChangeBarGraph:

        widget.dataLayout.map((dataPointLayout: any, index: number) => {
          let dataPoint: any = {};
          if (widget.dateKey) {
            dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          }
          else {
            dataPoint = sourceData[index] ?? {};
          }
          widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <VerticalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={widget.max}
            description={widget.dataDescription}
            showChangeBar={true}
          />
        );

        break;
      case WidgetGraphType.ChangeBarGraph2:

        widget.dataLayout.map((dataPointLayout: any, index: number) => {
          let dataPoint: any = {};
          if (widget.dateKey) {
            dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          }
          else {
            dataPoint = sourceData[index] ?? {};
          }
          widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        // chartDescription = `The duration no resources were not in use in ${locationName}.`,

        // Build the chart footer
        if (widgetData.length === 2) {
          const value1 = Math.round(10 * widgetData[0].value / 60) / 10;
          const value2 = Math.round(10 * widgetData[1].value / 60) / 10;
          const change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
          if (value1 === value2) {
            chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
          }
          else if (value1 > value2) {
            chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
          }
          else {
            chartDescription = `All ${widget.resourceName} where empty for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
          }
        }

        widgetContent = (
          <VerticalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={widget.max}
            description={widget.dataDescription}
            showChangeBar={false}
          />
        );

        break;
      case WidgetGraphType.MonthlyDurationEmpty:

        widget.dataLayout.map((dataPointLayout: any, index: number) => {
          let dataPoint: any = {};
          if (widget.dateKey) {
            dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          }
          else {
            dataPoint = sourceData[index] ?? {};
          }
          widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        if (widgetData.length === 2) {
          const value1 = Math.round(10 * widgetData[0].value / 60) / 10;
          const value2 = Math.round(10 * widgetData[1].value / 60) / 10;
          const change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
          if (value1 === value2) {
            chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
          }
          else if (value1 > value2) {
            chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
          }
          else {
            chartDescription = `All ${widget.resourceName} where empty at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
          }

          widgetContent = (
            <TextValue
              targetValue={widgetData[0].value}
              valueType={widget.valueType}
              duration={1000}
            />
          );
        }

        break;
      case WidgetGraphType.MonthlyDurationFull:
      case WidgetGraphType.MonthlyDurationAtCapacity:

        widget.dataLayout.map((dataPointLayout: any, index: number) => {
          let dataPoint: any = {};
          if (widget.dateKey) {
            dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          }
          else {
            dataPoint = sourceData[index] ?? {};
          }
          widgetData.push({ value: get(dataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (dataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        if (widgetData.length === 2) {
          const value1 = Math.round(10 * widgetData[0].value / 60) / 10;
          const value2 = Math.round(10 * widgetData[1].value / 60) / 10;
          const change = Math.abs(Math.round(10 * (value1 - value2)) / 10);
          if (value1 === value2) {
            // No rooms where available
            chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is the same amount as in ${lastLastMonth.format("MMMM")}.`;
          }
          else if (value1 > value2) {
            chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours more than in ${lastLastMonth.format("MMMM")}.`;
          }
          else {
            chartDescription = `All ${widget.resourceName} where occupied at the same time for a total of **${value1}** hours in ${building.name} in ${lastMonth.format("MMMM")}. That is **${change}** hours less than in ${lastLastMonth.format("MMMM")}.`;
          }

          widgetContent = (
            <TextValue
              targetValue={widgetData[0].value}
              valueType={widget.valueType}
              duration={1000}
            />
          );
        }

        break;
      case WidgetGraphType.Sparkline:

        widget.dataLayout.map((dataPointLayout: any, index: number) => {
          
          // Get data point with the same date as the layout definition
          const datedDataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          
          widgetData.push({ value: get(datedDataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });

          if (datedDataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, datedDataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        chartDescription = widget.description;

        widgetContent = (
          <Sparkline
            data={widgetData}
            // valueType={widget.valueType}
            // max={widget.max}
            color={widget.color}
            description={widget.dataDescription}
          />
        );

        break;
      case WidgetGraphType.UtilizationSparklineMonth:

        let max = -100000;
        let min = 100000;
        widget.dataLayout.map((dataPointLayout: any, index: number) => {

          // Get data point with the same date as the layout definition
          const datedDataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};

          widgetData.push({ value: get(datedDataPoint, dataPointLayout.property, 0), description: dataPointLayout.description, color: dataPointLayout.color });
          max = Math.max(max, get(datedDataPoint, dataPointLayout.property, 0));
          min = Math.min(min, get(datedDataPoint, dataPointLayout.property, 0));

          if (datedDataPoint.locationIds !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, datedDataPoint.locationIds.length);
          }
        });

        // Build the chart footer
        if (max === min) {
          chartDescription = `There where no activity in ${building.name} in ${lastMonth.format("MMMM")}.`;
        }
        else {
          const flooredMaxPercentage = Math.floor(max * 100);
          chartDescription = `The highest utilization reached in ${building.name} in ${lastMonth.format("MMMM")} was **${flooredMaxPercentage}%**.`;
        }

        widgetContent = (
          <Sparkline
            data={widgetData}
            // valueType={widget.valueType}
            // max={widget.max}
            color={widget.color}
            description={widget.dataDescription}
          />
        );

        break;
      case WidgetGraphType.HorizontalCompareMonths:

        let hasDescription1 = false;
        let sum1 = 0;
        let sumChange1 = 0;
        let total1 = 0;
        widget.dataLayout.forEach((dataPointLayout: any) => {
          // Get the data point with the same date/weekday/hour etc. as the layout
          const dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          widgetData.push({
            value: get(dataPoint, dataPointLayout.property, 0),
            description: dataPointLayout.description,
            color: dataPointLayout.color
          });

          sum1 += get(dataPoint, dataPointLayout.property, 0);
          sumChange1 += get(dataPoint, dataPointLayout.change, 0);
          total1 = get(dataPoint, "total", 0);

          if (dataPointLayout.description) {
            hasDescription1 = true;
          }

          if (dataPoint.total !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.total);
          }
        });

        // Build the chart footer
        if (sum1 === 0) {
          if (sumChange1 === 0) {
            chartDescription = `There where no ${widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}.`;
          }
          else {
            chartDescription = `There where no ${widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(sumChange1)}** less than in ${lastLastMonth.format("MMMM")}.`;
          }
        }
        else {
          if (sumChange1 === 0) {
            chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is the same as in ${lastLastMonth.format("MMMM")}.`;
          }
          else if (sumChange1 < 0) {
            chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(sumChange1)}** less than in ${lastLastMonth.format("MMMM")}.`;
          }
          else {
            chartDescription = `There where **${sum1}** ${sum1 === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${sumChange1}** more than in ${lastLastMonth.format("MMMM")}.`;
          }
        }

        widgetContent = (
          <HorizontalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={total1 ?? widget.max}
            showDescription={hasDescription1}
          />
        );

        break;
      case WidgetGraphType.MonthlyUtilizationDistribtion:

        widget.dataLayout.map((dataPointLayout: any) => {
          // Get the data point with the same date/weekday/hour etc. as the layout
          const dataPoint = sourceData.find((data: any) => data[widget.dateKey] === dataPointLayout[widget.dateKey]) ?? {};
          widgetData.push({
            value: get(dataPoint, dataPointLayout.property, 0),
            change: get(dataPoint, dataPointLayout.change, 0),
            description: dataPointLayout.description,
            color: dataPointLayout.color
          });

          if (dataPoint.total !== undefined) {
            widgetLocationCount = Math.max(widgetLocationCount, dataPoint.total);
          }
        });

        // Build the chart footer
        if (widgetData.length === 5) {
          const littleUsed = widgetData[0].value + widgetData[1].value;
          const littleUsedChange = widgetData[0].change + widgetData[1].change;

          if (littleUsed === 0) {
            if (littleUsedChange === 0) {
              chartDescription = `There where no ${widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}.`;
            }
            else {
              chartDescription = `There where no ${widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(littleUsedChange)}** less than in ${lastLastMonth.format("MMMM")}.`;
            }
          }
          else {
            if (littleUsedChange === 0) {
              chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is the same as in ${lastLastMonth.format("MMMM")}.`;
            }
            else if (littleUsedChange < 0) {
              chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${Math.abs(littleUsedChange)}** less than in ${lastLastMonth.format("MMMM")}.`;
            }
            else {
              chartDescription = `There where **${littleUsed}** ${littleUsed === 1 ? widget.singleResourceName : widget.resourceName} with low usage in ${building.name} in ${lastMonth.format("MMMM")}. That is **${littleUsedChange}** more than in ${lastLastMonth.format("MMMM")}.`;
            }
          }
        }

        widgetContent = (
          <VerticalBarGraph
            data={widgetData}
            valueType={widget.valueType}
            max={widget.max}
            description={widget.dataDescription}
            showMaxLine={widget.showMaxLine}
          />
        );

        break;
      default:
        break;
    }

    // Replace $locationName in chartDescription with the location name
    chartDescription = chartDescription?.replace(/\$locationName/g, building.name) ?? "";

    let footerCount = "";
    let footer = null;

    // Only show the location count if it is greater than or equal to 0, 
    // and the widget has a wpa category id (not a people count widget)
    if (widgetLocationCount >= 0 && widget.wpaCategoryId !== undefined) {
      if (widgetLocationCount === 1) {
        footerCount = `${widgetLocationCount} location`;
      }
      else {
        footerCount = `${widgetLocationCount} locations`;
      }

      footer = (
        <>
          <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerDescription}</div>
          <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerCount}</div>
        </>
      );
    }
    else {
      footer = (
        <>
          <div style={{ display: "block", fontWeight: 400, color: "#666", fontSize: "13px" }}>{footerDescription}</div>
        </>
      );
    }

    // Get button rect
    let optionButtonRect = null;
    if (this.optionButtonRef.current) {
      optionButtonRect = this.optionButtonRef.current.getBoundingClientRect();
    }

    // Get widget menu
    let menu = null;
    if (loading) {
      menu = (
        <Menu>
          <MenuItem>
            <RotatingLines strokeColor="#777" width="20px" />
          </MenuItem>
        </Menu>
      );
    }
    else {

      const menuItems = [];

      if (this.props.showLocations) {
        menuItems.push(
          <PopupItem key="locations" onClick={() => this.props.showLocations && this.props.showLocations(widget)}>
            <PopupItemIcon>
              <FontAwesomeIcon icon={faList} color="#444" />
            </PopupItemIcon>
            <PopupItemText>{"Locations"}</PopupItemText>
          </PopupItem>
        );
      }

      if (this.props.showDataQuality) {
        menuItems.push(
          <PopupItem key="downtime" onClick={() => this.props.showDataQuality && this.props.showDataQuality(widget)}>
            <PopupItemIcon>
              <FontAwesomeIcon icon={faClock} color="#444" />
            </PopupItemIcon>
            <PopupItemText>{"Sensor uptime"}</PopupItemText>
          </PopupItem>
        );
      }

      if (this.props.addWidgetToOverview) {
        menuItems.push(
          <PopupItem key="pin" onClick={() => this.props.addWidgetToOverview && this.props.addWidgetToOverview(widget)}>
            <PopupItemIcon>
              <FontAwesomeIcon icon={faThumbTack} color="#444" />
            </PopupItemIcon>
            <PopupItemText>{"Pin to overview"}</PopupItemText>
          </PopupItem>
        );
      }

      if (this.props.removeWidgetFromOverview) {
        menuItems.push(
          <PopupItem key="unpin" onClick={() => this.props.removeWidgetFromOverview && this.props.removeWidgetFromOverview(widget)}>
            <PopupItemIcon>
              <FontAwesomeIcon icon={faThumbTackSlash} color="#444" />
            </PopupItemIcon>
            <PopupItemText>{"Unpin from overview"}</PopupItemText>
          </PopupItem>
        );
      }

      menu = (
        <Menu>
          <MenuItem>
            <MenuItemImage ref={this.optionButtonRef} role="button" onClick={this.showMenu} onKeyDown={this.showMenu}>
              <FontAwesomeIcon icon={faEllipsis} size="sm" color="#333" />
              <Popup ref={this.menuRef} isVisible={this.state.isMenuVisible} sourceRect={optionButtonRect} popupAnchor={PopupAnchor.TOP_RIGHT}>
                <PopupContent>
                  {menuItems}
                </PopupContent>
              </Popup>
            </MenuItemImage>
          </MenuItem>
        </Menu>
      );
    }

    return (
      <div style={{ minWidth: "400px", maxWidth: "430px", breakInside: "avoid" }}>
        <ListCard footer={footer} useBottomPadding>
          <div style={{ display: "block", margin: "20px", boxSizing: "border-box" }}>
            <div style={{ marginBottom: "25px" }}>
              <h3 style={{ color: "#222", marginBottom: "2px" }}>{widget.title}</h3>
              {widget.subtitle && <p style={{ color: "#666" }}>{widget.subtitle}</p>}
            </div>
            {menu}
            <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
              {widgetContent}
              {parseDescription(chartDescription)}
            </div>
          </div>
        </ListCard>
      </div>
    );

  }
}

export default Widget;

const Menu = styled.div`
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  // background-color: #dff;
  width: 100px;
  height: 25px;
`;

const MenuItem = styled.div`
  display: block;
  float: right;
  cursor: pointer;
  // background-color: #fdf;
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

const MenuItemImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const PopupContent = styled.div`
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);

  overflow: hidden;
  user-select: none;
`;

const PopupItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const PopupItemIcon = styled.div`
  display: inline-flex;
  width: 35px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const PopupItemText = styled.div`
  display: inline-flex;
  height: 40px;
  line-height: 39px;
  margin-right: 10px;
  color: #333;
`;

// Return a list of span elements with regular, bold, and italic text
const parseDescription = (description: string) => {

  if (!description) {
    return null;
  }

  const elements = [];
  let text = description;

  // Regular expression to match bold (**text**) and italic (*text*) patterns
  let match = text.match(/(\*\*.*?\*\*)|(\*.*?\*)/);

  while (match) {
    const index = match.index ?? 0;

    // Get the matched text
    const matchedText = match[0];

    // Add any text before the match as a regular span
    if (index > 0) {
      elements.push(
        <span key={"text" + elements.length} style={{ color: "#555" }}>
          {text.substring(0, index)}
        </span>
      );
    }

    // Determine if the matched text is bold (**text**) or italic (*text*)
    if (matchedText.startsWith("**")) {
      // Bold text (remove ** and apply bold styling)
      const boldText = matchedText.replace(/\*\*/g, "");
      elements.push(
        <span
          key={"bold" + elements.length}
          style={{ fontWeight: 600, color: "#111", fontSize: "18px" }}
        >
          {boldText}
        </span>
      );
    }
    else if (matchedText.startsWith("*")) {
      // Italic text (remove * and apply italic styling)
      const italicText = matchedText.replace(/\*/g, "");
      elements.push(
        <span
          key={"italic" + elements.length}
          style={{ fontStyle: "italic", color: "#111", fontSize: "18px" }}
        >
          {italicText}
        </span>
      );
    }

    // Update the text by removing the processed part
    text = text.substring(index + matchedText.length);

    // Look for the next match
    match = text.match(/(\*\*.*?\*\*)|(\*.*?\*)/);
  }

  // Add any remaining text as a regular span
  if (text) {
    elements.push(
      <span key={"endtext"} style={{ color: "#555" }}>
        {text}
      </span>
    );
  }

  return (
    <p style={{ marginTop: "20px", marginBottom: "5px", lineHeight: "22px" }}>
      {elements}
    </p>
  );
}