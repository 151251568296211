import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash, faRouter, faGlobe, faMobileButton, faUserPlus, faPersonShelter, faArchive, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";

import TopRowOptions from "../../../components/TopRowOptions";
import SearchBox from "../../../components/SearchBox";
import Caret from "./caret3_grey.png";
import * as warningsActions from "../../../actions/alarms";
import * as types from "../../../ApiTypes";

// Category colors
export const categoryColors = {
  "integration": "#e95959",
  "gateway": "#b36939",
  "people": "#c959a9",
  "presence": "#6969d9",
  "other": "#49b999",
};

export const stateColors = {
  "active": "#C94545",
  "silenced": "#E6A70A",
  "resolved": "#5B9B53",
};

class WarningsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      search: "",
      offset: 0,
      limit: 20,
      category: "all",
      page: "active"
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getWarningsList = this.getWarningsList.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ search: this.state.searchText }, this.fetchData);
  }

  onRowClick(warningId) {
    this.props.history.push(`/support/overview/${this.props.companyId}/alarms/${warningId}`);
  }

  onFilterClick(category) {
    this.setState({ category }, this.fetchData);
  }

  onPageChange(page) {
    this.setState({ page, offset: 0 }, this.fetchData);
  }

  fetchData() {
    const categories = [];
    if (this.state.category === "all") {
      categories.push("integration", "gateway", "people", "presence", "other");
    }
    else {
      categories.push(this.state.category);
    }

    const params = {
      search: this.state.search,
      offset: this.state.offset,
      limit: this.state.limit,
      state: this.state.page,
      categories: JSON.stringify(categories)
    };
    this.props.getWarnings(this.props.companyId, params);
  }

  getWarningsList() {

    const warningsList = this.props.warnings.map(warning => {

      // Build location breadcrumb
      let locationBreadcrumb = "";
      if (warning.breadcrumbs) {
        locationBreadcrumb = warning.breadcrumbs.map(location => location.name).join(", ");
      }

      // Find the warning type icon
      let typeIcon;
      switch (warning.category) {
        case "gateway": typeIcon = faRouter; break;
        case "integration": typeIcon = faGlobe; break;
        case "people": typeIcon = faUserPlus; break;
        case "presence": typeIcon = faPersonShelter; break;
        case "other": typeIcon = faMobileButton; break;
        default: break;
      }

      // Find the warning state color
      let stateColor;
      if (warning.state === "silenced") {
        stateColor = stateColors.silenced;
      }
      else if (warning.state === "resolved") {
        stateColor = stateColors.resolved;
      }
      else {
        stateColor = stateColors.active;
      }

      // Build warning date
      let warningDate;
      if (warning.triggeredUntil) {
        if (moment(warning.triggeredUntil).isSame(moment(), "year")) {
          warningDate = moment(warning.triggeredUntil).format("D. MMMM, HH:mm");
        }
        else {
          warningDate = moment(warning.triggeredUntil).format("D. MMMM YYYY");
        }
      }
      else if (warning.triggeredAt) {
        if (moment(warning.triggeredAt).isSame(moment(), "year")) {
          warningDate = moment(warning.triggeredAt).format("D. MMMM, HH:mm");
        }
        else {
          warningDate = moment(warning.triggeredAt).format("D. MMMM YYYY");
        }
      }
      else {
        warningDate = "Unknown";
      }

      const isSelected = this.props.match.params.warningId === warning.id;
      return (
        <WarningCard key={warning.id} onClick={() => this.onRowClick(warning.id)} $isSelected={isSelected}>
          <WarningIcons $color={stateColor}>
            {typeIcon && <CategoryIcon icon={typeIcon} title={warning.category} />}
          </WarningIcons>
          <WarningColumn>
            <WarningDate>{warningDate}</WarningDate>
            <WarningRow>
              <WarningTitle>{warning.name}</WarningTitle>
            </WarningRow>
            {locationBreadcrumb && <WarningLocation>{locationBreadcrumb}</WarningLocation>}
          </WarningColumn>
          <WarningColumnFixed $width={45}>
            <ArrowIcon src={Caret} />
          </WarningColumnFixed>
        </WarningCard>
      );
    });

    // Load more button
    if (this.props.isLoading) {
      warningsList.push(
        <LoadMoreRow key={"loading"}>
          Loading...
        </LoadMoreRow>
      );
    }
    else if (this.props.warnings.length === 0) {
      warningsList.push(
        <LoadMoreRow key={"noData"}>
          No warnings found
        </LoadMoreRow>
      );
    }
    else if (this.props.hasMoreWarnings) {
      warningsList.push(
        <LoadMoreRow key={"loadMore"} $clickable onClick={() => this.setState({ offset: this.state.offset + this.state.limit }, this.fetchData)}>
          Load more
        </LoadMoreRow>
      );
    }

    return warningsList;
  }


  render() {
    // console.log("WarningList.render()", this.props);

    if (isEmpty(this.props.companyId)) {
      return null;
    }
    
    return (
      <Page>
        <TopRowOptions
          searchbox={(
            <SearchBox
              value={this.state.searchText}
              onSearchChanged={(value) => { this.setState({ searchText: value }) }}
              onSearchClick={this.onSearchClick}
              onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
              inDetailsView
            />
          )}
        />
        <FilterRow>
          <FilterRowSection>
            <FilterSegmentedControl>
              <FilterSegmentedControlButton onClick={() => this.onPageChange("active")} title={"Active"}>
                <FilterIcon icon={faTriangleExclamation} $selected={this.state.page === "active"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onPageChange("silenced")} title={"Silenced"}>
                <FilterIcon icon={faBellSlash} $selected={this.state.page === "silenced"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onPageChange("resolved")} title={"Resolved"}>
                <FilterIcon icon={faArchive} $selected={this.state.page === "resolved"} />
              </FilterSegmentedControlButton>
            </FilterSegmentedControl>
          </FilterRowSection>
          <FilterRowSection>
            <FilterSegmentedControl>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("all")} title={"All"}>
                <FilterText $selected={this.state.category === "all"}>All</FilterText>
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("integration")} title={"Integration"}>
                <FilterIcon icon={faGlobe} $selected={this.state.category === "integration"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("gateway")} title={"Gateway"}>
                <FilterIcon icon={faRouter} $selected={this.state.category === "gateway"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("people")} title={"People"}>
                <FilterIcon icon={faUserPlus} $selected={this.state.category === "people"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("presence")} title={"Presence"}>
                <FilterIcon icon={faPersonShelter} $selected={this.state.category === "presence"} />
              </FilterSegmentedControlButton>
              <FilterSegmentedControlButton onClick={() => this.onFilterClick("other")} title={"Other"}>
                <FilterIcon icon={faMobileButton} $selected={this.state.category === "other"} />
              </FilterSegmentedControlButton>
            </FilterSegmentedControl>
          </FilterRowSection>
        </FilterRow>
        <WarningContainer>{this.getWarningsList()}</WarningContainer>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    warnings: state.alarms.warnings.results,
    hasMoreWarnings: state.alarms.warnings.hasMore,
    selectedCompany: state.alarms.selectedCompany,
    isLoading: state.loading[types.GET_WARNINGS],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getWarnings: warningsActions.getWarnings
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningsList);

const Page = styled.div`
  display: flex;
  flex-direction: column;
}`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const WarningCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  gap: 10px;
  transition: background-color 0.2s, color 0.2s;

  // First warning
  &:first-child {
    margin-top: 20px;
  }

  // Last warning
  &:last-child {
    margin-bottom: 20px;
  }

  // Selected warning
  ${props => props.$isSelected && `
    background-color: #F3F3F3;
  `}

  &:hover {
    background-color: #F3F3F3;
  }
`;

const WarningRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WarningColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0px;
`;

const WarningTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 2px;
`;

const WarningDate = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: rgb(145, 145, 145)
`;

const WarningLocation = styled.div`
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 5px;
  color: rgb(78, 78, 78)
`;

const WarningColumnFixed = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  height: 100%;
  gap: 5px;
  flex-shrink: 1;

  ${props => props.$width && `
    width: ${props.$width}px;
  `}
`;

const ArrowIcon = styled.img`
  width: 25px;
  height: 25px;
  transform: rotate(270deg);
  user-select: none;
`;

const WarningIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20px;
  padding: 14px 8px;

  background-color: rgb(235, 235, 235);
  background-color: ${props => props.$color};
`;

const CategoryIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #333;
  color: #ffffff;
`;

const LoadMoreRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  font-weight: 600;
  font-size: 16px;
  color: #333;
  transition: background-color 0.2s, color 0.2s;
  user-select: none;

  ${props => props.$clickable && `
    cursor: pointer;

    &:hover {
      background-color: #F3F3F3;
    }
  `}
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
`;

const FilterRowSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const FilterIcon = styled(FontAwesomeIcon)`
  color: ${props => props.$selected ? "#333" : "#ccc"};
`;

const FilterText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.$selected ? "#333" : "#ccc"};
`;

const FilterSegmentedControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
`;

const FilterSegmentedControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 38px;
  height: 28px;
  color: #333;
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;
  &:hover {
    background-color: #f0f0f0;
  }

  // Vertical line between buttons
  &:not(:first-child) {
    border-left: 1px solid #e0e0e0;
  }
`;