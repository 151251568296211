import React, { useEffect } from "react";
import { flexRender } from "@tanstack/react-table";
import classnames from "classnames";

// Custom equality function for React.memo.
// It compares the row's id and the underlying original data.
// Adjust this function to compare only the properties that determine
// whether the row's rendered output should change.
const areEqual = (prevProps, nextProps) => {
  const prevRow = prevProps.row;
  const nextRow = nextProps.row;

  // Compare the unique id.
  if (prevRow.id !== nextRow.id) return false;
  
  // Compare the underlying original data reference.
  // This assumes that your reducer returns the same object for unchanged rows.
  if (prevRow.original !== nextRow.original) {
    // Alternatively, you can do a shallow comparison of row.original properties:
    const prevOriginal = prevRow.original;
    const nextOriginal = nextRow.original;
    const prevKeys = Object.keys(prevOriginal);
    const nextKeys = Object.keys(nextOriginal);
    if (prevKeys.length !== nextKeys.length) return false;
    for (let key of prevKeys) {
      if (prevOriginal[key] !== nextOriginal[key]) return false;
    }
  }

  // Also compare index and any other props that determine rendering.
  if (prevProps.index !== nextProps.index) return false;
  if (prevProps.computeCellStyle !== nextProps.computeCellStyle) return false;
  if (prevProps.SubComponent !== nextProps.SubComponent) return false;

  // Compare the per-row highlighted/selected state.
  if (prevProps.isHighlighted !== nextProps.isHighlighted) return false;
  if (prevProps.isSelected !== nextProps.isSelected) return false;

  return true;
};

export const TableRow = React.memo(({ row, index, computeCellStyle, SubComponent }) => {
  return (
    <React.Fragment>
      <div className="rt-tr-group">
        <div className={classnames("rt-tr", index % 2 === 0 ? "-odd" : "-even")}>
          {row.getAllCells().map((cell) => {
            const { style, ...cellProps } = computeCellStyle(cell);
            return (
              <div
                {...cellProps}
                className={classnames("rt-td", cell.column.columnDef.className)}
                style={style}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            );
          })}
        </div>
        {row.getIsExpanded() && SubComponent && SubComponent({ row })}
      </div>
    </React.Fragment>
  );
}, areEqual);