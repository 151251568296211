import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import SearchBox from "../../components/SearchBox";
import SmallButton from "../../components/SmallButton";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import * as userActions from "../../actions/users";
import * as selectedActions from "../../actions/selected";
import Modal from "../../components/Modal";
import InputBox from "../../components/InputBox";

class UserList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      showInviteModal: false,
      emails: []
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onNewUserClicked = this.onNewUserClicked.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEmailsChanged = this.onEmailsChanged.bind(this);
    this.onInviteUsers = this.onInviteUsers.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/org/users/${rowInfo.original.id}/info`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id != null && rowInfo.original.id === this.props.userId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedUsers[row.original.id] !== undefined && this.props.selectedUsers[row.original.id]) {
      this.props.deselectUser(row.original.id);
    }
    else {
      this.props.selectUser(row.original);
    }
  }

  onNewUserClicked() {
    this.setState({ showInviteModal: true });
  }

  onEmailsChanged(event) {
    const emails = event.target.value.replaceAll(" ", "").split(",")
    this.setState({ emails });
  }

  onInviteUsers() {
    const validUntil = moment().add(4, "week").toISOString();
    this.props.inviteUsers(this.state.emails, "user", validUntil);
    this.setState({ 
      showInviteModal: false,
      emails: []
    });
  }

  onCancel() {
    this.props.clearSelection();
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getUsers(queryParams);
  }

  render() {
    const { isLoading } = this.props;
    // console.log("UserList.render()", this.props);
  
    const hasSelection = !isEmpty(this.props.selectedUsers);

    const hasSudoRole = this.props.auth.hasSudoRole;
    const canInviteAdmins = this.props.auth.enableAdminInvites;
  
    let inviteButtons = [];
    if ((canInviteAdmins || hasSudoRole) && this.props.auth.hasITAdminRole) {
      inviteButtons.push(
        <SmallButton key="b1" text="Invite users" onClick={this.onNewUserClicked} disabled={!this.props.auth.hasITAdminRole} singleLine noMargin />
      );
    }

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => { this.setState({ searchText: value }) }}
            onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
            onSearchClick={this.onSearchClick}
            inListView
          />
        )}
        buttons={inviteButtons}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("user");

    // Remove invites from users
    const users = get(this.props.users, "data", []).filter(user => user.id !== undefined);

    const tableElement = (
      <Table
        data={users}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.users.count}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc", offset: 0 }, this.fetchData);
        }}
        onOffsetChange={(offset) => { this.setState({ offset }, this.fetchData); }}
        noDataText={isLoading ? "" : "No users found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={
                    this.props.selectedUsers[row.original.id] !== undefined && this.props.selectedUsers[row.original.id]
                  }
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name"
          },
          {
            header: "Email",
            accessorKey: "email"
          },
          {
            header: "Phone",
            accessorKey: "phone_number",
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div><div className="arrow" /></div>)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <div>
          { options }
          { tableElement }
          <div style={{ paddingTop: "40px" }} />
        </div>

        <Modal
          show={this.state.showInviteModal}
          onHide={() => this.setState({ showInviteModal: false })}
          title="Invite users"
          primaryBtn={{
            text: "Invite",
            onClick: this.onInviteUsers
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showInviteModal: false })
          }}
          >
            <InputBox
              label="Email(s)"
              value={this.state.emails.join(",")}
              onChange={this.onEmailsChanged}
              valid={true}
            />
            <div style={{ paddingTop: "40px" }} />
         </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    auth: state.auth,
    isLoading: state.loading.users,
    selectedUsers: state.selected.users,
    selectedCompany: state.auth.selectedCompany,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getUsers: userActions.getUsers,
    inviteUsers: userActions.inviteUsers,
    selectUser: selectedActions.selectUser,
    deselectUser: selectedActions.deselectUser,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
