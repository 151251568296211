import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Container } from "react-grid-system";
import Cookies from "universal-cookie";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import MapboxMap from "../Map/MapboxMap";
import Freetext from "../../components/Freetext";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import SearchBox from "../../components/SearchBox";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import * as jobActions from "../../actions/jobs";
import style from "./style.module.scss";

const cookies = new Cookies();

class NewWebexSensor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sensorId: "",
      name: "",
      description: "",
      id: props.match.params.id,
      searchText: ""
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onSensorIdChange = this.onSensorIdChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSaveWithMap = this.onSaveWithMap.bind(this);
    this.onSaveWithoutMap = this.onSaveWithoutMap.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onViewJob = this.onViewJob.bind(this);
    this.onClose = this.onClose.bind(this);
    this.authenticateCisco = this.authenticateCisco.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

    if (props.match.params.id !== props.location.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
      // props.getCompanyMap();
    }

    // Reset Created job for Modal
    this.props.clearCreatedJob();

    const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);
    if (!isEmpty(ciscoAccessToken)) {
      this.props.getWebexDevices();
    }
  }

  onBackClick(id) {
    if (get(this.props.location, "state.canGoBack", false)) {
      this.props.history.goBack();
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/sensors`);
    }
  }

  onViewJob() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/unknown/unknown/${this.props.createdJob.jobId}`);
  }

  onClose() {
    this.props.clearCreatedJob();
  }

  onSearchClick() {
    this.props.getWebexDevices(this.state.searchText === "" ? undefined : this.state.searchText);
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onSensorIdChange(event) {
    this.setState({ sensorId: event.target.value });
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onSaveWithMap() {
    const { push } = this.props.history;

    const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);

    const body = {
      name: this.state.name,
      ciscoDeviceId: this.state.sensorId,
      locationIds: [this.props.location.id],
      accessToken: ciscoAccessToken
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    if (this.props.createdFeature) {
      body.geoJsonFeature = this.props.createdFeature;
    }

    this.props.createSensor("webex", body, this.props.location.id, push);
  }

  onSaveWithoutMap() {
    const { push } = this.props.history;

    const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);

    const body = {
      name: this.state.name,
      ciscoDeviceId: this.state.sensorId,
      locationIds: [this.props.location.id],
      accessToken: ciscoAccessToken
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    this.props.createSensor("webex", body, this.props.location.id, push);
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  authenticateCisco() {
    var baseUrl;
    if (process.env.NODE_ENV === "development") {
      baseUrl = "http://localhost:8081";
    }
    else {
      baseUrl = window.location.origin;
    }

    window.location.href = `${baseUrl}/authenticate/cisco?from=${window.location.href}`;
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo !== undefined) {
          this.setState({ sensorId: rowInfo.original.id })
          this.props.getWebexDeviceMacroStatus(rowInfo.original.id);
        }
      },
      style: {
        cursor: "pointer",
        background: (rowInfo && rowInfo.original.id === this.state.sensorId ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  render() {
    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const ciscoAccessToken = cookies.get(`bldng_ai_cisco_access_token`);
    if (isEmpty(ciscoAccessToken)) {
      return (
        <div className={style.outerScroll}>
          <div className={style.topRow}>
            <Container className={style.container}>
              <Row>
                <Col offset={{ md:3 }} xs={12} md={6}>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext 
                        header="Login to Cisco Webex Portal"
                        content="Make sure you have access privilages to the device you are about to enroll."
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Button text="Authenticate" onClick={this.authenticateCisco} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }

    let heading = (
      <Row>
        <Col>
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
          />
        </Col>
      </Row>
    );

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.location.floorMap} 
            drawMode="draw_point"
            showSensors
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    else if (this.props.companyMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.companyMap} 
            drawMode="draw_point"
            showSensors
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    
    // Options for saving
    const options = [];
    if (isEmpty(this.props.createdFeature)) {
      options.push({ 
        label: "Save without map", 
        callback: this.onSaveWithoutMap, 
        disabled: (this.state.name.length < 3)
      });
    }
    else {
      options.push({ 
        label: "Save with map", 
        callback: this.onSaveWithMap, 
        disabled: (this.state.name.length < 3)
      });
    }

    return (
      <>
        <div className={style.editContainer}>
          <div className={style.row}>
            <div className={style.col33}>
              <div className={style.scroll}>
                <div className={style.topRow}>
                  <Freetext header={`Select device`} />
                </div>
                { heading }
                <Table
                  data={this.props.webexDevices}
                  columns={[
                    {
                      header: "Name",
                      accessorKey: "displayName",
                      cell: ({ row }) => (<span title={row.original.displayName}>{row.original.displayName}</span>)
                    },
                    {
                      header: "Product name",
                      accessorKey: "product",
                      cell: ({ row }) => (<span title={row.original.product}>{row.original.product}</span>)
                    },
                    {
                      header: "Serial number",
                      accessorKey: "serial",
                      width: 120,
                      cell: ({ row }) => (<span title={row.original.serial}>{row.original.serial}</span>)
                    },
                    {
                      id: "arrow",
                      header: "",
                      accessorKey: "id",
                      sortable: false,
                      className: "pull-right",
                      width: 30,
                      cell: () => <div className="arrow" />
                    }
                  ]}
                  getTdProps={this.onRowClick}
                  className="-row-clickable setMaxHeigth -highlight"
                  loading={this.props.isLoading}
                />
              </div>
            </div>
            <div className={style.col33}>
              <div className={style.scroll}>
                <div className={style.topRow}>
                  <Freetext header={`Add sensor to ${this.props.location.name}`} />
                </div>
                <h3>1. Enter ID, name and description for sensor</h3>
                <InputGroup autoFocus onChange={this.onSensorIdChange} label="Sensor ID" placeholder="e.g. 4bebf938-a6bf-44f9-b7d8-2dfe5475e6e8" value={this.state.sensorId} valid={this.state.sensorId} required />
                <InputGroup onChange={this.onNameChange} label="Name (minimum 3 characters)" placeholder="e.g. K2B sensor" value={this.state.name} valid={this.state.name.length > 2} required />
                <InputGroup onChange={this.onDescriptionChange} label="Description (optional)" placeholder="e.g. The sensor is on the north wall" value={this.state.description} valid={this.state.description} />
              </div>
            </div>
            <div className={style.col33}>
              <h3 className={style.mapHeader}>2. Place sensor in the map</h3>
              { mapElement }
            </div>
          </div>
          <OptionFooter 
            cancel={this.onCancel} 
            cancelButtonLabel="Cancel"
            options={options} 
          />
        </div>

        <Modal
          show={!isEmpty(this.props.createdJob)}
          title="Creating sensor"
          text={"The sensor creation request has been sent."}
          primaryBtn={{
            text: "Add another",
            onClick: this.onClose
          }}
          secondaryBtn={{
            text: "View job",
            onClick: this.onViewJob
          }}
          tertiaryBtn={{
            text: "Return",
            color: "blue", 
            onClick: this.onCancel
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensor: state.sensor,
    webexDevices: state.sensors.webexDevices,
    isLoading: state.loading.sensors,
    createdJob: state.sensors.createdJob,
    company: state.auth.selectedCompany,
    createdFeature: state.selected.createdFeature
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocation: locationActions.getLocation,
    getWebexDevices: sensorActions.getWebexDevices,
    getWebexDeviceMacroStatus: sensorActions.getWebexDeviceMacroStatus,
    clearSelection: selectedActions.clearSelection,
    createSensor: sensorActions.createSensor,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap,
    clearCreatedJob: jobActions.clearCreatedJob
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewWebexSensor);
