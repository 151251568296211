import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, set } from "lodash";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import OptionFooter from "../../components/OptionFooter";
import * as authActions from "../../actions/auth";
import * as accountActions from "../../actions/account";
import * as widgetActions from "../../actions/widgets";
import { OuterContainer, InnerContainer, Row, Col } from ".";

class AdvancedSettings extends Component {

  constructor(props) {
    // console.log("AdvancedSettings", props);
    super(props);
    this.state = {
      id: props.match.params.companyId,
      name: "",
      mazemapCampusTag: "",
      mazemapApiKey: "",
      yanziAccountIds: "",
      inviteEmail: "",
      whitelistedAdmins: "",
      defaultPeopleCountCustomTagId: "",
      hasLoadedState: false
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onMazemapCampusTagChanged = this.onMazemapCampusTagChanged.bind(this);
    this.onMazemapApiKeyChanged = this.onMazemapApiKeyChanged.bind(this);
    this.onSyncMazemap = this.onSyncMazemap.bind(this);
    this.onYanziAccountIDsChange = this.onYanziAccountIDsChange.bind(this);
    this.onSyncYanzi = this.onSyncYanzi.bind(this);

    this.onInviteEmailChange = this.onInviteEmailChange.bind(this);
    this.onWhitelistedAdminsChange = this.onWhitelistedAdminsChange.bind(this);

    this.onEnableAppChange = this.onEnableAppChange.bind(this);
    this.onEnableScreenChange = this.onEnableScreenChange.bind(this);
    this.onEnableInsightsChange = this.onEnableInsightsChange.bind(this);
    this.onEnableWorkplaceAnalysisChange = this.onEnableWorkplaceAnalysisChange.bind(this);
    this.onEnableNoShowAnalysisChange = this.onEnableNoShowAnalysisChange.bind(this);
    this.onEnableEnergyAnalysisChange = this.onEnableEnergyAnalysisChange.bind(this);
    this.onEnableAdminInvitesChange = this.onEnableAdminInvitesChange.bind(this);

    this.onUseAzureApisChange = this.onUseAzureApisChange.bind(this);
    this.onUseDittoApisChange = this.onUseDittoApisChange.bind(this);
    this.onUseMSALChange = this.onUseMSALChange.bind(this);
    this.onShowTeamChange = this.onShowTeamChange.bind(this);

    this.onShowAppChange = this.onShowAppChange.bind(this);
    this.onShowMeetingChange = this.onShowMeetingChange.bind(this);
    this.onShowWorkplaceChange = this.onShowWorkplaceChange.bind(this);

    this.onUseGraphResourcesChanged = this.onUseGraphResourcesChanged.bind(this);
    this.onShowCapacityChanged = this.onShowCapacityChanged.bind(this);

    this.onSendInviteEmail = this.onSendInviteEmail.bind(this);
    this.onGetConsentLink = this.onGetConsentLink.bind(this);

    this.onForceReloadOfScreens = this.onForceReloadOfScreens.bind(this);

    this.onDefaultPeopleCountCustomTagIdChange = this.onDefaultPeopleCountCustomTagIdChange.bind(this);
   
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);

    if (props.match.params.companyId !== undefined && props.company._id !== props.match.params.companyId) {
      this.props.getSudoCompany(props.match.params.companyId);
    }

    this.props.getWidgetProfile(props.match.params.companyId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps", nextProps, prevState);

    if (prevState.hasLoadedState && nextProps.company._id === prevState.id) {
      return null;
    }

    console.log("getDerivedStateFromProps set values ? ", nextProps.company, nextProps.widgetProfile);
    if (prevState.id !== undefined && !isEmpty(nextProps.company) && nextProps.widgetProfile !== null) {
      console.log("getDerivedStateFromProps set values");

      const newState = {
        id: nextProps.company._id,
        name: nextProps.company.name || "",
        mazemapCampusTag: nextProps.company.mazemapCampusTag || "",
        mazemapApiKey: nextProps.company.mazemapApiKey || "",
        yanziAccountIds: nextProps.company.yanziAccountIds || "",
        applicationFeatures: !isEmpty(nextProps.company.applicationFeatures) ? { ...nextProps.company.applicationFeatures } : {},
        dashboardFeatures: !isEmpty(nextProps.company.dashboardFeatures) ? { ...nextProps.company.dashboardFeatures } : {},
        serviceFeatures: !isEmpty(nextProps.company.serviceFeatures) ? { ...nextProps.company.serviceFeatures } : {},
        defaultPeopleCountCustomTagId: nextProps.widgetProfile?.defaultPeopleCountCustomTagId || "",
        hasLoadedState: true
      };

      document.title = `BLDNG.ai - ${nextProps.company.name}`;

      return newState;
    }

    return null;
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onMazemapCampusTagChanged(event) {
    this.setState({ mazemapCampusTag: event.target.value });
  }

  onMazemapApiKeyChanged(event) {
    this.setState({ mazemapApiKey: event.target.value });
  }

  onSyncMazemap() {
    this.props.syncCompanyMap(this.state.id);
  }

  onYanziAccountIDsChange(event) {
    this.setState({ yanziAccountIds: event.target.value });
  }

  onSyncYanzi() {
    this.props.syncYanzi(this.state.id);
  }

  onInviteEmailChange(event) {
    this.setState({ inviteEmail: event.target.value });
  }

  onWhitelistedAdminsChange(event) {
    this.setState({ whitelistedAdmins: event.target.value });
  }

  onEnableAppChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableApp = !get(newState.serviceFeatures, "enableApp", false);
      return newState;
    });
  }

  onEnableScreenChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableScreen = !get(newState.serviceFeatures, "enableScreen", false);
      return newState;
    });
  }

  onEnableInsightsChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableInsights = !get(newState.serviceFeatures, "enableInsights", false);
      return newState;
    });
  }

  onEnableWorkplaceAnalysisChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableWorkplaceAnalysis = !get(newState.serviceFeatures, "enableWorkplaceAnalysis", false);
      return newState;
    });
  }

  onEnableNoShowAnalysisChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableNoShowAnalysis = !get(newState.serviceFeatures, "enableNoShowAnalysis", false);
      return newState;
    });
  }

  onEnableEnergyAnalysisChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableEnergyAnalysis = !get(newState.serviceFeatures, "enableEnergyAnalysis", false);
      return newState;
    });
  }

  onEnableAdminInvitesChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.serviceFeatures.enableAdminInvites = !get(newState.serviceFeatures, "enableAdminInvites", false);
      return newState;
    });
  }

  onUseAzureApisChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.useAzureApis = !get(newState.applicationFeatures, "useAzureApis", false);
      return newState;
    });
  }

  onUseDittoApisChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.useDittoApis = !get(newState.applicationFeatures, "useDittoApis", false);
      return newState;
    });
  }

  onUseMSALChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.useMSAL = !get(newState.applicationFeatures, "useMSAL", false);
      return newState;
    });
  }

  onShowTeamChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.showTeam = !get(newState.applicationFeatures, "showTeam", false);
      return newState;
    });
  }

  onShowAppChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.dashboardFeatures.showApp = !get(newState.dashboardFeatures, "showApp", false);
      return newState;
    });
  }

  onShowMeetingChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.dashboardFeatures.showMeetingRooms = !get(newState.dashboardFeatures, "showMeetingRooms", false);
      return newState;
    });
  }

  onShowWorkplaceChange() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.dashboardFeatures.showWorkplace = !get(newState.dashboardFeatures, "showWorkplace", false);
      return newState;
    });
  }

  onUseGraphResourcesChanged() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.useGraphResources = !get(newState.applicationFeatures, "useGraphResources", false);
      return newState;
    });
  }

  onShowCapacityChanged() {
    this.setState(prevState => {
      let newState = { ...prevState };
      newState.applicationFeatures.showCapacity = !get(newState.applicationFeatures, "showCapacity", false);
      return newState;
    });
  }

  onSendInviteEmail() {
    this.props.sendConsentEmail(this.state.inviteEmail, this.state.whitelistedAdmins.split(","));
  }

  async onGetConsentLink() {
    try {
      const data = await authActions.getConsentLink();
      window.Clipboard.copy(data.consentLinkEncoded);
      this.setState({ copiedConsentLink: true });
      setTimeout(() => {
        this.setState({ copiedConsentLink: false });
      }, 2000);
    } 
    catch (err) {
      console.error("Failed to fetch consent link:", err);
      this.setState({ copiedConsentLink: false });
    }
  }

  onForceReloadOfScreens() {
    this.props.forceReloadOfScreens();
  }

  onDefaultPeopleCountCustomTagIdChange(event) {
    this.setState({ defaultPeopleCountCustomTagId: event.target.value });
  }

  onSave() {
    const { push } = this.props.history;

    if (this.hasWidgetProfileUpdates()) {
      const body = {
        locationPeopleCountCustomTags: [],
        hiddenWidgets: [],
        overviewWidgets: [],
        ...this.props.widgetProfile,
        defaultPeopleCountCustomTagId: this.state.defaultPeopleCountCustomTagId
      };
      
      this.props.setWidgetProfile(this.state.id, body);
    }

    if (this.hasCompanyUpdates()) {
      const body = {
        name: this.state.name,
        mazemapCampusTag: this.state.mazemapCampusTag || "",
        mazemapApiKey: this.state.mazemapApiKey || "",
        applicationFeatures: { 
          useAzureApis: !!this.state.applicationFeatures.useAzureApis,
          useDittoApis: !!this.state.applicationFeatures.useDittoApis,
          useMSAL: !!this.state.applicationFeatures.useMSAL,
          showTeam: !!this.state.applicationFeatures.showTeam,
          useGraphResources: !!this.state.applicationFeatures.useGraphResources,
          showCapacity: !!this.state.applicationFeatures.showCapacity,
        },
        dashboardFeatures: { 
          showApp: !!this.state.dashboardFeatures.showApp,
          showMeetingRooms: !!this.state.dashboardFeatures.showMeetingRooms,
          showWorkplace: !!this.state.dashboardFeatures.showWorkplace
        },
        serviceFeatures: { 
          enableApp: !!this.state.serviceFeatures.enableApp,
          enableScreen: !!this.state.serviceFeatures.enableScreen,
          enableInsights: !!this.state.serviceFeatures.enableInsights,
          enableWorkplaceAnalysis: !!this.state.serviceFeatures.enableWorkplaceAnalysis,
          enableNoShowAnalysis: !!this.state.serviceFeatures.enableNoShowAnalysis,
          enableEnergyAnalysis: !!this.state.serviceFeatures.enableEnergyAnalysis,
          enableAdminInvites: !!this.state.serviceFeatures.enableAdminInvites
        },
      };

      const yanziAccountIds = this.state.yanziAccountIds.split(",");
      const firstYanziAccountId = get(yanziAccountIds, "[0]", null);
      if (!isEmpty(firstYanziAccountId)) {
        body.yanziAccountIds = yanziAccountIds;
      }

      this.props.updateCompany(this.state.id, body, push);
    }
  }

  onCancel() {
    this.setState({
      name: this.props.company.name || "",
      mazemapCampusTag: this.props.company.mazemapCampusTag || "",
      mazemapApiKey: this.props.company.mazemapApiKey || "",
      yanziAccountIds: this.props.company.yanziAccountIds || "",
      applicationFeatures: !isEmpty(this.props.company.applicationFeatures) ? { ...this.props.company.applicationFeatures } : {}, 
      dashboardFeatures: !isEmpty(this.props.company.dashboardFeatures) ? { ...this.props.company.dashboardFeatures } : {},
      serviceFeatures: !isEmpty(this.props.company.serviceFeatures) ? { ...this.props.company.serviceFeatures } : {},
      defaultPeopleCountCustomTagId: this.props.widgetProfile?.defaultPeopleCountCustomTagId || "",
    });
  }

  onDelete() {
    const { push } = this.props.history;
    this.props.deleteCompany(this.state.id, push);
  }

  hasCompanyUpdates() {
    return this.state.name.length > 0 &&
    (this.state.name !== this.props.company.name ||
      this.state.mazemapCampusTag !== this.props.company.mazemapCampusTag ||
      this.state.mazemapApiKey !== this.props.company.mazemapApiKey || 
      this.state.yanziAccountIds !== this.props.company.yanziAccountIds || 
      JSON.stringify(this.state.applicationFeatures) !== JSON.stringify(this.props.company.applicationFeatures) || 
      JSON.stringify(this.state.dashboardFeatures) !== JSON.stringify(this.props.company.dashboardFeatures) || 
      JSON.stringify(this.state.serviceFeatures) !== JSON.stringify(this.props.company.serviceFeatures)
    );
  }

  hasWidgetProfileUpdates() {
    return this.state.defaultPeopleCountCustomTagId !== (this.props.widgetProfile?.defaultPeopleCountCustomTagId || "");
  }
  
  getOptionFooter() {
    if (this.hasCompanyUpdates() || this.hasWidgetProfileUpdates()) {
      return (<OptionFooter cancel={this.onCancel} options={[{ label: "Save", callback: this.onSave }]} />);
    }

    return null;
  }

  render() {
    // const { isLoading } = this.props;

    // console.log("this.state", this.state);
    // console.log("this.props", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // Check e-mails
    const validConsentEmail = this.state.inviteEmail.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const validWhitelistedEmails = this.state.whitelistedAdmins.split(",").every(email => email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
    
    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          <Row size="xl" $paddingTop="15px">
            <Col>
              <h2>General</h2>
            </Col>
          </Row>
          <Row>
            <Col size="sm">
              <InputBox onChange={this.onNameChange} label="Name" valid={this.state.name} value={this.state.name} required showValidIcon style={{ paddingTop: 0 }} />
            </Col>
          </Row>
          <Row>
            <InputBox onChange={this.onMazemapCampusTagChanged} label="MazeMap campus tag" value={this.state.mazemapCampusTag} />
          </Row>
          <Row>
            <div style={{ width: "350px" }}>
              <InputBox onChange={this.onMazemapApiKeyChanged} label="MazeMap API key" value={this.state.mazemapApiKey} />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button text="Sync with MazeMap" onClick={this.onSyncMazemap} paddingTop="44px" slim  />
            </div>
          </Row>
          <Row>
            <InputBox onChange={this.onYanziAccountIDsChange} label="Yanzi account IDs" value={this.state.yanziAccountIds} />
            <div style={{ marginLeft: "10px" }}>
              <Button text="Sync with Yanzi" onClick={this.onSyncYanzi} paddingTop="44px" slim  />
            </div>
          </Row>
          { this.props.auth.hasSudoRole && (
            <>
              <Row $paddingTop="20px">
                <p>Click this button to force all screens (in all companies) to restart</p>
              </Row>
              <Row>
                <Button text="Restart Screens" onClick={this.onForceReloadOfScreens} slim color="red" />
              </Row>
            </>
          )}
          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Service features</h2>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Checkbox label="App" description="(users can log in to the app and admins can create content)" isChecked={get(this.state.serviceFeatures, "enableApp", false)} onClick={this.onEnableAppChange} />
          </Row>
          <Row>
            <Checkbox label="Screens" description="(admins can create and use screens)" isChecked={get(this.state.serviceFeatures, "enableScreen", false)} onClick={this.onEnableScreenChange} />
          </Row>
          <Row>
            <Checkbox label="Insights" description="(give access to the deprecated reports)" isChecked={get(this.state.serviceFeatures, "enableInsights", false)} onClick={this.onEnableInsightsChange} />
          </Row>
          <Row>
            <Checkbox label="Workplace analysis" description="(give access to workplace reports)" isChecked={get(this.state.serviceFeatures, "enableWorkplaceAnalysis", false)} onClick={this.onEnableWorkplaceAnalysisChange} />
          </Row>
          <Row>
            <Checkbox label="No-show analysis" description="(give access to no-show reports)" isChecked={get(this.state.serviceFeatures, "enableNoShowAnalysis", false)} onClick={this.onEnableNoShowAnalysisChange} />
          </Row>
          <Row>
            <Checkbox label="Energy analysis" description="(give access to energy reports)" isChecked={get(this.state.serviceFeatures, "enableEnergyAnalysis", false)} onClick={this.onEnableEnergyAnalysisChange} />
          </Row>
          <Row>
            <Checkbox label="Allow invites" description="(let admins/support send invites)" isChecked={get(this.state.serviceFeatures, "enableAdminInvites", false)} onClick={this.onEnableAdminInvitesChange} />
          </Row>

          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Application features</h2>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Checkbox label="Use Azure APIs" description="(show meetings and booking from M365)" isChecked={get(this.state.applicationFeatures, "useAzureApis", false)} onClick={this.onUseAzureApisChange} />
          </Row>
          <Row>
            <Checkbox label="Use Ditto APIs" description="(show live data to users)" isChecked={get(this.state.applicationFeatures, "useDittoApis", false)} onClick={this.onUseDittoApisChange} />
          </Row>
          <Row>
            <Checkbox label="Use MSAL" description="(force users to log in with MSAL - for Intune)" isChecked={get(this.state.applicationFeatures, "useMSAL", false)} onClick={this.onUseMSALChange} />
          </Row>
          <Row>
            <Checkbox label="Show Team" description="(enable the team view in the app)" isChecked={get(this.state.applicationFeatures, "showTeam", true)} onClick={this.onShowTeamChange} />
          </Row>

          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Dashboard features</h2>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Checkbox label="App statistics" description="(active and total users)" isChecked={get(this.state.dashboardFeatures, "showApp", false)} onClick={this.onShowAppChange} />
          </Row>
          
          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Resources</h2>
              <p></p>
            </Col>
          </Row>
          <Row size="md"> 
            <Checkbox label="Use Graph resources" isChecked={get(this.state.applicationFeatures, "useGraphResources", false)} onClick={this.onUseGraphResourcesChanged} />
          </Row>
          <Row size="md"> 
            <Checkbox label="Show resource capacity" description="(when using graph resources)" isChecked={get(this.state.applicationFeatures, "showCapacity", false)} onClick={this.onShowCapacityChanged} />
          </Row>

          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Widget profile</h2>
              <p>The people count widgets on the front page requires a custom tag that the data is fetched from. The default will be used for all locations without a defined custom tag id.</p>
            </Col>
          </Row>
          <Row>
            <Col size="sm">
              <InputBox onChange={this.onDefaultPeopleCountCustomTagIdChange} label="Default people count custom tag id" value={this.state.defaultPeopleCountCustomTagId} />
            </Col>
          </Row>

          <Row size="xl" $paddingTop="20px">
            <Col>
              <h2>Azure/Entra ID consent-flow</h2>
              <p>Send a consent e-mail to the Azure admin to start the Entra ID sync. You can also pre-register BLDNG.ai admins to define who will get access when the Entra ID sync is done.</p>
            </Col>
          </Row>
          <Row>
            <Col size="sm">
              <InputBox onChange={this.onWhitelistedAdminsChange} label="BLDNG.ai admins" placeholder="comma-separated e-mails" value={this.state.whitelistedAdmins} valid={validWhitelistedEmails} required showValidIcon />
            </Col>
          </Row>
          <Row>
            <div style={{ width: "350px" }}>
              <InputBox onChange={this.onInviteEmailChange} label="Azure admin" placeholder="e-mail" value={this.state.inviteEmail} valid={validConsentEmail} required showValidIcon />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button text="Send consent e-mail" onClick={this.onSendInviteEmail} paddingTop="44px" slim disabled={!validConsentEmail} />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button text={this.state.copiedConsentLink ? "Copied" : "Copy consent link"}  onClick={this.onGetConsentLink} paddingTop="44px" slim disabled={this.state.copiedConsentLink} />
            </div>
          </Row>
          <Row>
            <Col size="md">
              <TextBox label="SCIM token" value={get(this.props.company, "scimToken", "")} disabled showCopy />
            </Col>
          </Row>
          
        </InnerContainer>
          { this.getOptionFooter() }
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.sudo.company,
    companyDraft: state.sudo.draft,
    isLoading: state.loading.sudo,
    widgetProfile: state.widgets.profile
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getSudoCompany: authActions.getSudoCompany,
    updateCompany: authActions.updateCompany,
    createCompany: authActions.createCompany,
    deleteCompany: authActions.deleteCompany,
    syncCompanyMap: authActions.syncCompanyMap,
    syncYanzi: authActions.syncYanzi,
    sendConsentEmail: authActions.sendConsentEmail,
    forceReloadOfScreens: accountActions.forceReloadOfScreens,
    getWidgetProfile: widgetActions.getWidgetProfile,
    setWidgetProfile: widgetActions.setWidgetProfile
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AdvancedSettings);