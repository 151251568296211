import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import { faPlug, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { get, isEmpty } from "lodash";
import { RowIcon, GREEN, BLUE, GREY, RED, ORANGE } from "../../components/RowIcon";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import SmallButton from "../../components/SmallButton";
import * as gatewayActions from "../../actions/gateways";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Gateways extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onExportGateways = this.onExportGateways.bind(this);
    this.onNewGateway = this.onNewGateway.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    let searchValue = value;
    searchValue = searchValue.replace("EUI64+","EUI64-");
    if (searchValue === "") {
      searchValue = undefined;
    }

    this.setState({ searchText: searchValue });
  }

  onNewGateway() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/new-gateway/`);
  }

  onRowClick(column, rowInfo) {
    let gateway = null;
    if (this.props.hoveredFeature && this.props.hoveredFeature.properties && this.props.hoveredFeature.properties.gateway) {
      gateway = JSON.parse(this.props.hoveredFeature.properties.gateway);
    }

    return {
      onClick: e => {
        if (column.name !== "isSelected" && rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/gateways/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        // Clear any pending leave timeout
        if (this.leaveTimeout) {
          clearTimeout(this.leaveTimeout);
          this.leaveTimeout = null;
        }
        if (rowInfo?.original?.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => {
        // Delay the clearing of hover state
        this.leaveTimeout = setTimeout(() => {
          this.onHover(null);
        }, 100);
      },
      style: {
        cursor: "pointer",
        background: (rowInfo && gateway && rowInfo.original.id === gateway._id ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onHover(id) {
    this.props.setHighlightedFeature(id);
  }

  onExportGateways() {
    this.props.downloadGatewaysCSV();
  }

  onToggle(row) {
    if (this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id]) {
      this.props.deselectGateway(row.original.id);
    }
    else {
      this.props.selectGateway(row.original);
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    if (queryParams.sortBy === "properties.connectionState") {
      queryParams.sortBy = "status";
    }

    this.props.getGateways(this.props.locationId, queryParams);
  }

  render() {
    const isLoading = this.props.isLocationLoading || this.props.isSensorsLoading || this.props.isGatewaysLoading;
    const canCreateGateway = this.props.auth.hasSupportRole;

    const heading = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: undefined }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New gateway" onClick={this.onNewGateway} disabled={!canCreateGateway} singleLine noMargin />
        ]}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("gateway");

    const tableElement = (
      <Table
        data={this.props.data.gateways}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => { this.setState({ offset }, this.fetchData) }}
        noDataText={isLoading ? "" : "No gateways found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "ID",
            accessorKey: "properties.vendorInfo.sensorId",
            sortable: false,
          },
          {
            id: "state",
            header: "",
            accessorKey: "properties.connectionState",
            width: 40,
            cell: ({ row }) => {
              const { connectionState, connectionStateUpdatedAt } = row.original.properties;
              if (connectionState === "connected") {
                return <RowIcon tooltip="connected" bgColor={GREEN} icon={faPlug} />
              }
              
              return <RowIcon tooltip="disconnected" bgColor={RED} icon={faPlug} />
            }
          },
          {
            id: "map",
            header: "",
            accessorKey: "properties.geoJsonFeature",
            width: 40,
            cell: ({ row }) => {
              const { geoJsonFeature } = row.original.properties;
              if (!isEmpty(geoJsonFeature)) {
                return <RowIcon tooltip="in map" bgColor={BLUE} icon={faMapMarkerAlt} />
              }
              
              return <RowIcon tooltip="not in map" bgColor={GREY} icon={faMapMarkerAlt} />
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => {
              const sensors = Object.keys(row.original.properties).filter((key) => (key !== "metadata"));
              return (<div>{sensors.length > 0 ? (<div className="arrow" />) : null}</div>)
            }
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isGatewaysLoading}
      />
    );

    const slimTableElement = (
      <Table
        data={this.props.data.gateways}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => { this.setState({ offset }, this.fetchData) }}
        noDataText={isLoading ? "" : "No gateways found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "",
            accessorKey: "properties.connectionState",
            width: 80,
            cell: ({ row }) => {
              const { connectionState, connectionStateUpdatedAt } = row.original.properties;
              if (connectionState === "connected") {
                return <RowIcon tooltip="connected" bgColor={GREEN} icon={faPlug} />
              }
              
              return <RowIcon tooltip="disconnected" bgColor={RED} icon={faPlug} />
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => {
              const sensors = Object.keys(row.original.properties).filter((key) => (key !== "metadata"));
              return (<div>{sensors.length > 0 ? (<div className="arrow" />) : null}</div>)
            }
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isGatewaysLoading}
      />
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={style.scroll}>
            { heading }
            { tableElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll}>
            { heading }
            { slimTableElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    isLocationLoading: state.loading.location,
    location: state.location,
    data: state.gateways.data,
    auth: state.auth,
    isSensorsLoading: state.loading.sensors,
    isGatewaysLoading: state.loading.gateways,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedTypes: state.selected.allTypes,
    hoveredFeature: state.selected.hoveredFeature,
    companyMap: state.auth.map
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getGateways: gatewayActions.getGateways,
    selectGateway: selectedActions.selectGateway,
    deselectGateway: selectedActions.deselectGateway,
    clearSelection: selectedActions.clearSelection,
    downloadGatewaysCSV: gatewayActions.downloadGatewaysCSV
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Gateways);
