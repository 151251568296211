import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Loader from "../../components/Loader";
import NoAccess from "../../containers/NoAccess";
import NotFound from "../../containers/NotFound";
import { getCustomTags } from "../../actions/customTags";
import { changeCompany } from "../../actions/auth";
import { getLocationHierarchy } from "../../actions/locations";
import { getCompanyId } from "../../helpers";
import * as types from "../../ApiTypes";

class Authentication extends Component {
  // redirect if not authenticated; otherwise, return the component inputted into <PrivateRoute />

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  componentDidUpdate(prevProps) {

    // Check if the URL companyId is different from the selectedCompanyId
    const urlCompanyId = getCompanyId();
    if (urlCompanyId && this.props.nextCompanyId !== urlCompanyId) {
      this.props.changeCompany(urlCompanyId);
      return;
    }

    // Load customTags and locationHierarchy if selectedCompany is set
    if (this.props.selectedCompanyId && this.props.selectedCompanyId !== prevProps.selectedCompanyId) {

      // Only query if the companyId is set
      if (urlCompanyId) {
        this.props.getCustomTags();
        this.props.getLocationHierarchy();
      }
    }
  }

  render() {
    // console.log("Authentication.props", this.props);

    if (this.props.isLoading || 
        this.props.isProfileLoading || 
        this.props.isVortoIdsLoading || 
        !this.props.hasCheckedCookie) {
      return <Loader fullScreen />;
    }

    const path = this.props.location.pathname;
    const urlCompanyId = getCompanyId();

    if (!this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: "/signin",
            state: {
              from: this.props.location,
              message: "Sign in with your phone number"
            }
          }}
        />
      );
    }

    // Check if the URL is a global support view - do not redirect to company view
    const inSupport = path.startsWith("/support");
    if (inSupport) {
      if (this.props.auth.hasAnyInternalRole) {
        return this.props.children;
      }
    }

    // If the URL companyId is empty and the selectedCompanyId is set - redirect to the selectedCompanyId
    if (isEmpty(urlCompanyId) && !isEmpty(this.props.selectedCompanyId)) {
      return (
        <Redirect
          to={{
            pathname: `/companies/${this.props.selectedCompanyId}${this.props.location.pathname}`,
            search: this.props.location.search
          }}
        />
      );
    }
    else if (urlCompanyId !== this.props.selectedCompanyId) {
      // If the URL companyId is different from the selectedCompanyId - do not render the component
      // Could be the user is trying to access a company he/she does not have access to
      // or the company does not exist
      return <NotFound />;
    }

    if (isEmpty(this.props.selectedCompany)) {
      // If the selectedCompany is empty - do not render the component
      return <NoAccess />;
    }

    // Limit access to spesific views to spesific roles
    // console.log("Authentication.path", path);
    // console.log("auth", this.props.auth);

    if (path.includes(`/companies/${this.props.selectedCompanyId}/home`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/locations`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/org`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/filters`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/sensors`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/gateways`) ||
        path.includes(`/companies/${this.props.selectedCompanyId}/account`)) {
      if (this.props.auth.hasAdminRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/app`)) {
      if (this.props.auth.hasAdminRole && get(this.props.selectedCompany, "serviceFeatures.enableApp", false)) {
        return this.props.children;
      }
      if (this.props.auth.hasITSupportRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/screens`)) {
      if (this.props.auth.hasAdminRole && get(this.props.selectedCompany, "serviceFeatures.enableScreen", false)) {
        return this.props.children;
      }
      if (this.props.auth.hasITSupportRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/reports`)) {
      if (get(this.props.selectedCompany, "serviceFeatures.enableWorkplaceAnalysis", false)) {
        if (this.props.auth.hasAdminRole) {
          return this.props.children;
        }

        if (this.props.auth.hasUserRole && (this.props.auth.enableWPAReportAccess || this.props.auth.enableEnergyReportAccess || this.props.auth.enableNoShowReportAccess)) {
          return this.props.children;
        }
      }
      if (this.props.auth.hasITSupportRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/insights`)) {
      if (this.props.auth.hasAdminRole && get(this.props.selectedCompany, "serviceFeatures.enableInsights", false)) {
        return this.props.children;
      }
      if (this.props.auth.hasITSupportRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/installation`)) {
      if (this.props.auth.hasSupportRole) {
        return this.props.children;
      }
    }

    if (path.includes(`/companies/${this.props.selectedCompanyId}/dashboard`)) {
      if (this.props.auth.hasAdminRole) {
        return this.props.children;
      }
    }

    if (this.props.auth.hasSudoRole) {
      return this.props.children;
    }

    // If a User with report access - redirect to report page
    if (this.props.auth.hasUserRole && (this.props.auth.enableWPAReportAccess || this.props.auth.enableEnergyReportAccess || this.props.auth.enableNoShowReportAccess)) {
      return (
        <Redirect
          to={{
            pathname: `/companies/${this.props.selectedCompanyId}/reports`,
            search: this.props.location.search
          }}
        />
      );
    }
    
    return <NoAccess />;
  }
}

function mapStateToProps(state) {
  return {
    hasCheckedCookie: state.auth.hasCheckedCookie,
    authenticated: state.auth.authenticated,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    selectedCompanyId: state.auth.selectedCompany._id,
    nextCompanyId: state.auth.nextCompanyId,
    locationHierarchy: state.locations.hierarchy,
    customTags: state.report.customTags,
    isLoading: state.loading.auth,
    isProfileLoading: state.loading[types.GET_PROFILE],
    isVortoIdsLoading: state.loading[types.GET_VORTO_IDS]
  };
}

// export default connect(mapStateToProps)(Authentication);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { 
      changeCompany,
      getCustomTags,
      getLocationHierarchy,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authentication));