// dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { changeCompany } from "../../actions/auth";
import Select from "../../components/Select";
import style from "./style.module.scss"
import { Role, getCompanyId } from "../../helpers";

class CompanySelector extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(event) {
    this.props.changeCompany(event.target.value);

    const nextCompany = this.props.auth.user?.companies?.find(company => (company._id === event.target.value));
  
    if (event.target.value === "none") {
      this.props.history.push(`/support`);
    }
    else if (nextCompany.role >= Role.User) {
      
      // Get the current path and redirect to it
      if (window.location.pathname.includes("/home")) {
        this.props.history.push(`/companies/${nextCompany._id}/home`);
      } 
      else if (window.location.pathname.includes("/locations/")) {
        this.props.history.push(`/companies/${nextCompany._id}/locations`);
      } 
      else if (window.location.pathname.includes("/org/")) {
        this.props.history.push(`/companies/${nextCompany._id}/org`);
      } 
      else if (window.location.pathname.includes("/app")) {
        this.props.history.push(`/companies/${nextCompany._id}/app`);
      } 
      else if (window.location.pathname.includes("/displays")) {
        this.props.history.push(`/companies/${nextCompany._id}/displays`);
      } 
      else if (window.location.pathname.includes("/insights")) {
        this.props.history.push(`/companies/${nextCompany._id}/insights`);
      } 
      else if (window.location.pathname.includes("/installation/")) {
        this.props.history.push(`/companies/${nextCompany._id}/installation`);
      } 
      else if (window.location.pathname.includes("/screens")) {
        this.props.history.push(`/companies/${nextCompany._id}/screens`);
      } 
      else if (window.location.pathname.includes("/reports")) {
        this.props.history.push(`/companies/${nextCompany._id}/reports`);
      } 
      else {
        this.props.history.push(`/companies/${nextCompany._id}`);
      }

    }
    else if (nextCompany.hasPowerBiReports) {
      this.props.history.push(`/companies/${nextCompany._id}/insights`);
    }
    else {
      this.props.history.push(`/companies/${nextCompany._id}/`);
    }
  }

  render() {

    const companyId = getCompanyId() || "none";

    // If not logged in
    if (isEmpty(this.props.auth.user) || isEmpty(this.props.auth.user.companies)) {
      return <div className={style.dropdown} />;
    }

    const options = this.props.auth.user?.companies?.map((company, i) => (
      <option key={company._id} value={company._id}>{company.name}</option>
    ));

    // Add overview option for support users
    if (this.props.auth.hasAnyInternalRole) {
      options.unshift(<option key="none" value="none">BLDNG - support</option>);
    }

    return (
      <div className={style.dropdown}>
        <Select
          name="Companies"
          onChange={this.onChange}
          value={companyId}
          options={options}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ 
  auth: state.auth,
  selectedCompany: state.auth.selectedCompany 
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  changeCompany 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector);
