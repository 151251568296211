import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { v4 as uuid } from "uuid";

const cookies = new Cookies();

export const getWarningsSummary = (queryParams) => async (dispatch) => {

  const correlationId = uuid();
  dispatch(actions.requestData(types.GET_WARNINGS_SUMMARY, { correlationId }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let offset = 0;
  const limit = 30;
  let moreData = true;

  while (moreData) {
    try {
      // Dispatch the request data action.
      dispatch(actions.requestData(types.GET_WARNINGS_SUMMARY_PART, { correlationId }));

      const response = await axios.get(
        `${apiUrl}warnings-summary`,
        await actions.getAxiosConfig({ ...queryParams, offset, limit })
      );

      // Check if there are more pages to fetch.
      moreData = response.data.count > offset + response.data.results.length;

      // Dispatch the page data immediately.
      if (moreData) {
        dispatch(actions.receiveData(response.data, types.GET_WARNINGS_SUMMARY_PART, { correlationId }));
      }
      else {
        // Call both to update the loading state
        dispatch(actions.receiveData(response.data, types.GET_WARNINGS_SUMMARY_PART, { correlationId }));
        dispatch(actions.receiveFinished(types.GET_WARNINGS_SUMMARY, { correlationId }));
      }

      // Increment the offset to fetch the next page.
      offset += limit;
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WARNINGS_SUMMARY, { correlationId }));
      }
      // Exit the loop on error.
      moreData = false;
    }
  }
};


export const getWarnings = (companyId, queryParams) => async (dispatch) => {

  const correlationId = uuid();
  dispatch(actions.requestData(types.GET_WARNINGS, { correlationId, ...queryParams }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/warnings`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_WARNINGS, { correlationId, ...queryParams }))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_WARNINGS, { correlationId, ...queryParams }));
      }
    });
}

export const getWarning = (companyId, warningId) => async (dispatch) => {

  const correlationId = uuid();
  dispatch(actions.requestData(types.GET_WARNING, { correlationId }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/warnings/${warningId}`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_WARNING, { correlationId }))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_WARNING, { correlationId }));
      }
    });
}

export const muteWarning = (companyId, warningId, body) => async (dispatch) => {
  dispatch(actions.requestData(types.MUTE_WARNING));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/warnings/${warningId}/mute`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MUTE_WARNING, { warningId: warningId, ...body }));
      dispatch(getWarning(companyId, warningId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.MUTE_WARNING));
      }
    });
}

export const unmuteWarning = (companyId, warningId) => async (dispatch) => {
  dispatch(actions.requestData(types.UNMUTE_WARNING));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/warnings/${warningId}/mute`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UNMUTE_WARNING, { warningId: warningId }));
      dispatch(getWarning(companyId, warningId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.UNMUTE_WARNING));
      }
    });
}

export const getWarningLogs = (companyId, warningId, queryParams) => async (dispatch) => {

  const correlationId = uuid();
  dispatch(actions.requestData(types.GET_WARNING_LOGS, { correlationId, ...queryParams }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const newQueryParams = { ...queryParams };
  newQueryParams.sortBy = "datetime";
  newQueryParams.sortOrder = "desc";

  axios.get(`${apiUrl}companies/${companyId}/warnings/${warningId}/logs`, await actions.getAxiosConfig(newQueryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_WARNING_LOGS, { correlationId, ...queryParams }))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_WARNING_LOGS, { correlationId, ...queryParams }));
      }
    });
}

export const createComment = (companyId, warningId, body) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_COMMENT));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/warnings/${warningId}/comments`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_COMMENT));
      dispatch(getWarning(companyId, warningId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.CREATE_COMMENT));
      }
    });
}

export const updateComment = (companyId, warningId, logId, body) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_COMMENT));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/warnings/${warningId}/comments/${logId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_COMMENT, { logId: logId }));
      dispatch(getWarning(companyId, warningId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.UPDATE_COMMENT));
      }
    });
}

export const deleteComment = (companyId, warningId, logId) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_COMMENT));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/warnings/${warningId}/comments/${logId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_COMMENT, { logId: logId }));
      dispatch(getWarning(companyId, warningId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.DELETE_COMMENT));
      }
    });
}