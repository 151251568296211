import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import SmallButton from "../../components/SmallButton";
import Loader from "../../components/Loader";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import OptionFooter from "../../components/OptionFooter";
import * as accountActions from "../../actions/account";
import { uploadImage } from "../../actions/content";
import { OuterContainer, InnerContainer, Row, Col, FloatCol } from ".";
import styled from "styled-components";

class Theme extends Component {

  constructor(props) {
    // console.log("Theme", props);
    super(props);
    this.state = {
      id: props.match.params.companyId,
      hasLoadedState: false
    };

    this.inputOpenFileRef = React.createRef();
    this.inputOpenLogoRef = React.createRef();
    this.inputOpenScreenLogoRef = React.createRef();

    this.onShowMapIconsChanged = this.onShowMapIconsChanged.bind(this);
    this.onStatusFilterChanged = this.onStatusFilterChanged.bind(this);
    this.onLabelFilterChanged = this.onLabelFilterChanged.bind(this);
    this.onAppLabelFilterChanged = this.onAppLabelFilterChanged.bind(this);
    this.onIconFilterChanged = this.onIconFilterChanged.bind(this);
    this.onUploadLogoClick = this.onUploadLogoClick.bind(this);
    this.onUploadLogo = this.onUploadLogo.bind(this);
    this.onDeleteLogoClick = this.onDeleteLogoClick.bind(this);
    this.onPrimaryColorChange = this.onPrimaryColorChange.bind(this);
    this.onHideMotionInRoomsWithCalendarChanged = this.onHideMotionInRoomsWithCalendarChanged.bind(this);
    this.onHideMotionInRoomsWithoutCalendarChanged = this.onHideMotionInRoomsWithoutCalendarChanged.bind(this);
    this.onUploadScreenLogo = this.onUploadScreenLogo.bind(this);
    this.onDeleteScreenLogoClick = this.onDeleteScreenLogoClick.bind(this);
    this.onUploadScreenLogoClick = this.onUploadScreenLogoClick.bind(this);
    this.onScreenPrimaryColorChange = this.onScreenPrimaryColorChange.bind(this);
    this.onScreenVacantColorChange = this.onScreenVacantColorChange.bind(this);
    this.onScreenOccupiedColorChange = this.onScreenOccupiedColorChange.bind(this);
    this.onScreenVacantBookedColorChange = this.onScreenVacantBookedColorChange.bind(this);
    this.onScreenStatusOpacityChange = this.onScreenStatusOpacityChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.getOptionFooter = this.getOptionFooter.bind(this);

    if (props.match.params.companyId !== undefined && props.company._id !== props.match.params.companyId) {
      // this.props.getCompany(props.match.params.companyId);
    }
  }

  onShowMapIconsChanged(event) {
    this.props.updateCompanyDraft({ showMapIcons: event.target.checked });
  }

  onStatusFilterChanged(event) {
    this.props.updateCompanyDraft({ statusFilter: event.target.value });
  }

  onLabelFilterChanged(event) {
    this.props.updateCompanyDraft({ labelFilter: event.target.value });
  }

  onAppLabelFilterChanged(event) {
    this.props.updateCompanyDraft({ appLabelFilter: event.target.value });
  }

  onIconFilterChanged(event) {
    this.props.updateCompanyDraft({ iconFilter: event.target.value });
  }

  onUploadLogo(event) {
    event.preventDefault();
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const binaryString = reader.result;
        const logoBase64 = btoa(binaryString);
        this.props.updateCompanyDraft({ theme: { logo: logoBase64 } });
      };

      reader.readAsBinaryString(file);
      event.target.value = ''; // eslint-disable-line no-param-reassign
    }
  }

  onUploadLogoClick() {
    this.inputOpenLogoRef.current.click();
  }

  onDeleteLogoClick() {
    this.props.updateCompanyDraft({ theme: { logo: null } });
  }

  onPrimaryColorChange(event) {
    this.props.updateCompanyDraft({ theme: { primaryColor: event.target.value } });
  }

  onHideMotionInRoomsWithCalendarChanged(event) {
    this.props.updateCompanyDraft({ hideMotionInRoomsWithCalendar: event.target.checked });
  }

  onHideMotionInRoomsWithoutCalendarChanged(event) {
    this.props.updateCompanyDraft({ hideMotionInRoomsWithoutCalendar: event.target.checked });
  }

  async onUploadScreenLogo(event) {
    event.persist();
    event.preventDefault();
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      const url = await this.props.uploadImage(file, file.name);
      this.props.updateCompanyDraft({ screenTheme: { logoUrl: url } });
      if (event.target) {
        event.target.value = ''; // eslint-disable-line no-param-reassign
      }
    }
  }

  onUploadScreenLogoClick() {
    this.inputOpenScreenLogoRef.current.click();
  }

  onDeleteScreenLogoClick() {
    this.props.updateCompanyDraft({ screenTheme: { logoUrl: null } });
  }

  onScreenPrimaryColorChange(event) {
    this.props.updateCompanyDraft({ screenTheme: { primaryColor: event.target.value } });
  }

  onScreenVacantColorChange(event) {
    this.props.updateCompanyDraft({ screenTheme: { vacantColor: event.target.value } });
  }

  onScreenOccupiedColorChange(event) {
    this.props.updateCompanyDraft({ screenTheme: { occupiedColor: event.target.value } });
  }

  onScreenVacantBookedColorChange(event) {
    this.props.updateCompanyDraft({ screenTheme: { vacantBookedColor: event.target.value } });
  }

  onScreenStatusOpacityChange(event) {
    this.props.updateCompanyDraft({ screenTheme: { statusOpacity: event.target.value } });
  }

  onSave() {
    // console.log("onSave", this.props.companyDraft)
    const { push } = this.props.history;

    const body = {};

    body.mapConfig = {
      showMapIcons: get(this.props.companyDraft, "showMapIcons", get(this.props.company, "showMapIcons", false)),
      statusFilter: get(this.props.companyDraft, "statusFilter", get(this.props.company, "statusFilter", "")),
      labelFilter: get(this.props.companyDraft, "labelFilter", get(this.props.company, "labelFilter", "")),
      appLabelFilter: get(this.props.companyDraft, "appLabelFilter", get(this.props.company, "appLabelFilter", "")),
      showSBLabels: true,
      iconFilter: get(this.props.companyDraft, "iconFilter", get(this.props.company, "iconFilter", ""))
    }

    body.hideMotionInRoomsWithCalendar = get(this.props.companyDraft, "hideMotionInRoomsWithCalendar", get(this.props.company, "hideMotionInRoomsWithCalendar", false));
    body.hideMotionInRoomsWithoutCalendar = get(this.props.companyDraft, "hideMotionInRoomsWithoutCalendar", get(this.props.company, "hideMotionInRoomsWithoutCalendar", false));
    
    if (!isEmpty(this.props.companyDraft.theme)) {
      body.theme = {};
      body.theme.logo = get(this.props.companyDraft, "theme.logo", get(this.props.company, "theme.logo", null));
      body.theme.primaryColor = get(this.props.companyDraft, "theme.primaryColor", get(this.props.company, "theme.primaryColor", null));
    }

    if (!isEmpty(this.props.companyDraft.screenTheme)) {
      body.screenTheme = {};
      body.screenTheme.logoUrl = get(this.props.companyDraft, "screenTheme.logoUrl", get(this.props.company, "screenTheme.logoUrl", null));
      body.screenTheme.primaryColor = get(this.props.companyDraft, "screenTheme.primaryColor", get(this.props.company, "screenTheme.primaryColor", null));
      body.screenTheme.vacantColor = get(this.props.companyDraft, "screenTheme.vacantColor", get(this.props.company, "screenTheme.vacantColor", null));
      body.screenTheme.occupiedColor = get(this.props.companyDraft, "screenTheme.occupiedColor", get(this.props.company, "screenTheme.occupiedColor", null));
      body.screenTheme.vacantBookedColor = get(this.props.companyDraft, "screenTheme.vacantBookedColor", get(this.props.company, "screenTheme.vacantBookedColor", null));

      // Convert statusOpacity to float
      const statusOpacity = parseFloat(get(this.props.companyDraft, "screenTheme.statusOpacity", get(this.props.company, "screenTheme.statusOpacity", "1.0")));
      if (isNaN(statusOpacity)) {
        body.screenTheme.statusOpacity = 1.0;
      }
      else {
        body.screenTheme.statusOpacity = statusOpacity;
      }
    }

    this.props.updateCompany(body, push);
  }

  onCancel() {
    this.props.clearCompanyDraft();
  }

  onFileUpload(event) {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];

    const { push } = this.props.history;

    reader.onloadend = () => {
      const json = JSON.parse(reader.result);
      if (json.type === "FeatureCollection") {
        delete json.type;
        this.props.uploadCompanyMap(this.state.id, json, push);
      }
    }

    if (event.target.files[0]) {
      reader.readAsText(file);
      event.target.value = ''; // eslint-disable-line no-param-reassign
    }
  }

  getOptionFooter() {

    const showOptionFooter = !isEmpty(this.props.companyDraft) && (
      get(this.props.companyDraft, "theme.logo", null) !== get(this.props.company, "theme.logo", null) ||
      get(this.props.companyDraft, "theme.primaryColor", null) !== get(this.props.company, "theme.primaryColor", null) ||
      get(this.props.companyDraft, "screenTheme.logoUrl", "") !== get(this.props.company, "screenTheme.logoUrl", "") ||
      get(this.props.companyDraft, "screenTheme.primaryColor", "") !== get(this.props.company, "screenTheme.primaryColor", "") ||
      get(this.props.companyDraft, "screenTheme.vacantColor", "") !== get(this.props.company, "screenTheme.vacantColor", "") ||
      get(this.props.companyDraft, "screenTheme.occupiedColor", "") !== get(this.props.company, "screenTheme.occupiedColor", "") ||
      get(this.props.companyDraft, "screenTheme.vacantBookedColor", "") !== get(this.props.company, "screenTheme.vacantBookedColor", "") ||
      get(this.props.companyDraft, "screenTheme.statusOpacity", "1.0") !== get(this.props.company, "screenTheme.statusOpacity", "1.0") ||
      get(this.props.companyDraft, "hideMotionInRoomsWithCalendar", false) !== get(this.props.company, "hideMotionInRoomsWithCalendar", false) ||
      get(this.props.companyDraft, "hideMotionInRoomsWithoutCalendar", false) !== get(this.props.company, "hideMotionInRoomsWithoutCalendar", false) ||
      get(this.props.companyDraft, "statusFilter", "") !== get(this.props.company, "statusFilter", "") ||
      get(this.props.companyDraft, "labelFilter", "") !== get(this.props.company, "labelFilter", "") ||
      get(this.props.companyDraft, "appLabelFilter", "") !== get(this.props.company, "appLabelFilter", "") ||
      get(this.props.companyDraft, "iconFilter", "") !== get(this.props.company, "iconFilter", "")
    );

    // Check if we should display the option save button
    let statusOpacityValid = false;
    const statusOpacity = get(this.props.companyDraft, "screenTheme.statusOpacity", get(this.props.company, "screenTheme.statusOpacity", "1.0"));
    const statusOpacityFloat = parseFloat(statusOpacity);
    if (!isNaN(statusOpacity) && statusOpacityFloat >= 0 && statusOpacityFloat <= 1) {
      statusOpacityValid = true;
    }

    const dataValid = statusOpacityValid;

    if (showOptionFooter) {
      return (<OptionFooter inline={true} cancel={this.onCancel} options={[{ label: "Save", callback: this.onSave, disabled: !dataValid }]} />);
    }

    return null;
  }

  render() {
    // const { isLoading } = this.props;

    // console.log("this.state", this.state);
    // console.log("this.props", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }
    
    return (
      <>
      <OuterContainer $darkBackground>
        <InnerContainer>
          <Row size="xl" $paddingTop="15px">
            <Col>
              <h2>App config</h2>
              <p>Select the logo and primary color for the app (iOS and Android). The logo should be completely white or black with a transparent background (.PNG) since it will be presented on top of your primary color.</p>
            </Col>
          </Row>
          <Row $paddingTop="10px">
            <FloatCol>
              <SmallButton text="Upload logo" onClick={this.onUploadLogoClick} noMargin />
            </FloatCol>
            {
              get(this.props.companyDraft, "theme.logo", get(this.props.company, "theme.logo", false)) && (
                <>
                  <FloatCol>
                    <SmallButton text="Delete logo" color="red" onClick={this.onDeleteLogoClick} noMargin />
                  </FloatCol>
                  <FloatCol>
                    <LogoImg src={`data:image/jpeg;base64,${get(this.props.companyDraft, "theme.logo", get(this.props.company, "theme.logo", ""))}`} alt="logo" />
                  </FloatCol>
                </>
              )
            }
            <label htmlFor="logoInput">
              <FileInput ref={this.inputOpenLogoRef} name="file" id="logoInput" type="file" onChange={this.onUploadLogo} />
            </label>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onPrimaryColorChange} label="Primary hex color" value={get(this.props.companyDraft, "theme.primaryColor", get(this.props.company, "theme.primaryColor", ""))} />
            </Col>
          </Row>
          <Row size="xl" $paddingTop="50px"> 
            <Col>
              <p>Set how M365 calendar data should be displayed together with motion data in the map (in most cases these should be unchecked).</p>
            </Col>
          </Row>
          <Row size="md"> 
            <Checkbox label="Hide motion data in rooms with calendar" isChecked={get(this.props.companyDraft, "hideMotionInRoomsWithCalendar", get(this.props.company, "hideMotionInRoomsWithCalendar", false))} onClick={this.onHideMotionInRoomsWithCalendarChanged} />
          </Row>
          <Row size="md"> 
            <Checkbox label="Hide motion data in rooms without calendar" isChecked={get(this.props.companyDraft, "hideMotionInRoomsWithoutCalendar", get(this.props.company, "hideMotionInRoomsWithoutCalendar", false))} onClick={this.onHideMotionInRoomsWithoutCalendarChanged} />
          </Row>
          <Row size="xl" $paddingTop="50px"> 
            <Col>
              <p>Add locations filters to configure presentation layers. These work by entering location types in an &#34;and/or/not&#34;-format. Any location type that meets the statement requirements will be presented. For example &#34;zone|room|!toilet&#34; will show data for any zone or room that is not a toilet.</p>
            </Col>
          </Row>
          <Row $paddingTop="0px"> 
            <Col size="sm">
              <InputBox 
                onChange={this.onStatusFilterChanged}
                label="Status filter (which location type should show status in the app)"
                value={get(this.props.companyDraft, "statusFilter", get(this.props.company, "statusFilter", ""))}
                placeholder="ex. meeting|quiet"
                help={`An empty field result in no status.`}
                />
            </Col>
          </Row>
          {/* <Row> 
            <Col size="sm">
              <InputBox
                onChange={this.onLabelFilterChanged}
                label="Label filter (which location type should show labels)"
                value={get(this.props.companyDraft, "labelFilter", get(this.props.company, "labelFilter", ""))}
                placeholder="ex. meeting|zone"
                help={`An empty field result in no status.`}
              />
            </Col>
          </Row> */}
          <Row>
            <Col size="sm">
              <InputBox
                onChange={this.onAppLabelFilterChanged}
                label="Label filter (which location type should show labels in the app)"
                value={get(this.props.companyDraft, "appLabelFilter", get(this.props.company, "appLabelFilter", ""))}
                placeholder="ex. meeting|zone" 
                help={`An empty field result in no status.`}/>
            </Col>
          </Row>
          {/* <Row>
            <Col size="sm">
              <InputBox onChange={this.onIconFilterChanged} label="Icon filter (which location type should show icons)" value={get(this.props.companyDraft, "iconFilter", get(this.props.company, "iconFilter", ""))} placeholder="ex. toilet" />
            </Col>
          </Row> */}
          <Row size="xl" $paddingTop="30px">
            <Col>
              <h2>Screen config</h2>
              <p>Select the logo, primary color and vacant/occupied colors for the screens. The logo should be completely white or black with a transparent background (.PNG) since it will be presented on top of your primary color.</p>
            </Col>
          </Row>
          <Row $paddingTop="10px">
            <FloatCol>
              <SmallButton text="Upload logo" onClick={this.onUploadScreenLogoClick} noMargin />
            </FloatCol>
            {
              get(this.props.companyDraft, "screenTheme.logoUrl", get(this.props.company, "screenTheme.logoUrl", false)) && (
                <>
                  <FloatCol>
                    <SmallButton text="Delete logo" color="red" onClick={this.onDeleteScreenLogoClick} noMargin />
                  </FloatCol>
                  <FloatCol>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "64px", width: "64px", backgroundColor: get(this.props.companyDraft, "screenTheme.primaryColor", get(this.props.company, "screenTheme.primaryColor", "")) || "gray" }}>
                      <img style={{ height: "52px", maxWidth: "64px" }} src={get(this.props.companyDraft, "screenTheme.logoUrl", get(this.props.company, "screenTheme.logoUrl", ""))} alt="logo2" />
                    </div>
                  </FloatCol>
                </>
              )
            }
            <label htmlFor="screenLogoInput">
              <FileInput ref={this.inputOpenScreenLogoRef} name="file" id="screenLogoInput" type="file" onChange={this.onUploadScreenLogo} />
            </label>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onScreenPrimaryColorChange} label="Primary hex color" value={get(this.props.companyDraft, "screenTheme.primaryColor", get(this.props.company, "screenTheme.primaryColor", ""))} />
            </Col>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onScreenVacantColorChange} label="Vacant hex color" value={get(this.props.companyDraft, "screenTheme.vacantColor", get(this.props.company, "screenTheme.vacantColor", ""))} />
            </Col>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onScreenOccupiedColorChange} label="Occupied hex color" value={get(this.props.companyDraft, "screenTheme.occupiedColor", get(this.props.company, "screenTheme.occupiedColor", ""))} />
            </Col>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onScreenVacantBookedColorChange} label="Booked but vacant hex color" value={get(this.props.companyDraft, "screenTheme.vacantBookedColor", get(this.props.company, "screenTheme.vacantBookedColor", ""))} />
            </Col>
          </Row>
          <Row>
            <Col size="xs">
              <InputBox onChange={this.onScreenStatusOpacityChange} label="Status color opacity (0.0 - 1.0)" value={get(this.props.companyDraft, "screenTheme.statusOpacity", get(this.props.company, "screenTheme.statusOpacity", "1.0"))} />
            </Col>
          </Row>
        </InnerContainer>
      </OuterContainer>
        { this.getOptionFooter() }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company.data,
    companyDraft: state.company.draft,
    isLoading: state.loading.company
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getCompany: accountActions.getCompany,
    updateCompany: accountActions.updateCompany,
    updateCompanyDraft: accountActions.updateCompanyDraft,
    clearCompanyDraft: accountActions.clearCompanyDraft,
    uploadImage: uploadImage
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Theme);

const FileInput = styled.input`
  display: none;
`;

const LogoImg = styled.img`
  width: 64px;
  height: 64px;
  background-color: white;
`;