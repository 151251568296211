import React from "react";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import classnames from "classnames";
import { TableRow } from "./tableRow";
import "./style.global.css";

const Table = (props) => {
  const {
    className = "",
    data,
    columns: propColumns,
    loading = false,
    offset = 0,
    limit = 20,
    count = 0,
    sortBy,
    sortOrder = "desc",
    hideHeaders = false,
    getTdProps,
    onOffsetChange,
    onSortedChange,
    noDataText,
    style,
    SubComponent,
    useDeepCompareOnRows = false,
    highlightedId,
    selectedId,
  } = props;

  const page = Math.floor(offset / limit) + 1;
  const pages = Math.ceil(count / limit);

  // Avoid mutating the columns prop directly
  const columns = React.useMemo(() => {
    if (SubComponent) {
      return [
        {
          header: "",
          id: "expander",
          resizable: false,
          className: "text-center",
          cell: ({ row }) => (
            <div
              {...row.getToggleRowExpandedProps()}
              title="Click here to see more information"
              className="rt-td rt-expandable p-0"
            >
              <div className={classnames("rt-expander", row.getIsExpanded() ? "-open" : "")}>•</div>
            </div>
          ),
        },
        ...propColumns,
      ];
    }
    return propColumns;
  }, [SubComponent, propColumns]);

  // Memoize the data if necessary
  const memoizedData = React.useMemo(() => data, [data]);

  // Call the useReactTable Hook at the top level
  const tableInstance = useReactTable({
    data: memoizedData,
    columns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  const headerGroups = tableInstance.getHeaderGroups();
  const rows = tableInstance.getCoreRowModel().rows;

  // For resizable columns
  const [columnWidths, setColumnWidths] = React.useState({});
  const columnRefs = React.useRef({});

  const startResizing = React.useCallback((columnId, initialClientX) => {
    const initialWidth = columnRefs.current[columnId]?.getBoundingClientRect().width || 0;

    const handleMouseMove = (e) => {
      const delta = e.clientX - initialClientX;
      setColumnWidths((currentWidths) => ({
        ...currentWidths,
        [columnId]: Math.max(initialWidth + delta, 50),
      }));
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  }, []);

  // Updated computeHeaderStyle
  const computeHeaderStyle = React.useCallback(
    (header) => {
      const style = {
        ...header.column.columnDef.style,
      };

      const columnDef = header.column.columnDef;
      const columnId = header.column.id;

      // Determine the flex property
      if (columnDef.width && !columnDef.minWidth && !columnDef.maxWidth) {
        // Fixed-width column
        style.width = columnDef.width;
        style.minWidth = columnDef.width;
        style.maxWidth = columnDef.width;
      } 
      else if (columnWidths[columnId]) {
        if (columnDef.maxWidth && columnWidths[columnId] > columnDef.maxWidth) {
          style.width = columnDef.maxWidth;
          style.maxWidth = columnDef.maxWidth;
          style.flex = `0 0 auto`;
        }
        else if (columnDef.minWidth && columnWidths[columnId] < columnDef.minWidth) {
          style.width = columnDef.minWidth;
          style.maxWidth = columnDef.minWidth;
          style.flex = `0 0 auto`;
        }
        else {
          style.width = columnWidths[columnId];
          style.maxWidth = columnWidths[columnId];
          style.flex = `0 0 auto`;
        }
      }
      else {
        style.width = columnDef.width;
        style.maxWidth = columnDef.maxWidth ? columnDef.maxWidth : columnDef.width;
        style.minWidth = columnDef.minWidth ? columnDef.minWidth : columnDef.width;
        style.flex = `${columnDef.width} 0 auto`;
      }

      return style;
    },
    [columnWidths]
  );

  // Updated computeCellStyle
  const computeCellStyle = React.useCallback(
    (cell) => {
      const cellProps = getTdProps ? getTdProps(cell.column.columnDef, cell.row) : {};
      const style = {
        ...cellProps.style,
        ...cell.column.columnDef.style,
      };

      const columnDef = cell.column.columnDef;
      const columnId = cell.column.id;

      // Determine the flex property
      if (columnDef.width && !columnDef.minWidth && !columnDef.maxWidth) {
        // Fixed-width column
        style.width = columnDef.width;
        style.minWidth = columnDef.width;
        style.maxWidth = columnDef.width;
      } 
      else if (columnWidths[columnId]) {
        if (columnDef.maxWidth && columnWidths[columnId] > columnDef.maxWidth) {
          style.width = columnDef.maxWidth;
          style.maxWidth = columnDef.maxWidth;
          style.flex = `0 0 auto`;
        }
        else if (columnDef.minWidth && columnWidths[columnId] < columnDef.minWidth) {
          style.width = columnDef.minWidth;
          style.maxWidth = columnDef.minWidth;
          style.flex = `0 0 auto`;
        }
        else {
          style.width = columnWidths[columnId];
          style.maxWidth = columnWidths[columnId];
          style.flex = `0 0 auto`;
        }
      }
      else {
        style.width = columnDef.width;
        style.maxWidth = columnDef.maxWidth ? columnDef.maxWidth : columnDef.width;
        style.minWidth = columnDef.minWidth ? columnDef.minWidth : columnDef.width;
        style.flex = `${columnDef.width} 0 auto`;
      }

      return { ...cellProps, style };
    },
    [columnWidths, getTdProps, highlightedId, selectedId] // include highlightedId and selectedId here
  );

  return (
    <div className={classnames("ReactTable", className)} style={style}>
      <div className="rt-table">
        {/* Table header */}
        <div className="rt-thead -header">
          {!hideHeaders &&
            headerGroups.map((headerGroup) => (
              <div className="rt-tr" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const style = computeHeaderStyle(header);
                  const resizable = header.column.columnDef.resizable !== false;
                  let tableHeaderClass = "rt-th";
                  let resizerClass = "rt-resizer";

                  if (resizable) {
                    tableHeaderClass = classnames(tableHeaderClass, "rt-resizable-header");
                    resizerClass = classnames(
                      resizerClass,
                      header.column.getIsResizing() ? "isResizing" : ""
                    );
                  }

                  const sortable = header.column.columnDef.sortable !== false;
                  const defaultSortDesc = header.column.columnDef.defaultSortDesc;
                  let onSort = null;
                  if (sortable && onSortedChange) {
                    tableHeaderClass = classnames(tableHeaderClass, "-cursor-pointer");

                    if (header.column.id === sortBy) {
                      tableHeaderClass = classnames(
                        tableHeaderClass,
                        sortOrder === "desc" ? "-sort-desc" : "-sort-asc"
                      );
                    }

                    onSort = () => {
                      const thisSortOrder = header.column.id === sortBy ? (sortOrder === "desc" ? "asc" : "desc") : defaultSortDesc ? "desc" : "asc";
                      const newSorting = [
                        {
                          id: header.column.id,
                          desc: thisSortOrder === "desc",
                        },
                      ];
                      onSortedChange(newSorting);
                    };
                  }

                  return (
                    <div className={tableHeaderClass} key={header.column.id} style={style}>
                      <div
                        className="rt-resizable-header-content"
                        onClick={onSort}
                        ref={(el) => (columnRefs.current[header.column.id] = el)}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      {resizable && (
                        <div
                          className={resizerClass}
                          onMouseDown={(e) => startResizing(header.column.id, e.clientX)}
                          onTouchStart={(e) => startResizing(header.column.id, e.touches[0].clientX)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
        </div>

        {/* Table body */}
        <div className="rt-tbody">
          {rows.map((row, index) => {
            if (useDeepCompareOnRows) {
              // Compute whether this specific row is highlighted/selected.
              const isHighlighted = row.id === highlightedId;
              const isSelected = row.id === selectedId;
              return (
                <TableRow
                  key={row.id}
                  row={row}
                  index={index}
                  computeCellStyle={computeCellStyle}
                  SubComponent={SubComponent}
                  isHighlighted={isHighlighted}
                  isSelected={isSelected}
                />
              );
            }
            else {
              return (
                <React.Fragment key={row.id}>
                  <div className="rt-tr-group">
                    <div className={classnames("rt-tr", index % 2 === 0 ? "-odd" : "-even")}>
                      {row.getAllCells().map((cell) => {
                        const { style, ...cellProps } = computeCellStyle(cell);
                        return (
                          <div
                            {...cellProps}
                            className={classnames("rt-td", cell.column.columnDef.className)}
                            style={style}
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </div>
                        );
                      })}
                    </div>
                    {row.getIsExpanded() && SubComponent && SubComponent({ row })}
                  </div>
                </React.Fragment>
              );
            }
          })}

          {/* No data text & loading */}
          {rows.length === 0 && (
            <div className="rt-tr-group-noData">
              <div className="rt-tr-noData">
                <div className="rt-td-noData">
                  <span className="rt-noData">
                    {loading
                      ? "Loading..."
                      : noDataText
                        ? noDataText
                        : "No data available"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {pages > 1 && (
        <div className="pagination-bottom">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                disabled={page === 1}
                className="-btn"
                onClick={() => onOffsetChange(offset - limit)}
              >
                Previous
              </button>
            </div>
            <div className="-center">
              <span className="-pageInfo">
                page
                <div className="-pageJump">
                  <input
                    aria-label="jump to page"
                    type="number"
                    value={page}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value, 10);
                      if (newValue > 0 && newValue <= pages) {
                        onOffsetChange((newValue - 1) * limit);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const newValue = parseInt(e.target.value, 10);
                        if (newValue > 0 && newValue <= pages) {
                          onOffsetChange((newValue - 1) * limit);
                        }
                      }
                    }}
                  />
                </div>
                of <span className="-totalPages">{pages}</span>
              </span>
            </div>
            <div className="-next">
              <button
                type="button"
                disabled={page === pages}
                className="-btn"
                onClick={() => onOffsetChange(offset + limit)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
