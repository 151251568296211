import axios from "axios";
import { EventEmitter } from "events";
import { isIE } from "react-device-detect";
import Cookies from "universal-cookie";
import isEmpty from "lodash/isEmpty";
import { COOKIE_PREFIX } from "../env";
import * as types from "../ApiTypes";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();
const emitter = new EventEmitter();
emitter.setMaxListeners(50);

// Simple config of Axios - Setting authentication by token
export async function getAxiosConfig(queryParams = {}) {

  const cookiePrefix = await COOKIE_PREFIX();

  const useRefreshToken = cookies.get(`${cookiePrefix}_use_refresh_token`);
  const expiredAccessToken = hasTokenExpired(cookies.get(`${cookiePrefix}_access_token`));
  if (expiredAccessToken && useRefreshToken) {
    await updateAccessToken(cookiePrefix);
  }

  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const reqHeaders = {};
  reqHeaders.Authorization = `Bearer ${accessToken}`;
  // restrict caching if browser is IE
  if (isIE) {
    reqHeaders.Pragma = 'no-cache';
  }

  const newQueryParams = queryParams;
  if (newQueryParams.search === '') {
    newQueryParams.search = undefined; // eslint-disable-line no-param-reassign
  }

	return { headers: reqHeaders, params: newQueryParams };
}

export function hasTokenExpired(token) {
  if (!token) {
    console.log("no token");
    return true;
  }

  try {
    const splitToken = token.split(".")[1];
    var buffer = Buffer.from(splitToken, "base64");
    var decodedString = buffer.toString();
    const decodedToken = JSON.parse(decodedString);
    const now = new Date();
    const tokenExpiration = new Date(decodedToken.exp * 1000 - 5 * 60 * 1000); // 5 minutes before expiration
    return tokenExpiration < now ;
  }
  catch (e) {
    console.log("catch", e);
    return true;
  }
}

// Used for action creation by Loggin
export const requestData = (fetchType, metadata) => ({ type: types.REQ_DATA, fetchType, metadata });
export const receiveData = (data, fetchType, metadata) => ({ type: types.RECV_DATA, payload: data, fetchType, metadata });
export const receiveFinished = (fetchType, metadata) => ({ type: types.RECV_FINISHED, fetchType, metadata });
export const receiveError = (data, fetchType, metadata) => ({ type: types.RECV_ERROR, payload: data, fetchType, metadata });
export const postData = (fetchType) => ({ type: types.POST_DATA, fetchType });
export const clearData = (fetchType) => ({ type: types.CLEAR_DATA, fetchType });
export const success = (fetchType) => ({ type: types.SUCCESS, fetchType });

// Reauthenticate if we recieve a callback with 401
// To prevent multiple 401 responses to start this process
// 1. Check if the 401 was from an old access token
// 2. Check if we're currently acquiring a new access token
let inRefreshTokenFlow = false;
export function sessionTokenExpired(cookiePrefix, oldAccessToken) {

  // Check if already acquired a new access token
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  if (oldAccessToken !== accessToken) {
    console.log("skipping reauthentication because the access token has been replaced already");
    return;
  }

  refreshAccessToken(cookiePrefix);
}

export function refreshAccessToken(cookiePrefix) {

  // Use a different baseUrl for development
  let baseUrl;
  if (process.env.NODE_ENV === "development") {
    baseUrl = "http://localhost:8081";
  }
  else {
    baseUrl = window.location.origin;
  }

  // Use refresh token flow if refresh token exist
  const useRefreshToken = cookies.get(`${cookiePrefix}_use_refresh_token`);
  if (useRefreshToken) {

    // Check if another request has started the refresh token flow
    if (inRefreshTokenFlow) {
      console.log("skipping reauthentication because we're already in the refresh token flow");
      return;
    }
    
    // Start the refresh token flow
    inRefreshTokenFlow = true;
    window.location.href = `${baseUrl}/postauth?from=${window.location.href}`;
  }
  else {
    window.location.href = `${baseUrl}/login?from=${window.location.href}`;
  }
}

export async function updateAccessToken(cookiePrefix) {

  // Refresh token once and wait for the response
  try {
    await new Promise(
      async(resolve, reject) => {
        
        const emitterListenerCount = emitter.listenerCount("get-access-token");
  
        // This event is emitted when a new token is returned from the IDP
        emitter.once("get-access-token", (_, error) => {
          if (error !== undefined) {
            reject(error);
          }
          else {
            resolve();
          }
        });
  
        if (emitterListenerCount === 0) {
          try {
            
            // Use a different baseUrl for development
            let baseUrl;
            if (process.env.NODE_ENV === "development") {
              baseUrl = "http://localhost:8081";
            }
            else {
              baseUrl = window.location.origin;
            }

            await axios.get(`${baseUrl}/postauth`, { params: { silent: true }, withCredentials: true });
        
            emitter.emit("get-access-token");
            emitter.removeAllListeners("get-access-token");
          }
          catch (error) {
            console.log("updateAccessToken error", error);
            emitter.emit("get-access-token", null, error);
            emitter.removeAllListeners("get-access-token");
          }
        }
      }
    );
  }
  catch (err) {
    console.log("Error refreshing access token", err);
    refreshAccessToken(cookiePrefix);
  }
}
  

export function showNoAccess() {
  window.location.href = `${window.location.origin}/noaccess`;
}

// Map editing
export const updateSensors = async (sensors, url) => {

  if (isEmpty(sensors)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();
  const config = await getAxiosConfig();

  return axios
      .all(sensors.map((sensor) => (
        axios.put(`${url}companies/${companyId}/sensors/${sensor.id}`, sensor.body, config)
      )));
};

export const updateGateways = async (gateways, url) => {

  if (isEmpty(gateways)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();
  const config = await getAxiosConfig();

  return axios
      .all(gateways.map((gateway) => (
        axios.put(`${url}companies/${companyId}/gateways/${gateway.id}`, gateway.body, config)
      )));
};

export const createFeatures = async (locationId, features, url) => {

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();

  return axios.post(`${url}companies/${companyId}/locations/${locationId}/map/create-features`, { features }, await getAxiosConfig());
};

export const updateFeatures = async (locationId, features, url) => {

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();

  return axios.post(`${url}companies/${companyId}/locations/${locationId}/map/update-features`, { features }, await getAxiosConfig());
};

export const deleteFeatures = async (locationId, features, url) => {  

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();
  const geoJsonFeatureIds = features.map(feature => feature.id);

  return axios.post(`${url}companies/${companyId}/locations/${locationId}/map/delete-features`, { geoJsonFeatureIds }, await getAxiosConfig());
};

export const createCompanyFeatures = async (features, companyId, url) => {

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  return axios.post(`${url}companies/${companyId}/map/create-features`, { features }, await getAxiosConfig());
};

export const updateCompanyFeatures = async (features, url) => {

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();

  return axios.post(`${url}companies/${companyId}/map/update-features`, { features }, await getAxiosConfig());
};

export const deleteCompanyFeatures = async (features, url) => {

  if (isEmpty(features)) {
    return Promise.resolve("Skip");
  }

  const companyId = getCompanyId();
  const geoJsonFeatureIds = features.map(feature => feature.id);

  return axios.post(`${url}companies/${companyId}/map/delete-features`, { geoJsonFeatureIds }, await getAxiosConfig());
}