import * as types from "../ApiTypes";

const initialState = {
  companies: {},
  locations: {},
  sensors: {},
  atomicSensors: {},
  gateways: {},
  locationTags: {},
  sensorTags: {},
  displays: {},
  screens: {},
  reports: {},
  content: {},
  userGroups: {},
  users: {},
  invites: {},
  customTags: {},
  newGateway: null,
  newSensor: null,
  unregisteredSensors: {},
  mapFeature: null, // Used when editing
  listFeature: null, // Used when editing
  features: [], // Used when viewing,
  selectedFeature: {},
  editedFeatures: [],
  createdFeature: null,
  createdFeatures: [],
  hoveredFeatureLocationId: null,
  configMode: "goto", // Used when navigating/selecting - "goto"/"connect"
  editMode: "goto", // Used when navigating/editing - "goto"/"edit"
  mapMode: "simple_select",
  allTypes: [], // Includes all selected types
};

export default function selectedReducer(state = initialState, action) {

  switch (action.type) {
    case types.SELECT_COMPANY: {
      const newCompanies = { ...state.companies};
      newCompanies[action.payload._id] = action.payload;
      return {...initialState, companies: newCompanies, allTypes: ["company"]};
    }

    case types.DESELECT_COMPANY: {
      const newCompanies = { ...state.companies};
      delete newCompanies[action.payload];
      return {...initialState, companies: newCompanies, allTypes: Object.keys(newCompanies).length > 0 ? ["company"] : [] };
    }

    case types.SELECT_SENSOR: {
      const newSensors = { ...state.sensors};
      newSensors[action.payload.id] = action.payload;
      return {...initialState, sensors: newSensors, allTypes: ["sensor"]};
    }

    case types.DESELECT_SENSOR: {
      const newSensors = { ...state.sensors};
      delete newSensors[action.payload];
      const mapMode = Object.keys(newSensors).length === 1 ? "draw_point" : "simple_select";
      return {...initialState, sensors: newSensors, mapMode, allTypes: Object.keys(newSensors).length > 0 ? ["sensor"] : [] };
    }

    case types.SELECT_SENSORS: {
      const newSensors = { ...state.sensors};
      action.payload.forEach(sensor => {
        newSensors[sensor.id] = sensor;
      });
      return {...initialState, sensors: newSensors, allTypes: ["sensor"]};
    }

    case types.DESELECT_SENSORS: {
      const newSensors = { ...state.sensors};
      action.payload.forEach(sensor => {
        delete newSensors[sensor.id];
      });
      const mapMode = Object.keys(newSensors).length === 1 ? "draw_point" : "simple_select";
      return {...initialState, sensors: newSensors, mapMode, allTypes: Object.keys(newSensors).length > 0 ? ["sensor"] : [] };
    }

    case types.SELECT_ATOMIC_SENSOR: {
      const newAtomicSensors = { ...state.atomicSensors};
      newAtomicSensors[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, atomicSensors: newAtomicSensors, allTypes: ["atomicSensor"]};
    }

    case types.DESELECT_ATOMIC_SENSOR: {
      const newAtomicSensors = { ...state.atomicSensors};
      delete newAtomicSensors[action.payload];
      return {...initialState, atomicSensors: newAtomicSensors, allTypes: Object.keys(newAtomicSensors).length > 0 ? ["atomicSensor"] : [] };
    }

    case types.SELECT_ALL_ATOMIC_SENSORS: {
      const newAtomicSensors = { ...state.atomicSensors};
      action.payload.forEach(sensor => {
        newAtomicSensors[sensor.id || sensor._id] = sensor;
      });
      return {...initialState, atomicSensors: newAtomicSensors, allTypes: ["atomicSensor"]};
    }

    case types.DESELECT_ALL_ATOMIC_SENSORS: {
      return initialState;
    }

    case types.SELECT_GATEWAY: {
      const newGateways = { ...state.gateways};
      newGateways[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, gateways: newGateways, allTypes: ["gateway"]};
    }

    case types.DESELECT_GATEWAY: {
      const newGateways = { ...state.gateways};
      delete newGateways[action.payload];
      return {...initialState, gateways: newGateways, allTypes: Object.keys(newGateways).length > 0 ? ["gateway"] : [] };
    }

    case types.SELECT_GATEWAYS: {
      const newGateways = { ...state.gateways};
      action.payload.forEach(gateway => {
        newGateways[gateway.id || gateway._id] = gateway;
      });
      return {...initialState, gateways: newGateways, allTypes: ["gateway"]};
    }

    case types.DESELECT_GATEWAYS: {
      const newGateways = { ...state.gateways};
      action.payload.forEach(gateway => {
        delete newGateways[gateway.id || gateway._id];
      });
      return {...initialState, gateways: newGateways, allTypes: Object.keys(newGateways).length > 0 ? ["gateway"] : [] };
    }

    case types.SELECT_LOCATION_TAG: {
      const newTags = { ...state.locationTags};
      newTags[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, locationTags: newTags, allTypes: ["locationTag"]};
    }

    case types.DESELECT_LOCATION_TAG: {
      const newTags = { ...state.locationTags};
      delete newTags[action.payload];
      return {...initialState, locationTags: newTags, allTypes: Object.keys(newTags).length > 0 ? ["locationTag"] : [] };
    }

    case types.SELECT_SENSOR_TAG: {
      const newTags = { ...state.sensorTags};
      newTags[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, sensorTags: newTags, allTypes: ["sensorTag"]};
    }

    case types.DESELECT_SENSOR_TAG: {
      const newTags = { ...state.sensorTags};
      delete newTags[action.payload];
      return {...initialState, sensorTags: newTags, allTypes: Object.keys(newTags).length > 0 ? ["sensorTag"] : [] };
    }

    case types.SELECT_LOCATION: {
      const newLocations = { ...state.locations};
      newLocations[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, locations: newLocations, allTypes: ["location"]};
    }
    
    case types.DESELECT_LOCATION: {
      const newLocations = { ...state.locations};
      delete newLocations[action.payload];
      return {...initialState, locations: newLocations, allTypes: Object.keys(newLocations).length > 0 ? ["location"] : [] };
    }

    case types.SELECT_LOCATIONS: {
      const newLocations = { ...state.locations};
      action.payload.forEach(location => {
        newLocations[location.id || location._id] = location;
      });
      return {...initialState, locations: newLocations, allTypes: ["location"]};
    }

    case types.DESELECT_LOCATIONS: {
      const newLocations = { ...state.locations};
      action.payload.forEach(location => {
        delete newLocations[location.id || location._id];
      });
      return {...initialState, locations: newLocations, allTypes: Object.keys(newLocations).length > 0 ? ["location"] : []};
    }
    
    case types.DESELECT_ALL_LOCATIONS: {
      return initialState;
    }

    case types.SELECT_DISPLAY: {
      const newDisplays = { ...state.displays};
      newDisplays[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, displays: newDisplays, allTypes: ["display"]};
    }

    case types.DESELECT_DISPLAY: {
      const newDisplays = { ...state.displays};
      delete newDisplays[action.payload];
      return {...initialState, displays: newDisplays, allTypes: Object.keys(newDisplays).length > 0 ? ["display"] : [] };
    }
    
    case types.SELECT_CONTENT: {
      const newContent = { ...state.content};
      newContent[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, content: newContent, allTypes: ["content"]};
    }

    case types.DESELECT_CONTENT: {
      const newContent = { ...state.content};
      delete newContent[action.payload];
      return {...initialState, content: newContent, allTypes: Object.keys(newContent).length > 0 ? ["content"] : [] };
    }

    case types.SELECT_USER: {
      const newUsers = { ...state.users};
      newUsers[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, users: newUsers, allTypes: ["user"]};
    }

    case types.DESELECT_USER: {
      const newUsers = { ...state.users};
      delete newUsers[action.payload];
      return {...initialState, users: newUsers, allTypes: Object.keys(newUsers).length > 0 ? ["user"] : [] };
    }

    case types.SELECT_INVITE: {
      const newInvites = { ...state.invites};
      newInvites[action.payload.inviteId] = action.payload;
      return {...initialState, invites: newInvites, allTypes: ["invite"]};
    }

    case types.DESELECT_INVITE: {
      const newInvites = { ...state.invites};
      delete newInvites[action.payload];
      return {...initialState, invites: newInvites, allTypes: Object.keys(newInvites).length > 0 ? ["invite"] : [] };
    }

    case types.SELECT_USER_GROUP: {
      const newUserGroups = { ...state.userGroups};
      newUserGroups[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, userGroups: newUserGroups, allTypes: ["userGroup"]};
    }

    case types.DESELECT_USER_GROUP: {
      const newUserGroups = { ...state.userGroups};
      delete newUserGroups[action.payload];
      return {...initialState, userGroups: newUserGroups, allTypes: Object.keys(newUserGroups).length > 0 ? ["userGroup"] : [] };
    }

    case types.SELECT_CUSTOM_TAG: {
      const newCustomTags = { ...state.customTags};
      newCustomTags[action.payload.id || action.payload._id] = action.payload;
      return {...initialState, customTags: newCustomTags, allTypes: ["customTag"]};
    }

    case types.DESELECT_CUSTOM_TAG: {
      const newCustomTags = { ...state.customTags};
      delete newCustomTags[action.payload];
      return {...initialState, customTags: newCustomTags, allTypes: Object.keys(newCustomTags).length > 0 ? ["customTag"] : [] };
    }

    case types.SELECT_UNREGISTERED_SENSORS: {
      const newUnregisteredSensors = { ...state.unregisteredSensors};
      action.payload.forEach(sensor => {
        newUnregisteredSensors[sensor.deviceId] = sensor;
      });
      return {...initialState, unregisteredSensors: newUnregisteredSensors, allTypes: ["unregisteredSensor"]};
    }

    case types.DESELECT_UNREGISTERED_SENSORS: {
      const newUnregisteredSensors = { ...state.unregisteredSensors};
      action.payload.forEach(deviceId => {
        delete newUnregisteredSensors[deviceId];
      });
      return {...initialState, unregisteredSensors: newUnregisteredSensors, allTypes: Object.keys(newUnregisteredSensors).length > 0 ? ["unregisteredSensor"] : [] };
    }

    // Update selected data after saving
    case types.RECV_DATA: {
      if (action.fetchType === types.UPDATE_UNREGISTERED_SENSORS) {

        // Update the devices with the new data
        const newUnregisteredSensors = { ...state.unregisteredSensors};
        const updatedDeviceId = action.payload.device.deviceId;
        newUnregisteredSensors[updatedDeviceId] = { ...newUnregisteredSensors[updatedDeviceId], ...action.payload.draft };

        return { ...state, unregisteredSensors: newUnregisteredSensors };
      }
      
      return state;
    }

    case types.SET_MAP_MODE: {
      return {...state, mapMode: action.payload };
    }

    case types.SELECT_MAP_FEATURE: {
      return {...state, mapFeature: action.payload, mapMode: "simple_select" };
    }
    
    case types.DESELECT_MAP_FEATURE: {
      return {...state, mapFeature: null, mapMode: "simple_select" };
    }

    case types.SELECT_LIST_FEATURE: {
      return {...state, listFeature: action.payload };
    }
    
    case types.DESELECT_LIST_FEATURE: {
      return {...state, listFeature: null };
    }

    case types.SET_SELECTED_FEATURES: {
      console.log("action.payload.features", action.payload.features);
      console.log("action.payload.mapMode", action.payload.mode);
      return {...state, features: action.payload.features, mapMode: action.payload.mode || "simple_select" };
    }

    case types.STORE_EDITED_FEATURES: {

      const editedFeatures = [...state.editedFeatures];

      // Replace the feature if it already exists
      action.payload.forEach(feature => {
        const existingFeature = editedFeatures.findIndex(f => f.id === feature.id);
        if (existingFeature !== -1) {
          editedFeatures[existingFeature] = feature;
        }
        else {
          editedFeatures.push(feature);
        }
      });

      return {...state, editedFeatures };
    }

    case types.REMOVE_EDITED_FEATURES: {
      const editedFeatures = [...state.editedFeatures];
      action.payload.forEach(feature => {
        const existingFeature = editedFeatures.findIndex(f => f.id === feature.id);
        if (existingFeature !== -1) {
          editedFeatures.splice(existingFeature, 1);
        }
      });

      return {...state, editedFeatures };
    }

    case types.CLEAR_EDITED_FEATURES: {
      return {...state, editedFeatures: [] };
    }

    case types.ADD_CREATED_FEATURE: {
      const createdFeatures = [...state.createdFeatures];
      createdFeatures.push(action.payload);
      return {...state, createdFeature: action.payload, createdFeatures, mapMode: "simple_select" };
    }

    case types.SET_HOVERED_FEATURE: {
      return {...state, hoveredFeature: action.payload };
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.UPDATE_FEATURES) {
        return initialState;
      }
      return state;
    }

    case types.SUCCESS: {
      if (action.fetchType === types.UPDATE_FEATURES) {
        return initialState;
      }
      return state;
    }

    case types.CHANGE_CONFIG_MODE: {
      console.log("types.CHANGE_CONFIG_MODE", action.payload)
      return {...state, configMode: action.payload };
    }

    case types.CHANGE_EDIT_MODE: {
      console.log("types.CHANGE_EDIT_MODE", action.payload)
      return {...state, editMode: action.payload };
    }

    case types.DESELECT_ALL: {
      return {...initialState, configMode: state.configMode };
    }

    case types.CLEAR_SELECTION: {
      return initialState;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
