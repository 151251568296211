import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import SmallButton from "../../components/SmallButton";
import Table from "../../components/Table";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Tag from "../../components/Tag";
import OptionFooter from "../../components/OptionFooter";
import SelectionFooterCell from "../../components/SelectionFooterCell";
import CustomTagDetails from "./customTagDetails";
import * as customTagActions from "../../actions/customTags";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class CustomTags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      activeSearch: undefined,
      sortBy: "name",
      sortOrder: "asc"
    };

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onNewCustomTagClicked = this.onNewCustomTagClicked.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
  }

  componentDidMount() {
    this.props.getCustomTags();

    if (isEmpty(this.props.locationId)) {
      this.props.clearCustomTag();
    }
  }

  onSearchClick() {
    this.setState({ activeSearch: this.state.searchText === "" ? undefined : this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/root/custom-tags/${rowInfo.original.id}`);
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo && rowInfo.original.id === this.props.tagId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedCustomTags[row.original.id] !== undefined && this.props.selectedCustomTags[row.original.id]) {
      this.props.deselectCustomTag(row.original.id);
    }
    else {
      this.props.selectCustomTag(row.original);
    }
  }

  onNewCustomTagClicked() {
    this.props.clearCustomTag();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/root/custom-tags/new`);
  }

  onDeselectLocation(id) {
    this.props.deselectLocation(id);
  }

  onSave() {
    const { push } = this.props.history;

    // New custom tag
    if (isEmpty(this.props.customTag)) {
      if (this.props.customTagDraft &&
        this.props.customTagDraft.name &&
        this.props.customTagDraft.name.length > 0) {

          let customTagDraft = { ...this.props.customTagDraft };
          
          const selectedLocationIds = Object.keys(this.props.selectedLocations);
          if (selectedLocationIds.length > 0) {
            customTagDraft.locations = selectedLocationIds;
          }

          this.props.createCustomTag(customTagDraft, push);
      }
    }
    else {
      let updatedTag = { ...this.props.customTag, ...this.props.customTagDraft };
      updatedTag.locations = updatedTag.locations.map(l => l.id);
      var customTagId = updatedTag.id;
      delete updatedTag.id;

      this.props.updateCustomTag(customTagId, updatedTag, push);
    }
  }

  onCancel() {
    this.props.clearCustomTagDraft();
  }

  onSortedChange(newSorted) {
    // console.log("onSortedChange", newSorted);
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  render() {
    // console.log("CustomTags.render()", this.props, this.state);
    const hasSelection = !isEmpty(this.props.selectedLocations) || !isEmpty(this.props.selectedCustomTags);

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: undefined, activeSearch: undefined }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New custom tag" onClick={this.onNewCustomTagClicked} singleLine noMargin />
        ]}
      />
    );

    // Search
    var customTags = this.props.customTags;
    if (this.state.activeSearch) {
      customTags = customTags.filter(tag => tag.name.toLowerCase().includes(this.state.activeSearch.toLowerCase()));
    }
    else {
      customTags = customTags.map(tag => tag);
    }

    // Sort
    if (this.state.sortBy === "name") {
      if (this.state.sortOrder === "asc") {
        customTags = customTags.sort((a,b) => a.name.localeCompare(b.name));
      }
      else {
        customTags = customTags.sort((a,b) => b.name.localeCompare(a.name));
      }
    }
    else {
      if (this.state.sortOrder === "asc") {
        customTags = customTags.sort((a,b) => a.locations.length > b.locations.length ? 1 : -1);
      }
      else {
        customTags = customTags.sort((a,b) => a.locations.length < b.locations.length ? 1 : -1);
      }
    }

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("customTag");

    const tableElement = (
      <Table
        data={customTags}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No custom tags found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedCustomTags[row.original.id] !== undefined && this.props.selectedCustomTags[row.original.id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => <Tag text={row.original.name} color={row.original.colorTheme} />
          },
          {
            header: "# locations",
            accessorKey: "locations",
            cell: ({ row }) => (<span title={row.original.locations.length}>{row.original.locations.length}</span>)
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isLoading}
      />
    );

    const showOptionFooter = !isEmpty(this.props.customTagDraft) && (
      get(this.props.customTagDraft, "name", null) !== get(this.props.customTag, "name", null) ||
      get(this.props.customTagDraft, "description", null) !== get(this.props.customTag, "description", null) ||
      get(this.props.customTagDraft, "colorTheme", null) !== get(this.props.customTag, "colorTheme", null) ||
      get(this.props.customTagDraft, "locations", null) !== get(this.props.customTag, "locations", null));

    //  hasSelection || 
    let tableStyle = (showOptionFooter || hasSelection) ? style.listContainer : style.listContainerHidden;
    // let tableMobileStyle = (showOptionFooter || hasSelection) ? style.listContainerMobile : style.listContainerHiddenMobile;
    
    return (
      <>
        <Hidden xs sm md>
          <div className={tableStyle}>
            <div className={style.row}>
              <div className={style.col50}>
                <div className={style.scroll}>
                  {options}
                  {tableElement}
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.mapPart40}>
                <div className={style.scroll}>
                  { !isEmpty(this.props.tagId) && <CustomTagDetails {...this.props} />}
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={tableStyle}>
            <div className={style.row}>
              <div className={style.col} style={{ backgroundColor: !isEmpty(this.props.tagId) && "#F6F6F6" }}>
                <div className={style.slimScroll}>
                  { isEmpty(this.props.tagId) ? (
                    <>
                      {options}
                      {tableElement}
                      <div style={{ paddingTop: "40px" }} />
                    </>
                  ) : (
                    <CustomTagDetails {...this.props} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Visible>

        {
          showOptionFooter && (
            <OptionFooter 
              cancel={this.onCancel} 
              options={[
                { 
                  label: "Save", 
                  callback: this.onSave, 
                  disabled: this.props.isLoading ||
                    isEmpty(this.props.customTagDraft)
                }
              ]} 
            >
              { 
                this.props.selectedLocations && Object.keys(this.props.selectedLocations).map(selectionId => (
                  <SelectionFooterCell id={selectionId} key={selectionId} text={this.props.selectedLocations[selectionId].name} onClick={() => this.onDeselectLocation(selectionId)} style={{ marginLeft: "15px" }} />
                ))
              }
            </OptionFooter>
          )
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    customTag: state.customTag.data,
    customTagDraft: state.customTag.draft,
    customTags: state.customTags.list,
    isLoading: state.loading.customTags,
    selectedCustomTags: state.selected.customTags,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getCustomTags: customTagActions.getCustomTags,
    createCustomTag: customTagActions.createCustomTag,
    updateCustomTag: customTagActions.updateCustomTag,
    clearCustomTagDraft: customTagActions.clearCustomTagDraft,
    clearCustomTag: customTagActions.clearCustomTag,
    selectCustomTag: selectedActions.selectCustomTag,
    deselectCustomTag: selectedActions.deselectCustomTag,
    clearSelection: selectedActions.clearSelection,
    deselectLocation: selectedActions.deselectLocation,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTags);
