import get from "lodash/get";
import Mapbox from "!mapbox-gl";

export const getMapBounds = (features) => {
  let minLng = 180;
  let maxLng = -180;
  let minLat = 90;
  let maxLat = -90;

  features.forEach(feature => {
    if (feature.geometry.type === "Point") {
      const lng = get(feature, "geometry.coordinates[0]", minLng);
      const lat = get(feature, "geometry.coordinates[1]", minLat);
      if (lng < minLng) {
        minLng = lng;
      }
      if (lng > maxLng) {
        maxLng = lng;
      }
      if (lat < minLat) {
        minLat = lat;
      }
      if (lat > maxLat) {
        maxLat = lat;
      }
    }
    else if (feature.geometry.type === "Polygon") {
      const coordinates = get(feature, "geometry.coordinates[0]", []);
      if (Array.isArray(coordinates)) {
        coordinates.forEach(coordinate => {
          const lng = get(coordinate, "[0]", minLng);
          const lat = get(coordinate, "[1]", minLat);
          if (lng < minLng) {
            minLng = lng;
          }
          if (lng > maxLng) {
            maxLng = lng;
          }
          if (lat < minLat) {
            minLat = lat;
          }
          if (lat > maxLat) {
            maxLat = lat;
          }
        });
      }
    }
    else if (feature.geometry.type === "MultiPolygon") {
      const polygons = get(feature, "geometry.coordinates", []);
      if (Array.isArray(polygons)) {
        polygons.forEach(polygon => {
          const coordinates = get(polygon, "[0]", []);
          if (Array.isArray(coordinates)) {
            coordinates.forEach(coordinate => {
              const lng = get(coordinate, "[0]", minLng);
              const lat = get(coordinate, "[1]", minLat);
              if (lng < minLng) {
                minLng = lng;
              }
              if (lng > maxLng) {
                maxLng = lng;
              }
              if (lat < minLat) {
                minLat = lat;
              }
              if (lat > maxLat) {
                maxLat = lat;
              }
            });
          }
        });
      }
    }
  });
  
  const sw = new Mapbox.LngLat(minLng, minLat);
  const ne = new Mapbox.LngLat(maxLng, maxLat);
  const llb = new Mapbox.LngLatBounds(sw, ne);

  return llb;
};

export const getInitialCenter = (features) => {
  const llb = getMapBounds(features);
  return llb.getCenter();
};

export const getBounds = (coordinates) => {
  let minLng = 180;
  let maxLng = -180;
  let minLat = 90;
  let maxLat = -90;
  coordinates.forEach(coordinate => {
    const [lng, lat] = coordinate;
    if (lng < minLng) {
      minLng = lng;
    }
    if (lng > maxLng) {
      maxLng = lng;
    }
    if (lat < minLat) {
      minLat = lat;
    }
    if (lat > maxLat) {
      maxLat = lat;
    }
  });

  const sw = new Mapbox.LngLat(minLng, minLat);
  const ne = new Mapbox.LngLat(maxLng, maxLat);
  const llb = new Mapbox.LngLatBounds(sw,ne);

  return llb;
};

export const getBoundsWithMargin = (llb, margin) => {
  const ne = llb.getNorthEast();
  if (ne.lng > 0) {
    ne.lng += margin;
  }
  else {
    ne.lng -= margin;
  }

  if (ne.lat > 0) {
    ne.lat += margin;
  }
  else {
    ne.lat -= margin;
  }
  llb.setNorthEast(ne);

  const sw = llb.getSouthWest();
  if (sw.lng > 0) {
    sw.lng -= margin;
  }
  else {
    sw.lng += margin;
  }

  if (sw.lat > 0) {
    sw.lat -= margin;
  }
  else {
    sw.lat += margin;
  }
  llb.setSouthWest(sw);

  return llb;
};

export const MapColors = {
  zone: "#F2ECE6",
  room: "#E0DDD5",
  floor: "#CCC8C2",
};

export const StateColors = {
  disabled: "rgb(185, 185, 185)",   // grey
  unknown: "rgb(250, 214, 72)",     // yellow rgb(245, 227, 85)
  connected: "rgb(119, 207, 58)",   // green  rgb(75, 204, 58)
  disconnected: "rgb(209, 86, 86)"  // red    rgb(227, 64, 64)
};