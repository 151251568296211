import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import Loader from "../../../components/Loader";
import WarningsSummaryList from "./warningsSummaryList";
import WarningsSummaryDetails from "./warningsSummaryDetails";
import Modal from "./modal";
import WarningView from "./warningView";
import * as alarmActions from "../../../actions/alarms";
import style from "./style.module.scss";

class AlarmsContainer extends Component {

  clearSelectedWarningId = () => {
    this.props.history.push(`/support/overview/${this.props.companyId}/alarms`);
  }

  render() {
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    // Get warningId from URL
    const { warningId } = this.props.match.params;

    return (
      <>
        <Hidden xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <WarningsSummaryList { ...this.props} />
                </div>
                <Modal
                  isOpen={warningId}
                  onClose={this.clearSelectedWarningId}
                  title="Alarm">
                    <WarningView key={this.props.warningId} { ...this.props} onClose={this.clearSelectedWarningId} />
                </Modal>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <WarningsSummaryDetails key={this.props.companyId} { ...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.mainContainerHidden} style={{ backgroundColor: !isEmpty(this.props.companyId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.companyId) ? (<WarningsSummaryList { ...this.props} />) : (<WarningsSummaryDetails key={this.props.companyId} { ...this.props} onSelectedWarningId={this.onSelectedWarningId} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(alarmActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsContainer);