import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Freetext from "../../components/Freetext";
import InputBox from "../../components/InputBox";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import Loader from "../../components/Loader";
import SmallButton from "../../components/SmallButton";
import { NewControlledDropdownSelection } from "../../components/DropdownSelection";
import * as screenActions from "../../actions/screens";
import * as screenViewActions from "../../actions/screenViews";
import style from "./style.module.scss";

class ScreenGroupDeviceDetails extends Component {

  constructor(props) {
    super(props);
    this.onEnableDevice = this.onEnableDevice.bind(this);
    this.onDisableDevice = this.onDisableDevice.bind(this);
    this.onDeleteDevice = this.onDeleteDevice.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.deviceId) {
      if (this.props.device.id !== this.props.match.params.deviceId) {
        this.props.getScreenDevice(this.props.match.params.screenGroupId, this.props.match.params.deviceId);
      }
    }

    if (isEmpty(this.props.screen.views)) {
      this.props.getScreenViews(this.props.screenGroupId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.deviceId) {
      if (this.props.device.id !== this.props.match.params.deviceId) {
        this.props.getScreenDevice(this.props.match.params.screenGroupId, this.props.match.params.deviceId);
      }
    }
  }

  onEnableDevice() {
    this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { approvalState: "approved" });
  }

  onDisableDevice() {
    this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { approvalState: "disabled" });
  }

  onDeleteDevice() {
    this.props.deleteScreenDevice(this.props.screenGroupId, this.props.device.id);
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (isEmpty(this.props.match.params.deviceId) || isEmpty(this.props.device.authCode)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader />;
    }

    if (isEmpty(this.props.device.id)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/devices/${this.props.device.id}/details`, label: `Details` });

    const title = this.props.device.name ?? `Device ${this.props.device.authCode}`;

    let customButtonText = "";
    let customButtonColor = "";
    let onCustomButtonClicked = null;
    if (this.props.device.approvalState === "approved") {
      customButtonText = "Disable";
      customButtonColor = "white-red";
      onCustomButtonClicked = this.onDisableDevice;
    }
    else if (this.props.device.approvalState === "disabled") {
      customButtonText = "Enable";
      customButtonColor = "white";
      onCustomButtonClicked = this.onEnableDevice;
    }
    else {
      customButtonText = "Approve";
      customButtonColor = "blue"
      onCustomButtonClicked = this.onEnableDevice;
    }
    
    // Build the drop down options (view ids with view names)
    const viewOptions = this.props.views.map(view => {
      return { id: view.id, name: view.name };
    });

    // Add "None" option
    viewOptions.unshift({ id: "", name: "None" });

    let connectionElement = null;
    if (!this.props.device.connectedAt) {
      connectionElement = <InputBox label="Last online at" value={"-"} size="medium" readOnly />;
    }
    else if (this.props.device.isOnline) {
      const connectedAt = moment(this.props.device.connectedAt);
      connectionElement = <InputBox label="Online since" value={connectedAt.format("Do MMMM YYYY, HH:mm:ss")} size="medium" readOnly />;
    }
    else {
      const connectedAt = moment(this.props.device.connectedAt);
      const disconnectedAt = moment(this.props.device.disconnectedAt);
      let formattedString = "";
      if (connectedAt.isSame(disconnectedAt, "day")) {
        formattedString = `${connectedAt.format("D. MMMM YYYY, HH:mm:ss")} - ${disconnectedAt.format("HH:mm:ss")}`;
      }
      else {
        formattedString = `${connectedAt.format("D. MMMM YYYY, HH:mm:ss")} - ${disconnectedAt.format("D. MMMM YYYY, HH:mm:ss")}`;
      }
      connectionElement = <InputBox label="Last online at" value={formattedString} size="medium" readOnly />;
    }

    let restartButton = null;
    if (this.props.auth.hasITAdminRole && this.props.device.isOnline) {
      restartButton =(
        <div style={{ marginTop: "20px" }}>
          <SmallButton text="Reload device" color="red" onClick={() => this.props.forceScreenRestart(this.props.screenGroupId, this.props.device.id)} noMargin />
        </div>
      );
    }

    return (
      <div>
        <DetailsHeader
          title={title}
          customButtonText={customButtonText}
          customButtonColor={customButtonColor}
          canUseCustomButton
          canDelete={true}
          isLoading={this.props.isLoading}
          onCustomButtonClicked={onCustomButtonClicked}
          onDeleteClicked={this.onDeleteDevice}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <NewControlledDropdownSelection
          label="Start view"
          value={this.props.device.defaultViewId ?? ""}
          options={viewOptions}
          onChange={(event) => this.props.updateScreenDevice(this.props.screenGroupId, this.props.device.id, { defaultViewId: event.target.value })}
          style={{ marginTop: "20px" }}
        />
        { connectionElement }
        <InputBox label="Bundle version" value={this.props.device.bundleVersion ?? "-"} size="medium" readOnly />
        { restartButton }

        <div style={{ marginTop: "20px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.screen.device,
    views: state.screen.views,
    isLoading: state.loading.screen
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenDevice: screenActions.getScreenDevice,
    updateScreenDevice: screenActions.updateScreenDevice,
    deleteScreenDevice: screenActions.deleteScreenDevice,
    forceScreenRestart: screenActions.forceScreenRestart,
    getScreenViews: screenViewActions.getScreenViews,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupDeviceDetails);