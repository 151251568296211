import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../../NotFound";
import DetailsHeader from "../../../components/DetailsHeader";
import SubNavigation from "../../../components/SubNavigation";
import CompanySettings from "./companySettings";

class CompanyDetails extends Component {

  render() {
    const { isLoading } = this.props;

    if (isEmpty(this.props.companyId)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/support/settings/${this.props.companyId}/details`, label: `Details` });
    // subNavigationLinks.push({ pathname: `/support/overview/${this.props.companyId}/graph`, label: `Graph` });
    
    let title = this.props.company?.name;

    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={isLoading}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/support/settings/${this.props.companyId}`} to={{ pathname: `/support/settings/${this.props.companyId}/details` }} />
          <Route path="/support/settings/:companyId/details" children={(props) => <CompanySettings key={this.props.companyId} {...props} companyId={this.props.companyId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.sudo.company
  };
}

export default connect(mapStateToProps, null)(CompanyDetails);