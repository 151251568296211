import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import isEmpty from "lodash/isEmpty";
import CompanyList from "./companyList";
import CompanyDetails from "./companyDetails";
import style from "./style.module.scss";

class CompaniesContainer extends Component {
  render() {
    return (
      <>
        <Hidden xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <CompanyList { ...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <CompanyDetails key={this.props.companyId} { ...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.mainContainerHidden} style={{ backgroundColor: !isEmpty(this.props.companyId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.companyId) ? (<CompanyList { ...this.props} />) : (<CompanyDetails key={this.props.companyId} { ...this.props} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

export default CompaniesContainer;