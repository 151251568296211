import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import moment from "moment";
import InputModal from "../../components/InputModal";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import SegmentedControl from "../../components/SegmentedControl";
import * as screenActions from "../../actions/screens";

class Screens extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      offset: 0,
      limit: 100,
      newScreenName: "",
      newScreenIsPrivate: true,
      showNewScreenModal: false
    };
    this.onNewScreenClick = this.onNewScreenClick.bind(this);
    this.onNewScreenNameChanged = this.onNewScreenNameChanged.bind(this);
    this.onNewScreen = this.onNewScreen.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onNewScreenClick() {
    this.setState({ showNewScreenModal: true });
  }

  onNewScreenNameChanged(event) {
    this.setState({ newScreenName: event.target.value });
  }

  onNewScreen() {
    const push = this.props.history.push;
    this.props.createScreenGroup({ name: this.state.newScreenName, isPrivate: this.state.newScreenIsPrivate }, push); // , allowInteractions: false, resetTimer: { timeout: 0, reloadDefaultView: false }, language: "en"
    this.setState({ showNewScreenModal: false, newScreenName: "", newScreenIsPrivate: true });
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    this.setState({ searchText: value === '' ? undefined : value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log("onRowClick", e, rowInfo, column, instance);
        if (column.name !== 'isSelected' && rowInfo !== undefined) {

          const link = `/companies/${this.props.match.params.companyId}/screens/${rowInfo.original.id}/details`;
          this.props.resetScreenGroupForm();

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.props.screenGroupId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getScreenGroups(queryParams);
    this.props.getWaitingScreenDevices();
  }

  render() {
    // console.log("Screens.render", this.state);

    const hasAccess = this.props.auth.hasITAdminRole;

    const description = `A "screen" is a website that connect to our servers with a websocket and streams live data to a multitude of components. 
    A screen can be public or private, where public screens are accessible by anyone with the link and private screens require approval per device. 
    Each screen can have multiple views - these define which component is shown on the screen and how they are arranged.`;

    const options = (
      <TopRowOptions
        description={description}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          hasAccess && <SmallButton key="b1" text="New screen" onClick={this.onNewScreenClick} singleLine noMargin />
        ]}
      />
    );

    const configTable = getConfigTable(this.props.data, this.state.sortBy, this.state.sortOrder, this.state.offset, this.state.limit, this.onSortedChange, this.onOffsetChange, this.onRowClick, this.props.isLoading);

    return (
      <>
        { options }
        { configTable }
        <div style={{ paddingTop: "40px" }} />

        <InputModal
          show={this.state.showNewScreenModal}
          onHide={() => this.setState({ showNewScreenModal: false })}
          title="New screen"
          // text=""
          inputLabel="Name"
          inputValid={this.state.newScreenName}
          inputValue={this.state.newScreenName}
          onInputChange={this.onNewScreenNameChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.onNewScreen
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNewScreenModal: false })
          }}
        >
          <Row style={{ marginTop: "20px" }}>
            <Col sm={12} md={12} lg={12}>
              <SegmentedControl
                label="Security level"
                options={[
                  { label: "Public", value: false },
                  { label: "Private", value: true }
                ]}
                value={this.state.newScreenIsPrivate}
                onChange={value => this.setState({ newScreenIsPrivate: value })}
              />
            </Col>
          </Row>
        </InputModal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.screens.data,
    isLoading: state.loading.screens,
    selectedScreens: state.selected.screens,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getScreenGroups: screenActions.getScreenGroups,
    createScreenGroup: screenActions.createScreenGroup,
    resetScreenGroupForm: screenActions.resetScreenGroupForm,
    getWaitingScreenDevices: screenActions.getWaitingScreenDevices
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Screens);

function getConfigTable(data, sortBy, sortOrder, offset, limit, onSortedChange, onOffsetChange, onRowClick, isLoading) {
  return (
    <Table
      data={data.results}
      sortBy={sortBy}
      sortOrder={sortOrder}
      offset={offset}
      limit={limit}
      count={data.count}
      onSortedChange={onSortedChange}
      onOffsetChange={onOffsetChange}
      noDataText={isLoading ? "" : "No screens found"}
      columns={[
        {
          header: "Created at",
          accessorKey: "createdAt",
          defaultSortDesc: true,
          width: 140,
          cell: ({ row }) => 
          (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
            {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
          </span>)
        },
        {
          header: "Name",
          accessorKey: "name",
          cell: ({ row }) => (<span>{row.original.name}</span>)
        },
        {
          header: "Live connections",
          width: 200,
          sortable: false,
          cell: ({ row }) => {

            let onlineConnections = 0;
            if (row.original.isPrivate) {
              onlineConnections = row.original.onlineDevicesCount;
            }
            else {
              onlineConnections = row.original.onlineLinksCount;
            }

            return (<span>{onlineConnections}</span>);
          }
        },
        {
          header: "Pending devices",
          accessorKey: "waitingDevicesCount",
          width: 200,
          sortable: false,
          cell: ({ row }) => {
            return (<span>{row.original.isPrivate ? row.original.waitingDevicesCount : "-"}</span>);
          }
        },
        {
          id: "arrow",
          header: "",
          accessorKey: 'properties',
          sortable: false,
          className: "pull-right",
          width: 60,
          cell: () => (<div className="arrow" />)
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />
  );
}