import React, { Component } from "react";
import PropTypes from "prop-types";
import ListCard from "../../../components/ListCard";
import Table from "../../../components/Table";
import SearchBox from "../../../components/SearchBox";

class HomeSideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.onBuildingClick = this.onBuildingClick.bind(this);
  }

  static propTypes = {
    regions: PropTypes.array.isRequired,
    selectedLocationId: PropTypes.string,
    onLocationClick: PropTypes.func.isRequired,
  };

  onBuildingClick(column, row) {
    return {
      onClick: e => {
        if (row) {
          this.props.onLocationClick(row.original.id);
        }
      },
      style: {
        cursor: "pointer",
        background: (
          row && row.original.id === this.props.selectedLocationId ? "#e1e1e1" : null
        )
      }
    }
  }

  render() {
    // console.log("BuildingSideBar.render()", this.props);

    const root = (
      <div key="root" style={{ marginBottom: "20px" }}>
        <ListCard>
          <Table
            data={[{ id: "root", name: "All regions" }]}
            noDataText={""}
            columns={[
              {
                accessorKey: "name",
                style: { paddingLeft: "20px" },
                cell: ({ row }) => {
                  return <span>{row.original.name}</span>
                }
              }
            ]}
            getTdProps={this.onBuildingClick}
            hideHeaders
            className="-row-clickable -minimalist -no-header -highlight"
          />
        </ListCard>
      </div>
    );

    const regions = this.props.regions.length > 0 && this.props.regions.map(region => {
      return (
        <div key={region.id} style={{ marginBottom: "20px" }}>
          <h3>{region.name}</h3>
          <ListCard>
            <Table
              data={region.children}
              noDataText={""}
              columns={[
                {
                  accessorKey: "name",
                  style: { paddingLeft: "20px" },
                  cell: ({ row }) => {
                    return <span>{row.original.name}</span>
                  }
                }
              ]}
              getTdProps={this.onBuildingClick}
              hideHeaders
              className="-row-clickable -minimalist -no-header -highlight"
            />
          </ListCard>
        </div>
      );
    });

    const searchBox = (
      <div style={{ marginBottom: "30px" }}>
        {/* <h3>Search</h3> */}
        <SearchBox
          placeholder="What are you looking for?"
          value={this.state.searchText}
          onSearchChanged={(value) => this.setState({ searchText: value })}
          onSearchClick={() => this.props.onSearchClick(this.state.searchText)}
        />
      </div>
    );

    return (
      <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        {/* { searchBox } */}
        {/* { root } */}
        { regions }
      </div>
    );
  }
}

export default HomeSideBar;