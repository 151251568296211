import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { RoleName, getRoleName, getRoleStringOptions } from "../../helpers";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import * as userGroupsActions from "../../actions/userGroups"

class GroupSettings extends Component {

  constructor(props) {
    super(props);

    this.privilegesChanged = this.privilegesChanged.bind(this);
    this.appAccessChanged = this.appAccessChanged.bind(this);
    this.wpaAccessChanged = this.wpaAccessChanged.bind(this);
    this.energyAccessChanged = this.energyAccessChanged.bind(this);
    this.noShowAccessChanged = this.noShowAccessChanged.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.userGroup.name)) {
      document.title = `BLDNG.ai - ${this.props.userGroup.name} - settings`;
    }
  }

  privilegesChanged(role) {
    var draft = { ...this.props.userGroup.draft, roles: role === "" ? [] : [role] };

    // Check if there is no change and return a blank draft
    let oldRoles = [...this.props.userGroup.roles];
    if (draft.roles.length === 0 && oldRoles.length === 0) {
      delete draft.roles;
    }
    else if (draft.roles.length === oldRoles.length) {
      var changed = false;
      draft.roles.forEach(newRole => {
        if (!oldRoles.includes(newRole)) {
          changed = true;
        }
      });

      if (!changed) {
        delete draft.roles;
      }
    }

    this.props.draftUserGroupChange(draft);
  }

  appAccessChanged(event) {
    var draft = { ...this.props.userGroup.draft, enableApp: event.target.checked };

    // Check if there is no change and return a blank draft
    if (draft.enableApp === this.props.userGroup.enableApp) {
      delete draft.enableApp;
    }

    this.props.draftUserGroupChange(draft);
  }

  wpaAccessChanged(event) {
    var draft = { ...this.props.userGroup.draft, enableWPAReportAccess: event.target.checked };

    // Check if there is no change and return a blank draft
    if (draft.enableWPAReportAccess === this.props.userGroup.enableWPAReportAccess) {
      delete draft.enableWPAReportAccess;
    }

    this.props.draftUserGroupChange(draft);
  }

  energyAccessChanged(event) {
    var draft = { ...this.props.userGroup.draft, enableEnergyReportAccess: event.target.checked };

    // Check if there is no change and return a blank draft
    if (draft.enableEnergyReportAccess === this.props.userGroup.enableEnergyReportAccess) {
      delete draft.enableEnergyReportAccess;
    }

    this.props.draftUserGroupChange(draft);
  }

  noShowAccessChanged(event) {
    var draft = { ...this.props.userGroup.draft, enableNoShowReportAccess: event.target.checked };

    // Check if there is no change and return a blank draft
    if (draft.enableNoShowReportAccess === this.props.userGroup.enableNoShowReportAccess) {
      delete draft.enableNoShowReportAccess;
    }

    this.props.draftUserGroupChange(draft);
  }

  render() {
    
    if (isEmpty(this.props.groupId)) {
      return null;
    }

    const canUpdateGroupRole = this.props.auth.hasITAdminRole;
    const isSystemGroup = this.props.userGroup.isEveryoneGroup;

    let roleOptions = getRoleStringOptions();

    // Remove support and installer roles since we don't need to assign them to groups
    roleOptions = roleOptions.filter((role) => role.id !== RoleName.ITSupport && role.id !== RoleName.Support);

    // console.log("GroupSettings.render()", this.props);

    const role = get(this.props.userGroup.draft, "roles[0]", get(this.props.userGroup, "roles[0]", RoleName.User));
    const hasApp = get(this.props.userGroup.draft, "enableApp", get(this.props.userGroup, "enableApp", false));
    const hasWPA = get(this.props.userGroup.draft, "enableWPAReportAccess", get(this.props.userGroup, "enableWPAReportAccess", false));
    const hasEnergy = get(this.props.userGroup.draft, "enableEnergyReportAccess", get(this.props.userGroup, "enableEnergyReportAccess", false));
    const hasNoShow = get(this.props.userGroup.draft, "enableNoShowReportAccess", get(this.props.userGroup, "enableNoShowReportAccess", false));

    let roleElement = null;
    if (!isSystemGroup) {
      if (this.props.auth.hasITAdminRole) {
        roleElement = (
          <>
            <div style={{ marginTop: "25px" }} />
            <p>Groups can automatically give access to users in the group - this is useful to let Entra ID control administration access.</p>
            <ControlledDropdownSelection
              name="role"
              value={role}
              label="Access level"
              options={roleOptions}
              onChange={(event) => this.privilegesChanged(event.target.value)}
              disabled={!canUpdateGroupRole}
              style={{ marginTop: "0px" }}
            />
          </>
        );
      }
      else {
        roleElement = (
          <>
            <div style={{ marginTop: "25px" }} />
            <p>Groups can automatically give access to users in the group - this is useful to let Entra ID control administration access.</p>
            <InputBox label="Access level" value={getRoleName(role)} readOnly style={{ maxWidth: "110px", paddingTop: 0 }} />
          </>
        );
      }
    }

    return (
      <>
        { roleElement }
        <div style={{ marginTop: "25px" }} />
        <p>Normal users require spesific privileges to view potensial sensitive data (administrators have access to everything). The checked features will be given to all users in this group.</p>
        <div style={{ marginTop: "25px" }}>
          <Checkbox label="App" description="(can log in to the app)" isChecked={hasApp} onClick={this.appAccessChanged} disabled={!canUpdateGroupRole} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Checkbox label="WPA report" description="(can view the report on web)" isChecked={hasWPA} onClick={this.wpaAccessChanged} disabled={!canUpdateGroupRole} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Checkbox label="Energy report" description="(can view the report on web)" isChecked={hasEnergy} onClick={this.energyAccessChanged} disabled={!canUpdateGroupRole} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Checkbox label="No-show report" description="(can view the report on web)" isChecked={hasNoShow} onClick={this.noShowAccessChanged} disabled={!canUpdateGroupRole} />
        </div>
        <div style={{ marginTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: state.userGroup,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    draftUserGroupChange: userGroupsActions.draftUserGroupChange
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettings);
