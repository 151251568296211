import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import { faPlug, faShareAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { get, isEmpty } from "lodash";
import { RowIcon, BLUE, GREEN, GREY, RED, ORANGE } from "../../components/RowIcon";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import SmallButton from "../../components/SmallButton";
import DropdownModal from "../../components/DropdownModal";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Sensors extends Component {

  constructor(props) {
    // console.log("Sensors.constructor", props);
    super(props);
    this.state = {
      id: props.locationId,
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      showSelectSensorType: false,
      deviceTypes: [],
      deviceType: null,
      hasLoadedState: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onNewSensor = this.onNewSensor.bind(this);
    this.onSelectedDeviceType = this.onSelectedDeviceType.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onExportSensors = this.onExportSensors.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.locationId === nextProps.location.id) {

      const newState = {
        hasLoadedState: true
      };

      newState.deviceTypes = getDeviceTypes(nextProps);

      if (newState.deviceTypes.length > 0) {
        newState.deviceType = newState.deviceTypes[0].id;
      }
      
      return newState;
    }

    return null;
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    this.setState({ searchText: value.replace("EUI64+", "EUI64-") });
  }

  onRowClick(column, rowInfo) {
    let sensor = null;
    if (this.props.hoveredFeature && this.props.hoveredFeature.properties && this.props.hoveredFeature.properties.sensor) {
      sensor = JSON.parse(this.props.hoveredFeature.properties.sensor);
    }

    return {
      onClick: e => {
        if (column.name !== "isSelected" && rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/sensors/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        // Clear any pending leave timeout
        if (this.leaveTimeout) {
          clearTimeout(this.leaveTimeout);
          this.leaveTimeout = null;
        }
        if (rowInfo?.original?.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => {
        // Delay the clearing of hover state
        this.leaveTimeout = setTimeout(() => {
          this.onHover(null);
        }, 100);
      },
      style: {
        cursor: "pointer",
        background: (rowInfo && sensor && rowInfo.original.id === sensor._id ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onHover(id) {
    this.props.setHighlightedFeature(id);
  }

  onNewSensor() {
    this.setState({ showSelectSensorType: true });
  }

  onSelectedDeviceType() {
    let deviceType = this.state.deviceType;
    switch (deviceType) {
      case "hono-sensor":
        this.props.history.push({
          pathname: `/companies/${this.props.match.params.companyId}/locations/${this.state.id}/new-hono-sensor/`,
          state: { canGoBack: true }
        });
        break;

      case "yanzi-sensor":
        this.props.history.push({
          pathname: `/companies/${this.props.match.params.companyId}/locations/${this.state.id}/new-yanzi-sensor/`,
          state: { canGoBack: true }
        });
        break;

      case "yanzi-meshnode":
        this.props.history.push({
          pathname: `/companies/${this.props.match.params.companyId}/locations/${this.state.id}/new-mesh-node/`,
          state: { canGoBack: true }
        });
        break;

      case "webex":
        this.props.history.push({
          pathname: `/companies/${this.props.match.params.companyId}/locations/${this.state.id}/new-webex-sensor/`,
          state: { canGoBack: true }
        });
        break;

      case "webex-manual":
        this.props.history.push({
          pathname: `/companies/${this.props.match.params.companyId}/locations/${this.state.id}/new-webex-sensor-manual/`,
          state: { canGoBack: true }
        });
        break;

      default:
        break;
    }
  }

  onExportSensors() {
    this.props.downloadSensorsCSV();
  }

  onToggle(row) {
    if (this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id]) {
      this.props.deselectSensor(row.original.id);
    }
    else {
      this.props.selectSensor(row.original);
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };
    
    if (queryParams.sortBy === "properties.metadata.connectionState") {
      queryParams.sortBy = "status";
    }

    const sensorsQueryHash = `${this.state.id}-${JSON.stringify(queryParams)}`;
    if (this.props.sensors.sensorsQueryHash !== sensorsQueryHash) {
      this.props.getSensors(this.state.id, queryParams);
    }
  }

  render() {
    // console.log("---- Sensors.render()", this.props);

    const isLoading = this.props.isLocationLoading || this.props.isSensorsLoading || this.props.isGatewaysLoading;
    const canCreateSensor = this.props.auth.hasSupportRole;
    
    const hasSelection = !isEmpty(this.props.selectedLocations) || 
      !isEmpty(this.props.selectedSensors) || 
      !isEmpty(this.props.selectedGateways) || 
      !isEmpty(this.props.selectedContent) || 
      !isEmpty(this.props.selectedUserGroups);

    const hasGateway = this.props.locationGateways.length >= 1 || this.props.closestAncestorGateways.length === 1 || Object.keys(this.props.selectedGateways).length === 1;
    const heading = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New sensor" onClick={this.onNewSensor} disabled={!canCreateSensor} hoverText={!hasGateway ? "You need to add a gateway here or select a gateway from another location" : ""} singleLine noMargin />
        ]}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("sensor");

    const tableElement = (
      <Table
        data={this.props.sensors.data.sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.sensors.data.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No sensors found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "ID",
            accessorKey: "properties.metadata.vendorInfo.sensorId",
            sortable: false,
          },

          {
            id: "onlyAtomicSensorConnected",
            header: "",
            accessorKey: "properties.metadata.onlyAtomicSensorConnected",
            width: 40,
            cell: ({ row }) => {
              const { onlyAtomicSensorConnected } = row.original.properties.metadata;
              if (onlyAtomicSensorConnected) {
                return <RowIcon tooltip="from other" bgColor={BLUE} icon={faShareAlt} />
              }

              return null;
              // return <RowIcon tooltip="from this" bgColor={BLUE} icon={faShareAlt} />
            }
          },
          {
            id: "status",
            header: "",
            accessorKey: "properties.metadata.connectionState",
            width: 40,
            cell: ({ row }) => {
              const { connectionState, connectionStateUpdatedAt } = row.original.properties.metadata;
              if (connectionState === "connected") {
                return <RowIcon tooltip="connected" bgColor={GREEN} icon={faPlug} />
              }

              if (connectionState === "disconnected") {
                return <RowIcon tooltip="disconnected" bgColor={RED} icon={faPlug} />
              }

              return <RowIcon tooltip="unknown" bgColor={GREY} icon={faPlug} />
            }
          },
          {
            id: "geoJsonFeature",
            header: "",
            accessorKey: "properties.metadata.geoJsonFeature",
            width: 40,
            cell: ({ row }) => {
              const { geoJsonFeature } = row.original.properties.metadata;
              if (!isEmpty(geoJsonFeature)) {
                return <RowIcon tooltip="in map" bgColor={BLUE} icon={faMapMarkerAlt} />
              }

              return <RowIcon tooltip="not in map" bgColor={GREY} icon={faMapMarkerAlt} />
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isSensorsLoading}
      />
    );

    const slimTableElement = (
      <Table
        data={this.props.sensors.data.sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.sensors.data.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No sensors found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Status",
            accessorKey: "properties.metadata.connectionState",
            width: 80,
            cell: ({ row }) => {
              const { connectionState, connectionStateUpdatedAt } = row.original.properties.metadata;
              if (connectionState === "connected") {
                return <RowIcon tooltip="connected" bgColor={GREEN} icon={faPlug} />
              }

              if (connectionState === "disconnected") {
                return <RowIcon tooltip="disconnected" bgColor={RED} icon={faPlug} />
              }

              return <RowIcon tooltip="unknown" bgColor={GREY} icon={faPlug} />
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isSensorsLoading}
      />
    );

    const disableClickStyle = this.state.showSelectSensorType ? { pointerEvents: "none" } : {};

    return (
      <>
        <Hidden xs sm md>
          <div className={style.scroll} style={disableClickStyle}>
            {heading}
            {tableElement}
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll} style={disableClickStyle}>
            {heading}
            {slimTableElement}
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Visible>

        <DropdownModal
          show={this.state.showSelectSensorType}
          onHide={() => this.setState({ showSelectSensorType: false })}
          title="Sensor type"
          selectionName="deviceType"
          selectionValue={this.state.deviceType}
          selectionOptions={this.state.deviceTypes}
          onSelectionChange={(selection) => { this.setState({ deviceType: selection.target.value }); }}
          primaryBtn={{
            text: "Add",
            onClick: this.onSelectedDeviceType
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: () => this.setState({ showSelectSensorType: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    locationGateways: state.location.gateways,
    isLocationLoading: state.loading.location,
    closestAncestorGateways: state.location.closestAncestorGateways,
    sensors: state.sensors,
    location: state.location,
    isSensorsLoading: state.loading.sensors,
    isGatewaysLoading: state.loading.gateways,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedUserGroups: state.selected.userGroups,
    selectedContent: state.selected.content,
    selectedTypes: state.selected.allTypes,
    hoveredFeature: state.selected.hoveredFeature,
    companyMap: state.auth.map
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSensors: sensorActions.getSensors,
    selectSensor: selectedActions.selectSensor,
    deselectSensor: selectedActions.deselectSensor,
    clearSelection: selectedActions.clearSelection,
    downloadSensorsCSV: sensorActions.downloadSensorsCSV
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);

function getDeviceTypes(props) {
  let deviceTypes = [];

  // Check what kind of gateway is selected
  let hasYanziGateway = false;
  let hasHonoGateway = false;
  if (Object.keys(props.selectedGateways).length === 1) {
    let firstKey = Object.keys(props.selectedGateways)[0];
    let gateway = props.selectedGateways[firstKey];
    if (isEmpty(gateway.hono)) {
      hasYanziGateway = true;
    }
    else {
      hasHonoGateway = true;
    }
  }
  else {
    // Allow hono without gateway
    hasHonoGateway = true;

    // Allow yanzi if there are any gateways without hono
    let hasYanziGatewayInLocation = props.locationGateways.some(gateway => isEmpty(gateway.hono));
    let hasYanziGatewayInAncestors = props.closestAncestorGateways.some(gateway => isEmpty(gateway.hono));
    hasYanziGateway = hasYanziGatewayInLocation || hasYanziGatewayInAncestors;
  }

  if (hasHonoGateway) {
    deviceTypes.push({ id: "hono-sensor", name: "IoT sensor" });
  }

  if (hasYanziGateway && get(props.selectedCompany, "hasYanziIntegration", false)) {
    deviceTypes.push({ id: "yanzi-sensor", name: "Yanzi Sensor" });
    deviceTypes.push({ id: "yanzi-meshnode", name: "Yanzi Mesh node" });
  }

  deviceTypes.push({ id: "webex", name: "Cisco Webex device" });
  deviceTypes.push({ id: "webex-manual", name: "Cisco Webex device (manual)" });

  return deviceTypes;
}
