import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import MapboxMap from "../Map/MapboxMap";
import Loader from "../../components/Loader";
import Freetext from "../../components/Freetext";
import InputBox from "../../components/InputBox";
import OptionFooter from "../../components/OptionFooter";
import Checkbox from "../../components/Checkbox";
import SmallButton from "../../components/SmallButton";
import { DropdownSelection, ControlledDropdownSelection } from "../../components/DropdownSelection";
import { showInMapOptions, getSupportedLocationTypes } from "../../locationHelpers";
import * as locationActions from "../../actions/locations";
import * as selectedActions from "../../actions/selected";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class NewLocation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: "",
      mapSourceName: "",
      type: "",
      floorLevel: "",
      hardCapacity: "",
      squareMeters: "",
      mazemapSquareMeters: "",
      graphId: undefined,
      isBookable: false,
      supportedLocationTypes: [],
      isTypeValid: false,
      showInMap: null,
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onFetchNameFromMap = this.onFetchNameFromMap.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onFloorLevelChange = this.onFloorLevelChange.bind(this);
    this.onHardCapacityChange = this.onHardCapacityChange.bind(this);
    this.onSquareMetersChange = this.onSquareMetersChange.bind(this);
    this.onMazemapSquareMetersChange = this.onMazemapSquareMetersChange.bind(this);
    this.onGraphIdChange = this.onGraphIdChange.bind(this);
    this.onIsBookable = this.onIsBookable.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndExit = this.onSaveAndExit.bind(this);
    this.createLocationBody = this.createLocationBody.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onShowInMapChange = this.onShowInMapChange.bind(this);
    this.getOptionFooter = this.getOptionFooter.bind(this);

    if (props.match.params.id !== "root") {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
    }
    else {
      this.state.supportedLocationTypes = getSupportedLocationTypes("root");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const newState = {
      ...prevState
    };

    // Fill in location name if feature selected and name is empty
    if (nextProps.selectedFeatures.length === 1) {
      const mapSourceName = get(nextProps.selectedFeatures, "[0].properties.mapSourceName", null);
      if (mapSourceName) {
        newState.mapSourceName = mapSourceName;
      }
    }

    if (prevState.hasLoadedState) {
      return newState;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.match.params.id === nextProps.location.id) {

      newState.supportedLocationTypes = getSupportedLocationTypes(nextProps.location.type);
      newState.hasLoadedState = true;

      // If only one - select that
      if (newState.supportedLocationTypes.length === 1 && newState.supportedLocationTypes[0].children === undefined) {
        newState.type = newState.supportedLocationTypes[0].id;
        newState.isTypeValid = true;
      }
      
      return newState;
    }

    return null;
  }

  onBackClick(id) {
    if (id) {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/locations`);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/root/locations`);
    }
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onFetchNameFromMap() {
    this.setState(prevState => ({ name: prevState.mapSourceName }));
  }

  onTypeChange(event) {
    this.setState({ type: event.target.value, isTypeValid: true });
  }

  onFloorLevelChange(event) {
    this.setState({ floorLevel: event.target.value });
  }

  onHardCapacityChange(event) {
    const hardCapacity = parseInt(event.target.value);
    this.setState({ hardCapacity });
  }

  onSquareMetersChange(event) {
    const squareMeters = parseInt(event.target.value);
    this.setState({ squareMeters });
  }

  onMazemapSquareMetersChange(event) {
    const mazemapSquareMeters = parseInt(event.target.value);
    this.setState({ mazemapSquareMeters });
  }

  onGraphIdChange(event) {
    this.setState({ graphId: event.target.value });
  }

  onIsBookable(event) {
    this.setState(prevState => ({ isBookable: !prevState.isBookable }));
  }
  
  onShowInMapChange(event) {
    // Get option value for "show in map" id
    const showInMapObject = showInMapOptions.find((option) => (option.id === event.target.value));
    this.setState({ showInMap: showInMapObject.value });
  }

  onSave() {
    const body = this.createLocationBody();
    this.props.addLocation(body, this.state.id);
  }

  onSaveAndExit() {
    const { push } = this.props.history;
    const body = this.createLocationBody();
    this.props.addLocation(body, this.state.id, push);
  }

  createLocationBody() {
    const body = {
      name: this.state.name,
      showLocationStatusInMap: this.state.showInMap,
      type: this.state.type,
      isBookable: this.state.isBookable
     };
     
    if (this.state.id !== "root") {
      body.parents = [this.state.id];
    }

    if (this.state.type === "floor" && this.state.floorLevel !== "") {
      body.floorLevel = this.state.floorLevel;
    }

    // Add geoJsonFeature
    if (!isEmpty(this.props.selectedFeatures)) {
      const feature = this.props.selectedFeatures[0];
      body.geoJsonFeatureId = feature.id;
    }

    // MS Graph Id
    if (this.state.graphId) {
      if (this.state.type.includes("zone") || this.state.type.includes("room") || this.state.type.includes("asset")) {
        body.microsoftGraph = { emailAddress: this.state.graphId };
      }
      else {
        body.microsoftGraph = null;
      }
    }

    // Add capacity
    if (this.state.hardCapacity !== "" && !Object.is(this.state.hardCapacity, NaN)) {
      body.capacity = { hard: this.state.hardCapacity };
    }

    // Square meters
    body.squareMeters = this.state.squareMeters === "" ? undefined : parseFloat(this.state.squareMeters);

    if (this.state.type === "building") {
      body.mazemapSquareMeters = this.state.mazemapSquareMeters === "" ? undefined : parseFloat(this.state.mazemapSquareMeters);
    }

    return body;
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  getOptionFooter() {
    const missingFloorLevel = (this.state.type === "floor" && (this.state.floorLevel === "" || this.state.floorLevel === "0"));
    return (
      <OptionFooter 
        cancel={this.onCancel} 
        cancelButtonLabel="Cancel"
        options={[
          { 
            label: "Save & add more", 
            callback: this.onSave, 
            disabled: (this.state.name.length === 0) || missingFloorLevel || !this.state.isTypeValid || this.props.isLoading || this.props.isLoadingLocations
          },
          { 
            label: "Save & exit", 
            callback: this.onSaveAndExit, 
            disabled: (this.state.name.length === 0) || missingFloorLevel || !this.state.isTypeValid || this.props.isLoading || this.props.isLoadingLocations
          }
        ]} 
      />
    );
  }

  render() {
    const isLoading = this.props.isLoading || 
      this.props.isLoadingLocations ||
      this.props.isSyncingMap;
      
    if (isLoading) {
      return <Loader fullScreen />;
    }

    let headerRow;
    if (this.state.id === "root") {
      headerRow = (
        <Row className={style.topRow}>
          <Col md={9}>
            <div>
              <Freetext header="Add location to root" content="" />
            </div>
          </Col>
        </Row>
      );
    }
    else {
      headerRow = (
        <Row className={style.topRow}>
          <Col md={9}>
            <div>
              <Freetext header={`Add location to ${this.props.location.name}`} />
            </div>
          </Col>
        </Row>
      );
    }

    // Get "show in map" id for options selection
    const showInMapSelectionId = get(showInMapOptions.find((option) => (option.value === this.state.showInMap)), "id", undefined);

    // Type form (controlled or uncontrolled)
    var typeDropDown;
    if (this.state.type !== "") {
      typeDropDown = (
        <ControlledDropdownSelection 
          options={this.state.supportedLocationTypes}
          name="selectedType" 
          value={this.state.type}
          onChange={this.onTypeChange} 
          label="Type" 
          info="required"
        />
      );
    } 
    else {
      typeDropDown = (
        <DropdownSelection 
          options={this.state.supportedLocationTypes}
          name="selectedType" 
          onChange={this.onTypeChange} 
          label="Type" 
          info="required"
        />
      );
    }

    // Floor level form
    var floorLevelSelector;
    if (this.state.type === "floor") {
      floorLevelSelector = (
        <InputBox 
          type="number" 
          value={this.state.floorLevel}
          onChange={this.onFloorLevelChange} 
          label="Floor level" 
          valid={this.state.floorLevel && this.state.floorLevel.length > 0}
          style={{ width: "80px", paddingLeft: "20px" }}
          info="required"
          />
      );
    }

    const form = (
      <>
        <Row>
          <Col sm={12} md={12} lg={8}>
            <InputBox 
              autoFocus 
              onChange={this.onNameChange} 
              label="Name" 
              placeholder="Your new location" 
              valid={this.state.name} 
              value={this.state.name}
              info="required"
              required
              showValidIcon
              />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={4}>
            <InputBox 
              label="Name in connected map feature"
              disabled
              required
              value={`${this.state.mapSourceName}`}
              />
          </Col>
          <Col sm={12} md={12} lg={4}>
            <SmallButton
              text={`Copy to name`}
              onClick={this.onFetchNameFromMap} 
              disabled={isEmpty(this.state.mapSourceName)}
              style={{ marginTop: "43px" }}
              />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row nogutter>
          { typeDropDown }
          { floorLevelSelector }
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row nogutter>
          <InputBox 
            type="number" 
            value={this.state.hardCapacity}
            onChange={this.onHardCapacityChange} 
            label="People capacity" 
            valid={this.state.hardCapacity} 
            style={{ width: "120px" }} 
          />
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label='Use capacity for bookable workplaces' isChecked={this.state.isBookable} onClick={this.onIsBookable} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row nogutter>
          <InputBox 
            type="number" 
            onChange={this.onSquareMetersChange} 
            label="Square meters" 
            valid={this.state.squareMeters} 
            value={this.state.squareMeters} 
            style={{ width: "120px", marginRight: "20px" }}
            disabled={!this.props.auth.hasITSupportRole}
          />
          { this.state.type === "building" && (
            <InputBox 
              type="number" 
              onChange={this.onMazemapSquareMetersChange} 
              label="Mazemap square meters" 
              valid={this.state.mazemapSquareMeters}
              value={this.state.mazemapSquareMeters}
              style={{ width: "160px" }}
              disabled={!this.props.auth.hasITSupportRole}
            />
          )}
        </Row>
        { (this.state.type.includes("zone") || this.state.type.includes("room") || this.state.type.includes("asset")) && (
            <>
              <div style={{ paddingTop: "40px" }} />
              <Row>
                <Col sm={12} md={12} lg={8}>
                  <InputBox 
                    onChange={this.onGraphIdChange} 
                    label="Microsoft Graph ID" 
                    placeholder="The locations e-mail address" 
                    valid={this.state.graphId} 
                    />
                </Col>
              </Row>
            </>
          )
        }
        <div style={{ paddingTop: "20px" }} />
        {/* <Freetext header="Map" content="Here you can configure if a location should be shown in the map." /> */}
        <Row>
          <Col sm={12} md={12} lg={12}>
            <ControlledDropdownSelection 
              label="Show in map"
              name="showInMap"
              value={showInMapSelectionId}
              onChange={this.onShowInMapChange}
              options={showInMapOptions}
            />
          </Col>
        </Row>
        <div style={{ paddingTop: "80px" }} />
      </>
    );

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <MapboxMap 
          mapId="new-location-map-container" 
          id={this.state.id} 
          company={this.props.company} 
          location={this.props.location} 
          map={this.props.location.floorMap}
          showConnectForNewLocation
          showMap
          />
      );
    }

    return (
      <div className={style.editContainer}>
        <div className={style.row}>
          <div className={style.col50}>
            <div className={style.scroll}>
              { headerRow }
              { form }
            </div>
          </div>
          <div className={style.mapPart40}>
            { mapElement }
          </div>
        </div>
        {
          this.getOptionFooter()
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    location: state.location,
    isLoading: state.loading.location,
    isLoadingLocations: state.loading.locations,
    isSyncingMap: state.loading[API.SYNC_FLOOR_MAP],
    company: state.auth.selectedCompany,
    selectedFeatures: state.selected.features
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getLocation: locationActions.getLocation,
    getFloorMap: locationActions.getFloorMap,
    addLocation: locationActions.addLocation,
    deselectMapFeature: selectedActions.deselectMapFeature,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewLocation);
