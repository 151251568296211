import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRouter, faGlobe, faMobileButton, faUserPlus, faPersonShelter } from "@fortawesome/pro-solid-svg-icons";
import SearchBox from "../../../components/SearchBox";
import SmallButton from "../../../components/SmallButton";
import Table from "../../../components/Table";
import TopRowOptions from "../../../components/TopRowOptions";
import Loader from "../../../components/Loader";
import * as alarmActions from "../../../actions/alarms";
import { categoryColors } from "./companySettings";
import style from "./style.module.scss";
import SegmentedControl from "../../../components/SegmentedControl";
import CheckboxBlock from "../../../components/CheckboxBlock";

class CompanyList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      includeSilencedInCount: false,
      highlightedId: undefined
    };
    
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/support/settings/${rowInfo.original.id}/details`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id != null && rowInfo.original.id === this.props.companyId ? "rgba(0,0,0,0.05)" : null
        )
      }
    }
  }

  render() {
    // console.log("Support.companyList render", this.props);
    const { isLoading } = this.props;
  
    let optionButtons = [];
    if (!this.props.auth.isSudo) {
      optionButtons.push(
        <SmallButton key="add" text="New company" onClick={() => { this.props.history.push("/support/new-company") }} style={{ margin: 0 }} />
      );
    }

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => { this.setState({ searchText: value }) }}
            onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
            onSearchClick={this.onSearchClick}
            inListView
          />
        )}
        buttons={optionButtons}
      />
    );

    const companies = this.props.auth?.user?.companies ?? [];
    const compactedCompanies = companies.map(company => ({ id: company._id, name: company.name, roles: company.roles }));

    // Check if the name includes the search text (split on space)
    const filteredCompanies = compactedCompanies.filter(company => {
      if (this.state.searchText) {
        const searchWords = this.state.searchText.toLowerCase().split(" ");
        if (!searchWords.every(word => company.name.toLowerCase().includes(word))) {
          return false;
        }
      }
      return true;
    });

    // Sort the companies
    filteredCompanies.sort((a, b) => {
      if (a[this.state.sortBy] < b[this.state.sortBy]) {
        return this.state.sortOrder === "asc" ? -1 : 1;
      }
      if (a[this.state.sortBy] > b[this.state.sortBy]) {
        return this.state.sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    const tableElement = (
      <Table
        data={filteredCompanies}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc", offset: 0 });
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset });
        }}
        noDataText={isLoading ? "" : "No companies found"}
        selectedId={this.props.companyId}
        useDeepCompareOnRows={true}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            resizable: false,
            minWidth: 150,
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 40,
            cell: ({ row }) => (<div><div className="arrow" /></div>)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <div>
          { options }
          { tableElement }
          <div style={{ paddingTop: "40px" }} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getWarningsSummary: alarmActions.getWarningsSummary
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);

