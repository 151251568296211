import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../components/Loader";
import NotFound from "../../NotFound";
import SubNavigation from "../../../components/SubNavigation";
import LargeHeader from "../../../components/LargeHeader";
import AlarmsContainer from "./container";
import * as alarmActions from "../../../actions/alarms";
import style from "./style.module.scss";

class Alarms extends Component {
  
  render() {
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/support/overview`, label: `Live` });
    // subNavigationLinks.push({ pathname: `/support/history`, label: `History` });

    return (
      <div className={style.page}>
        <LargeHeader title="Overview" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Route path="/support/overview/:companyId?/alarms/:warningId?" children={(props) => <AlarmsContainer {...props} companyId={props.match.params.companyId} warningId={props.match.params.warningId} />} />
          <Route path="/support/overview/:companyId?" children={(props) => <AlarmsContainer {...props} companyId={props.match.params.companyId} warningId={props.match.params.warningId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthLoading: state.loading.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(alarmActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);