import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faChevronRight, faChevronLeft, faBellSlash, faArchive, faTriangleExclamation, faChevronDoubleLeft, faChevronDoubleRight, faComment } from "@fortawesome/pro-solid-svg-icons";

import AutoResizingTextArea from "../../../components/AutoResizingTextArea";
import Datepicker from "../../../components/Datepicker";
import Tag from "../../../components/Tag";
import { stateColors } from "./warningsList";
import LogRow from "./logRow";
import * as warningsActions from "../../../actions/alarms";

class WarningView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offset: 0,
      editingLogId: null,
      editedLogText: "",
      newLogType: null,
      newLogText: "",
      newMuteUntilDate: new Date()
    };

    this.onLocationClick = this.onLocationClick.bind(this);
    this.onWarningClick = this.onWarningClick.bind(this);
    this.onSensorClick = this.onSensorClick.bind(this);
    this.onGatewayClick = this.onGatewayClick.bind(this);
    this.startCommentEditing = this.startCommentEditing.bind(this);
    this.endCommentEditing = this.endCommentEditing.bind(this);
    this.createComment = this.createComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.mute = this.mute.bind(this);
    this.unmute = this.unmute.bind(this);
  }

  componentDidMount() {
    this.props.getWarning(this.props.companyId, this.props.warningId);
    this.props.getWarningLogs(this.props.companyId, this.props.warningId, { limit: this.state.limit, offset: 0 });
  }

  // when warning data updates - reset the newLogType
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.warning) !== JSON.stringify(prevProps.warning)) {
      this.setState({ newLogType: null, editingLogId: null, editedLogText: "" });
    }
  }

  onLocationClick(event, locationId) {
    const link = `/companies/${this.props.companyId}/locations/${locationId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  onWarningClick(event, warningId) {
    const link = `/support/overview/${this.props.companyId}/alarms/${warningId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  onSensorClick(event, sensorId) {
    const link = `/companies/${this.props.companyId}/sensors/${sensorId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  onGatewayClick(event, gatewayId) {
    const link = `/companies/${this.props.companyId}/gateways/${gatewayId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  startCommentEditing(logId, logText) {
    this.setState({ editingLogId: logId, editedLogText: logText });
  }

  endCommentEditing() {
    this.setState({ editingLogId: null, editedLogText: "" });
  }

  createComment() {
    this.props.createComment(this.props.companyId, this.props.warningId, { text: this.state.newLogText });
  }

  updateComment(logId) {
    this.props.updateComment(this.props.companyId, this.props.warningId, logId, { text: this.state.editedLogText });
  }

  deleteComment(logId) {
    this.props.deleteComment(this.props.companyId, this.props.warningId, logId);
  }

  mute() {
    this.props.muteWarning(this.props.companyId, this.props.warningId, { mutedUntil: this.state.newMuteUntilDate.toISOString() });
    this.setState({ newLogType: null, mutedUntil: new Date() });
  }

  unmute() {
    this.props.unmuteWarning(this.props.companyId, this.props.warningId);
    this.setState({ newLogType: null });
  }

  onLogPageClick(pageNumber) {
    const offset = (pageNumber - 1) * this.state.limit;

    if (this.props.logs.offset === offset || offset < 0 || offset > this.props.logs.count) {
      return;
    }

    this.props.getWarningLogs(this.props.companyId, this.props.warningId, { limit: this.state.limit, offset: offset });
  }

  getHeader(warning) {
    const title = warning.name;
    const deviceId = warning.sensorId || warning.gatewayId;
    const onClick = warning.sensorId ? this.onSensorClick : this.onGatewayClick;
    const headerLink = <HeaderLink onClick={(event) => onClick(event, deviceId)}>{deviceId}</HeaderLink>;
    const headerParentheses = deviceId && <HeaderParentheses>({headerLink})</HeaderParentheses>;
    return (
      <Header>
        <Title>{title} {headerParentheses}</Title>
        <CloseButton onClick={this.props.onClose}>
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </CloseButton>
      </Header>
    );
  }

  getLocationElement(warning) {
    const locationBreadcrumbs = [];
    if (warning.breadcrumbs) {
      const reversedBreadcrumbs = warning.breadcrumbs.slice().reverse();
      reversedBreadcrumbs.forEach((location, index) => {

        locationBreadcrumbs.push(
          <Location key={location.id} onClick={(event) => this.onLocationClick(event, location.id)}>
            {location.name}
          </Location>
        );

        if (index < warning.breadcrumbs.length - 1) {
          locationBreadcrumbs.push(<FontAwesomeIcon key={index} icon={faChevronRight} />);
        }
      });
    }
    return <LocationBreadcrumbs>{locationBreadcrumbs}</LocationBreadcrumbs>;
  }

  getStatusElement(warning) {

    // State tag
    let stateColor = null;
    let stateText = null;
    let stateIcon = null;
    if (warning.mutedAt || !isEmpty(warning.suppressedBy)) {
      stateColor = stateColors.silenced;
      stateText = "Silenced";
      stateIcon = faBellSlash;
    }
    else if (warning.triggeredAt) {
      stateColor = stateColors.active;
      stateText = "Alarm";
      stateIcon = faTriangleExclamation;
    }
    else {
      stateColor = stateColors.resolved;
      stateText = "Resolved";
      stateIcon = faArchive;
    }

    const tagContent = (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <FontAwesomeIcon icon={stateIcon} />
        {stateText}
      </div>
    );

    const stateTag = <Tag color={stateColor} text={tagContent} noMargin />;

    // Silenced by tag
    let silencedBy = null;
    if (warning.mutedAt) {
      silencedBy = (
        <Tag text={warning.mutedBy ?? "Unknown"} noMargin />
      );
    }
    else if (!isEmpty(warning.suppressedBy)) {
      silencedBy = warning.suppressedBy.map(sw => {
        return (
          <Tag key={sw.id} color={stateColors.active} text={sw.name} onClick={(event) => this.onWarningClick(event, sw.id)} noMargin />
        );
      });
    }

    return (
      <Section>
        <StatusRow>
          {stateTag}
          {silencedBy && <span>by</span>}
          {silencedBy}
          {warning.mutedUntil && <span>until</span>}
          {warning.mutedUntil && <Tag text={moment(warning.mutedUntil).format("DD/MM/YY")} noMargin />}
        </StatusRow>
      </Section>
    );
  }

  getActionRow(warning, newLogType) {

    let actionContainer = null;
    switch (newLogType) {
      case "reactivate":
        actionContainer = (
          <div>
            <div style={{ marginBottom: "3px" }}>The alarm is silenced to hide it in the alarm list.</div>
            <div style={{ marginBottom: "15px" }}>Are you sure you want to remove the silencing effect from the alarm?</div>
            <ActionContainer>
              <ButtonContainer>
                <ActionButton onClick={() => this.setState({ newLogType: null })}>Cancel</ActionButton>
                <ActionButton onClick={this.unmute}>Reactivate alarm</ActionButton>
              </ButtonContainer>
            </ActionContainer>
          </div>
        );
        break;

      case "muteUntil":
        actionContainer = (
          <div>
            <div style={{ marginBottom: "3px" }}>Silencing an alarm removes it from the alarm list. The silencing will remain in effect even after the alarm is resolved.</div>
            <div style={{ marginBottom: "15px", color: "#990000", fontWeight: "600", fontStyle: "italic" }}>NB! Only do this if the issue will be fixed soon or if the alarm is false/unimportant.</div>
            <ActionContainer>
              <Datepicker date={this.state.newMuteUntilDate} onChange={(newDate) => this.setState({ newMuteUntilDate: newDate })} />
              <ButtonContainer>
                <ActionButton onClick={() => this.setState({ newLogType: null })}>Cancel</ActionButton>
                <ActionButton onClick={this.mute}>Save</ActionButton>
              </ButtonContainer>
            </ActionContainer>
          </div>
        );
        break;

      case "comment":
        const disabled = this.state.newLogText.length > 500;
        actionContainer = (
          <div>
            <ActionContainer>
              <AutoResizingTextArea
                autoFocus
                value={this.state.newLogText}
                onChange={(e) => this.setState({ newLogText: e.target.value })}
                placeholder="Write your comment here..."
                style={{
                  flex: 1,
                  backgroundColor: "rgb(250, 250, 250)",
                  borderRadius: "5px",
                  border: "solid 1px #D8D8D8",
                  padding: "12px",
                  color: "#0c0f26",
                  boxSizing: "border-box",
                }}
              />
              <ButtonContainer>
                <ActionButton onClick={() => this.setState({ newLogType: null, newLogText: "" })}>Cancel</ActionButton>
                <ActionButton $disabled={disabled} onClick={!disabled ? this.createComment : null}>Save</ActionButton>
              </ButtonContainer>
            </ActionContainer>
          </div>
        );
        break;

      default:
        actionContainer = (
          <ActionContainer>
            <ActionButton onClick={() => this.setState({ newLogType: "comment" })}>
              <FontAwesomeIcon icon={faComment} />
              Comment
            </ActionButton>

            {!warning.mutedBy && (
              <ActionButton onClick={() => this.setState({ newLogType: "muteUntil" })}>
                <FontAwesomeIcon icon={faBellSlash} />
                Silence alarm
              </ActionButton>
            )}

            {warning.mutedBy && (
              <ActionButton onClick={() => this.setState({ newLogType: "reactivate" })}>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                Reactivate alarm
              </ActionButton>
            )}
          </ActionContainer>
        );
        break;
    }

    return (
      <Section>
        <SubHeader>
          <SubTitle>Actions</SubTitle>
        </SubHeader>
        {actionContainer}
      </Section>
    );
  }

  getCommentList(comments, editingLogId) {

    if (isEmpty(comments)) {
      return (
        <Section>
          <SubHeader>
            <SubTitle>Comments</SubTitle>
          </SubHeader>
          <LogContainer>
            <LogRow
              key={"empty"}
              text={"There are no comments"}
            />
          </LogContainer>
        </Section>
      );
    }

    // Comments rows
    const commentsElement = comments.map(comment => {
      const saveDisabled = this.state.editedLogText.length > 500;
      return (
        <LogRow
          key={comment.id}
          id={comment.id}
          text={editingLogId === comment.id ? this.state.editedLogText : comment.text}
          user={comment.user}
          createdAt={comment.createdAt}
          isEditing={editingLogId === comment.id}
          canEdit={true}
          canDelete={true}
          saveDisabled={saveDisabled}
          onEdit={() => this.startCommentEditing(comment.id, comment.text)}
          onSave={() => this.updateComment(comment.id)}
          onCancel={() => this.endCommentEditing()}
          onChange={(e) => this.setState({ editedLogText: e.target.value })}
          onDelete={() => this.deleteComment(comment.id)}
        />
      );
    });

    return (
      <Section>
        <SubHeader>
          <SubTitle>Comments</SubTitle>
        </SubHeader>
        <LogContainer>
          {commentsElement}
        </LogContainer>
      </Section>
    );
  }

  getLogList(logs, isLoadingLogs) {

    const { offset, limit, count, results } = logs;
    const allLogs = results ?? [];

    if (allLogs.length === 0) {
      return (
        <Section>
          <SubHeader>
            <SubTitle>History</SubTitle>
          </SubHeader>
          <LogContainer>
            <LogRow
              key={"empty"}
              text={"No history available."}
            />
          </LogContainer>
        </Section>
      );
    }

    const page = Math.floor(offset / limit) + 1;
    const pages = Math.ceil(count / limit);
    const pageArray = Array.from({ length: pages }, (_, i) => i + 1);

    // Limit pageArray to 3 pages around the current page
    if (pageArray.length > 3) {
      if (page <= 2) {
        pageArray.splice(3);
      }
      else if (page >= pages - 1) {
        pageArray.splice(0, pages - 3);
      }
      else {
        const start = Math.max(1, page - 1);
        const end = Math.min(pages, page + 1);
        pageArray.splice(0, start - 1);
        pageArray.splice(end - start + 1);
      }
    }

    let pagingElement = null;
    if (pageArray.length > 1) {
      pagingElement = (
        <div style={{ display: "flex" }}>
          <PagingContainer>
            <PagingButton disabled={page === 1} onClick={() => this.onLogPageClick(1)}>
              <FontAwesomeIcon icon={faChevronDoubleLeft} size="sm" />
            </PagingButton>
            <PagingButton disabled={page === 1} onClick={() => this.onLogPageClick(page - 1)}>
              <FontAwesomeIcon icon={faChevronLeft} size="sm" />
            </PagingButton>
            {
              pageArray.map(pageNumber => {
                return (
                  <PagingButton
                    key={pageNumber}
                    $pageIndex={pageNumber}
                    $currentPage={page}
                    onClick={() => this.onLogPageClick(pageNumber)}
                  >
                    {pageNumber}
                  </PagingButton>
                );
              })
            }
            <PagingButton disabled={page === pages} onClick={() => this.onLogPageClick(page + 1)}>
              <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </PagingButton>
            <PagingButton disabled={page === pages} onClick={() => this.onLogPageClick(pages)}>
              <FontAwesomeIcon icon={faChevronDoubleRight} size="sm" />
            </PagingButton>
          </PagingContainer>
        </div>
      );
    }

    // Log rows
    const logsElement = allLogs.map((log, index) => {

      // Build log text
      let logText = `${log.category} going ${log.action}`;
      logText = logText.charAt(0).toUpperCase() + logText.slice(1);

      // Build link
      let onClick = null;
      if (log.sensorId) {
        onClick = (event) => this.onSensorClick(event, log.sensorId);
      }
      else if (log.gatewayId) {
        onClick = (event) => this.onGatewayClick(event, log.gatewayId);
      }

      return (
        <LogRow
          key={log.datetime + "-" + index}
          text={logText}
          createdAt={log.datetime}
          user={log.name}
          onUserClick={onClick}
        />
      );
    });



    if (isLoadingLogs) {
      return (
        <Section>
          <SubHeader>
            <SubTitle>History</SubTitle>
            {pagingElement}
          </SubHeader>
          <LogContainer>
            <LogRow
              key={"loading"}
              text={"Loading history..."}
            />
          </LogContainer>
        </Section>
      );
    }

    return (
      <Section>
        <SubHeader>
          <SubTitle>History</SubTitle>
          {pagingElement}
        </SubHeader>
        <LogContainer>
          {logsElement}
        </LogContainer>
      </Section>
    );
  }

  render() {
    // console.log("WarningView.render()", this.props, this.state);

    if (isEmpty(this.props.warning)) {
      return null;
    }

    return (
      <div>
        {this.getHeader(this.props.warning)}
        {this.getLocationElement(this.props.warning)}
        {this.getStatusElement(this.props.warning)}
        {this.getActionRow(this.props.warning, this.state.newLogType)}
        {this.getCommentList(this.props.warning.comments ?? [], this.state.editingLogId)}
        {this.getLogList(this.props.logs, this.props.isLoadingLogs)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.loading["GET_WARNING"],
    isLoadingLogs: state.loading["GET_WARNING_LOGS"],
    warning: state.alarms.warning,
    logs: state.alarms.logs
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getWarningLogs: warningsActions.getWarningLogs,
    createComment: warningsActions.createComment,
    updateComment: warningsActions.updateComment,
    deleteComment: warningsActions.deleteComment
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningView);

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Title = styled.div`
  flex: 1;
  min-width: 0; /* allows the text to wrap properly */
  font-size: 24px;
  font-weight: 600;
  color: #222222;
`;

const HeaderParentheses = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

const HeaderLink = styled.span`
  cursor: pointer;
  color:rgb(30, 89, 152);
  font-size: 20px;
  font-weight: 400;
`;

const CloseButton = styled.div`
  flex-shrink: 0;
  align-self: flex-start;
  cursor: pointer;
  font-size: 20px;
  font-weight: 100;
  color: #333;
  margin-left: 8px;

  // Disable selection
  user-select: none;
`;

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 10px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
`;

const LocationBreadcrumbs = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  color: #666;
  align-items: center;
`;

const Location = styled.div`
  cursor: pointer;
  color: #1c4d82;
  font-size: 18px;
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: start;
`;

const ActionButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 8px 12px;
  color: #333;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ $disabled }) => $disabled && `
    color: #ccc;
    background-color: #f0f0f0;
    cursor: not-allowed;
  `}

  // Disable selection
  user-select: none;
`;

const LogContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const PagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const PagingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 30px;
  cursor: pointer;
  box-sizing: border-box;
  color: #333;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  transition: background-color 0.2s, color 0.2s;

  border-radius: 5px;
  border: 1px solid #e0e0e0;

  &:not([disabled]):hover {
    background-color: #f0f0f0;
  }

  // Active page
  ${({ $pageIndex, $currentPage }) => $pageIndex && $pageIndex === $currentPage && `
    color: #fff;
    background-color: #1c4d82;

    // Disable hover effect
    &:not([disabled]):hover {
      background-color: #1c4d82;
    }
  `}

  // Disabled button
  ${({ disabled }) => disabled && `
    color: #ccc;
  `}

  // Disable selection
  user-select: none;
`;